export default {
  name: '帐号设置',
  path: '/profile/set',
  color: 'f0f1f6',
  getComponent (nextState, cb) {
    import('./components/AccountSetView').then((view) => {
      cb(null, view.default);
    });
  },
};
