// 课后作业
import assign from 'object-assign';
import Constants from '../../constants/AppConstants';
import request from '../../lib/request';
import Prefix from '../../constants/Prefix';
import ActionMixins from '../../mixins/ActionMixins';

const ActionTypes = Constants.ActionTypes;

const AfterHomeworkAction = assign({}, ActionMixins.mixins, {
  // 获取课后作业列表
  getAfterHomework: function (data) {
    const { classId, subjectId, homeworkType, currentPage, pageSize } = data;
    const url = `${Prefix('HDKT')}/homework/teacher/homeworkList/v2/${classId}/${subjectId}/${homeworkType}?currentPage=${currentPage}&pageSize=${pageSize}`;
    const options = {
      method: 'GET',
    };
    return request({
      type: ActionTypes.GET_AFTER_HOMEWORK_LIST,
      url,
      options,
      returnParam: { currentPage },
    });
  },
  deleteHomeworkById: function (homeworkId, index) {
    const url = `${Prefix('HDKT')}/homework/teacher/del/v1`;
    const data = { homeworkId };
    const options = {
      method: 'POST',
      body: data,
    };
    return request({
      url,
      options,
      returnParam: { index },
    });
  },
  // // 发布或者编辑课后作业
  // saveAfterClassHomework: function (data) {
  //   const url = `${Prefix('HDKT')}/homework/teacher/del/v1`;
  //   const options = {
  //     method: 'POST',
  //     body: data,
  //   };
  //   return request({
  //     url,
  //     options,
  //   });
  // },
  // 获取课后作业详情
  getReviewDetail: function (homeworkId, classId) {
    const url = `${Prefix('HDKT')}/homework/teacher/get/correct/afterClassHomeworkInfo/v1/${homeworkId}/${classId}`;
    const options = {
      method: 'GET',
    };
    return request({
      url,
      options,
    });
  },
  // 获取批改列表
  getReviewList: function (data) {
    const { classId, homeworkId, id } = data;
    const url = `${Prefix('HDKT')}/homework/teacher/studentCommitInfoList/v1/${classId}/${homeworkId}/${id}`;
    const options = {
      method: 'GET',
    };
    return request({
      type: ActionTypes.GET_AFTER_HOMEWORK_REVIEW_LIST,
      url,
      options,
    });
  },
  // 获取题目单
  getCheckPProblemList: function (taskId) {
    const url = `${Prefix('HDKT')}/homework/teacher/getTaskProblemInfo/v1/${taskId}`;
    const options = {
      method: 'GET',
    };
    return request({
      type: ActionTypes.GET_AFTER_HOMEWORK_CHECK_LIST,
      url,
      options,
    });
  },
  // 获取学生答案
  getStudentAnswer: function (taskId, homeworkStudentId) {
    const url = `${Prefix('HDKT')}/homework/teacher/correctPage/v1/${taskId}/${homeworkStudentId}`;
    const options = {
      method: 'GET',
    };
    return request({
      url,
      options,
    });
  },
  // 课后作业-作业批改-历史作答及点评
  getHistoryAnswer: function (homeworkId, studentId) {
    const url = `${Prefix('HDKT')}/homework/teacher/getHistoryOfAnswerCorrect/v1/${homeworkId}/${studentId}`;
    const options = {
      method: 'GET',
    };
    return request({
      url,
      options,
    });
  },
  // 课后作业-作业批改-提交点评/退回订正
  putStudentScore: function (data) {
    const url = `${Prefix('HDKT')}/homework/teacher/doCorrectOrReturn/v1`;
    const options = {
      method: 'POST',
      body: data,
    };
    return request({
      url,
      options,
    });
  },
  // 获取提交详情
  getReviewDetailList: function (data) {
    const { classId, homeworkId, id } = data;
    const url = `${Prefix('HDKT')}/homework/teacher/studentCommitInfoList/v1/${classId}/${homeworkId}/${id}`;
    const options = {
      method: 'GET',
    };
    return request({
      url,
      options,
    });
  },
  /**
   * 发布或编辑课后作业
   */
  saveAfterClassHomework: function (data) {
    const url = `${Prefix('HDKT')}/homework/teacher/saveAfterClassHomework/v2`;
    const options = {
      method: 'POST',
      body: data,
    };
    return request({
      url,
      options,
    });
  },
  /**
   * 获取课后作业详情
   */
  getAfterHomeworkById: function (homeworkId) {
    const url = `${Prefix('HDKT')}/homework/getAfterClassHomework/v1/${homeworkId}`;
    const options = {
      method: 'GET',
    };
    return request({
      type: ActionTypes.GET_AFTER_HOMEWORK_BY_ID,
      url,
      options,
    });
  },
  /**
   * 教师批改作业-评语字典列表
   */
  getCommentList: function () {
    const url = `${Prefix('HDKT')}/dict/commentList/list/v2`;
    const options = {
      method: 'GET',
    };
    return request({
      url,
      options,
    });
  },
  /**
   * 课后作业-教师对已批改的作业-追加评语
   */
  appendRemark: function (data) {
    const url = `${Prefix('HDKT')}/homework/teacher/addRemark/v1`;
    const options = {
      method: 'POST',
      body: data,
    };
    return request({
      url,
      options,
    });
  },
  /**
   * 教师批改作业-保存自定义评语
   */
  saveQuickComment: function (data) {
    const url = `${Prefix('HDKT')}/dict/save/teacher/comment/v1`;
    const options = {
      method: 'POST',
      body: data,
    };
    return request({
      url,
      options,
    });
  },
  /**
   * 教师批改作业-删除评语
   */
  deleteQuickComment: function (data) {
    const url = `${Prefix('HDKT')}/dict/delete/teacher/comment/v1`;
    const options = {
      method: 'POST',
      body: data,
    };
    return request({
      url,
      options,
    });
  },
  /**
   * 教师批改作业-更新评语使用时间
   */
  updateCommentUsed: function (data) {
    const url = `${Prefix('HDKT')}/dict/save/teacher/comment/used/v1`;
    const options = {
      method: 'POST',
      body: data,
    };
    return request({
      url,
      options,
    });
  },
});

export default AfterHomeworkAction;
