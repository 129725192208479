import Event from 'events';
import assign from 'object-assign';
import _ from 'underscore';
import moment from 'moment';

import { Toast } from 'antd-mobile';
import Util from '../lib/Util';
import StoreMixins from '../mixins/StoreMixins';
import { removeAuthority, globalData } from '../lib/authority';

const EventEmitter = Event.EventEmitter;

const store = {
  updateTarget: {
    isGetUserInfo: false,
    isFlash: false,
    isGetSubjectInfo: false,
    isUpdateClassInfo: false,
    isGetUnreadMessage: false,
  },
  userInfo: {},
  subjectInfo: [],
  subjectMap: {},
  subjectList: [],
  classList: [],
  unreadMessageStatus: {
    homework: 0,
    onlineHomework: 0,
    advice: 0,
  }, // 未读消息
};
const AppStore = assign({}, EventEmitter.prototype, StoreMixins.mixins, {
  getUpdateTarget: function () {
    return store.updateTarget;
  },
  clearUpdateTarget: function (key) {
    store.updateTarget[key] = false;
    return store.updateTarget;
  },
  getUserInfo: function () {
    return store.userInfo;
  },
  getUserClassList: function () {
    const { classList } = store.userInfo;
    if (!classList || classList.length === 0) {
      return [];
    }
    return Util.deepCopy(classList);
  },
  /**
   * 班级列表去重
   * @returns {Array}
   */
  getUserClassDictList: function () {
    return store.classList;
  },
  getClassInfoById: function (classId, subjectId) {
    const subjectItem = store.subjectMap[subjectId];
    if (!classId || !subjectItem) {
      return '';
    }
    const classList = subjectItem.classList || [];
    let classInfo = {};
    for (let i = 0; i < classList.length; i += 1) {
      const item = classList[i];
      if (item.classId === classId) {
        classInfo = assign({}, item);
        break;
      }
    }
    return classInfo;
  },
  getClassListById: function (id) {
    if (_.isEmpty(store.subjectMap[id])) {
      return [];
    }
    const item = store.subjectMap[id];
    if (!_.isArray(item.classList)) {
      return [];
    }
    return Util.deepCopy(item.classList);
  },
  getSubjectNameById: function (id) {
    if (_.isEmpty(store.subjectMap[id])) {
      return '';
    }
    return store.subjectMap[id].subjectName;
  },
  getSubjectList: function () {
    return store.subjectList;
  },
  getSubjectInfo: function () {
    return store.subjectInfo;
  },
  /**
   * 获取学科map
   * @param id
   * @returns {*|{}}
   */
  getSubjectItemById: function (id) {
    const subjectMap = Util.deepCopy(store.subjectMap[id] || {});
    delete subjectMap.classList;
    return subjectMap;
  },
  getUnreadMessageStatus: function () {
    return store.unreadMessageStatus;
  },
  generateTitle: function (pageSource, subjectId, titleType) {
    /**
     * pageSource:  1： 在线 2： 课后 3：随堂检测 4：添加试卷 5: 预习任务
     * titleType: 1. 主标题 2. 试卷标题
     */
    const startDate = moment(+new Date()).format('MM月DD日').toString();
    const subjectName = this.getSubjectNameById(subjectId);
    let gTitle = '';
    switch (pageSource) {
      case 1:
        if (titleType === 1) {
          gTitle = `${startDate} (${subjectName}) 在线作业`;
        } else {
          gTitle = `${startDate} (${subjectName}) 在线作业题目单`;
        }
        break;
      case 2:
        if (titleType === 1) {
          gTitle = `${startDate} (${subjectName}) 课后作业`;
        } else {
          gTitle = `${startDate} (${subjectName}) 课后作业题目单`;
        }
        break;
      case 5:
        if (titleType === 1) {
          gTitle = `${startDate} (${subjectName}) 预习任务`;
        } else {
          gTitle = `${startDate} (${subjectName}) 预习任务题目单`;
        }
        break;
      default:
        break;
    }
    return gTitle;
  },
  emitFlashMessage: function (message) {
    const { status, content } = message;
    if (status === 'fail') {
      Toast.fail(content);
    } else {
      Toast.success(content);
    }
  },
  emitUpdateClassInfo: function () {
    store.updateTarget.isUpdateClassInfo = true;
    this.emitChange();
  },
  handleUserInfo: function (classList, disEmit) {
    if (_.isArray(classList) && classList.length > 0) {
      const classMap = {};
      store.subjectMap = {};
      store.subjectList = [];
      store.classList = [];
      store.userInfo.classList.map((item, index) => {
        if (_.isEmpty(store.subjectMap[item.subjectId])) {
          store.subjectMap[item.subjectId] = {
            ...item,
            classList: [],
          };
          store.subjectList.push({
            subjectId: item.subjectId,
            subjectName: item.subjectName,
            subjectImg: item.subjectImg
          });
        }
        if (!classMap[item.classId]) {
          classMap[item.classId] = item;
          store.classList.push({ classId: item.classId, className: item.className });
        }
        item.lockId = index + 1;
        item.schoolId = store.userInfo.schoolId;
        store.subjectMap[item.subjectId].classList.push(item);
        return store.subjectMap;
      });
    }
    if (!disEmit) {
      this.emitChange();
    }
  },
  EXCEPTION_ACTION: function (response, status, error) {
    if (error) {
      this.emitFlashMessage({ content: response, status: 'fail' });
    }
  },
  NEED_LOGIN: function () {
    removeAuthority();
  },
  GET_USER_INFO_RESPONSE: function (response, status, error) {
    if (!error) {
      store.updateTarget.isGetUserInfo = true;
      store.userInfo = response && response.data ? response.data : {};
      const { classList, schoolId } = store.userInfo;
      if (_.isEmpty(classList)) {
        store.userInfo.isSetClass = true;
      }
      globalData.schoolId = schoolId;
      this.handleUserInfo(classList);
    }
  },
  GET_SUBJECT_INFO_RESPONSE: function (response, status, error) {
    if (!error) {
      store.updateTarget.isGetSubjectInfo = true;
      store.subjectInfo = response && response.data ? response.data : [];
      this.emitChange();
    }
  },
  GET_DEFAULT_CLASS_LIST_RESPONSE: function (response, status, error) {
    if (!error) {
      store.updateTarget.isGetDefaultClass = true;
      const classList = response.data || [];
      store.userInfo.classList = classList;
      this.handleUserInfo(classList);
    }
  },
  GET_UNREAD_MESSAGE_STATUS_RESPONSE: function (response, status, error) {
    if (!error) {
      store.updateTarget.isGetUnreadMessage = true;
      store.unreadMessageStatus = response.data || {};
      this.emitChange();
    }
  },
});

AppStore.registerDispatchToken();
export default AppStore;
