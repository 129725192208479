export default {
  name: '登录',
  path: '/login',
  getComponent (nextState, cb) {
    import('./components/LoginViewV2').then((view) => {
      cb(null, view.default);
    });
  },
};

