/**
 * @description: home
 * @author: tengyang <tengyangcc@163.com>
 */
import React from 'react';
import { Link } from 'react-router';

import Prefix from '../../../../constants/Prefix';
import NavBar from '../../../../components/NavBar'
import './HomeView.scss';

import onlineHomework from '../../../../images/home/onlineHomework@2x.png';
import afterHomework from '../../../../images/home/afterHomework@2x.png';
import previewHomework from '../../../../images/home/previewHomework@2x.png';
import onlineExam from '../../../../images/home/onlineExam@2x.png';
import analysis from '../../../../images/home/analysis@2x.png';
import netCourse from '../../../../images/home/netCourse@2x.png';
import platformMicro from '../../../../images/home/platformMicro@2x.png';
import schoolMicro from '../../../../images/home/schoolMicro@2x.png';
import areaMicro from '../../../../images/home/areaMicro@2x.png';
import wrongProblem from '../../../../images/home/wrongProblem@2x.png';

const menuList = [
  { id: 9, name: '预习任务', path: '/homework/preview', type: previewHomework },
  { id: 2, name: '课后作业', path: '/homework/after', type: afterHomework },
  { id: 1, name: '在线做题', path: '/homework/online', type: onlineHomework },
  { id: 10, name: '在线考试', path: '/online/exam', type: onlineExam },
  { id: 3, name: '学情分析', path: '/analysis/online/class/report', type: analysis },
  { id: 4, name: '错题本', path: '/library/problem/', type: wrongProblem },
  { id: 5, name: '网络课堂', path: `${Prefix(`VIDEOCALL`)}/h5/course`, type: netCourse },
  { id: 6, name: '平台点播课', path: '/excellent/course/platform', type: platformMicro },
  { id: 7, name: '校本点播课', path: '/excellent/course/school', type: schoolMicro },
  { id: 8, name: '区域点播课', path: '/excellent/course/area', type: areaMicro },
];

class HomeView extends React.Component {
  constructor (props) {
    super(props);
    this.state = {};
  }
  toNetCourse = (path) => {
    window.location.href = path;
  };

  render () {
    return (
      <>
        <NavBar showGoBack={false}>首页</NavBar>
        <section className='home-container'>
          <ul className='clearfix'>
            {menuList.map(item => {
              if (item.id === 5) {
                return (
                  <li key={`menu_${item.id}`}>
                    <span onClick={() => this.toNetCourse(item.path)} className='outer'>
                      <img alt='plan' src={item.type} />
                      <p>{item.name}</p>
                    </span>
                  </li>
                );
              }
              return (
                <li key={`menu_${item.id}`}>
                  <Link to={item.path} className='outer'>
                    <img alt='plan' src={item.type} />
                    <p>{item.name}</p>
                  </Link>
                </li>
              );
            })}
          </ul>
        </section>
      </>
    );
  }
}

export default HomeView;
