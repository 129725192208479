import Event from 'events';
import assign from 'object-assign';
import _ from 'underscore';

import StoreMixins from '../../mixins/StoreMixins';
import ProblemStore from './ProblemStore';
import PaperStore from './PaperStore';
import ProblemWeightList from '../../constants/ProblemWeight';
import Util from '../../lib/Util';

const EventEmitter = Event.EventEmitter;

const store = {
  updateTarget: {
    isUpdateCart: false,
    isGetInitCart: false,
    isGetPreview: false,
  },
  paperCartMap: {},
  previewCartMap: {},
  weightMap: {},
};

ProblemWeightList.forEach((item) => {
  store.weightMap[item.id] = item;
});

const CartStore = assign({}, EventEmitter.prototype, StoreMixins.mixins, {
  getUpdateTarget: function () {
    return store.updateTarget;
  },
  clearUpdateTarget: function (key) {
    store.updateTarget[key] = false;
    return store.updateTarget;
  },
  getPaperCart: function () {
    return store.paperCartMap;
  },
  deletePreviewCart: function (tIndex, pIndex, type) {
    const { typeList } = store.previewCartMap;
    try {
      const typeItem = typeList[tIndex];
      const problemItem = typeItem.problemList[pIndex];
      if (!_.isEmpty(problemItem)) {
        const problemId = problemItem.problemId || problemItem.id;
        if (type === 'weight') {
          let { totalScore, totalCount, totalWeight } = store.previewCartMap;
          totalScore -= problemItem.score;
          typeItem.totalScore = Util.accSub(typeItem.totalScore, problemItem.score);
          typeItem.problemCount -= 1;
          totalCount -= 1;
          totalWeight -= typeItem.weight;
          store.previewCartMap.totalScore = totalScore;
          store.previewCartMap.totalCount = totalCount;
          store.previewCartMap.totalWeight = totalWeight;
        }
        ProblemStore.changeHasAddProblem(problemId);
        typeItem.problemList.splice(pIndex, 1);
        this.deletePaperCart(typeItem.typeId, problemId);
        if (typeItem.problemList.length === 0) {
          typeList.splice(tIndex, 1);
        }
      }
    } catch (e) {
      console.log(e);
    }
    store.updateTarget.isGetPreview = true;
    this.emitChange();
  },
  clearPaperCart: function () {
    const { typeList } = store.previewCartMap;
    if (!typeList || typeList.length === 0) {
      return;
    }
    const hasProblem = ProblemStore.hasMoreProblem();
    const hasPaper = PaperStore.hasMoreProblem();
    if (hasProblem || hasPaper) {
      let problemList = [];
      typeList.forEach((item) => {
        const tList = item.problemList || [];
        problemList = problemList.concat(tList);
        return item;
      });
      problemList.forEach((item) => {
        if (hasProblem) {
          ProblemStore.changeHasAddProblem(item.id);
        }
        if (hasPaper) {
          PaperStore.changeHasAddProblem(item.id);
        }
      });
    }
    store.paperCartMap = {};
    store.previewCartMap = {};
    store.updateTarget.isUpdateCart = true;
    store.updateTarget.isGetPreview = true;
    this.emitChange();
  },
  deletePaperCart: function (problemTypeId, problemId) {
    const problemTypeMap = store.paperCartMap[problemTypeId];
    if (!problemTypeMap || !problemTypeMap.problemMap) {
      return;
    }
    try {
      delete problemTypeMap.problemMap[problemId];
      if (_.isEmpty(problemTypeMap.problemMap)) {
        delete store.paperCartMap[problemTypeId];
      }
    } catch (e) {
      console.log(e);
    }
    store.updateTarget.isUpdateCart = true;
    this.emitChange();
  },
  addPaperCart: function (problemTypeId, data) {
    if (!store.paperCartMap[problemTypeId]) {
      store.paperCartMap[problemTypeId] = {
        problemTypeName: data.problemTypeName,
        problemMap: {},
      };
    }
    const problemTypeMap = store.paperCartMap[problemTypeId];
    const problemId = data.problemId || data.id;
    if (problemId) {
      problemTypeMap.problemMap[problemId] = data;
    }
    store.updateTarget.isUpdateCart = true;
    this.emitChange();
  },
  addProblemListToCart: function (problemList) {
    if (_.isArray(problemList) && problemList.length > 0) {
      problemList.forEach((item) => {
        const problemType = item.problemType || item.problemTypeId;
        if (!store.paperCartMap[problemType]) {
          store.paperCartMap[problemType] = {
            problemTypeName: item.problemTypeName,
            problemMap: {},
          };
        }
        const problemTypeMap = store.paperCartMap[problemType];
        const problemId = item.problemId || item.id;
        if (problemId) {
          problemTypeMap.problemMap[problemId] = item;
        }
      });
      store.updateTarget.isUpdateCart = true;
      this.emitChange();
    }
  },
  getPreviewResult: function () {
    return store.previewCartMap;
  },
  emitHandleScore: function () {
    store.updateTarget.isGetPreview = true;
    const { totalWeight, typeList } = store.previewCartMap;
    let { totalScore } = store.previewCartMap;
    if (totalWeight > totalScore) {
      totalScore = totalWeight;
    }
    const dInteger = parseInt(totalScore / totalWeight, 10);
    const dRemainder = totalScore % totalWeight;
    const typeLen = typeList.length;
    typeList.forEach((item, index) => {
      const problemList = item.problemList;
      item.totalScore = 0;
      const pLen = problemList.length;
      problemList.forEach((pItem, pIndex) => {
        pItem.score = item.weight * dInteger;
        if (typeLen - 1 === index && pLen - 1 === pIndex) {
          pItem.score = Util.accAdd(pItem.score, dRemainder);
        }
        item.totalScore = Util.accAdd(item.totalScore, pItem.score);
      });
    });
    this.emitChange();
  },
  emitQuestionSort: function (tIndex, index, dir) {
    const cIndex = index;
    let eIndex = 0;
    if (dir === 'down') {
      eIndex = cIndex + 1;
    } else {
      eIndex = cIndex - 1;
    }
    try {
      const { typeList } = store.previewCartMap;
      const tempArr = typeList[tIndex].problemList[index];
      typeList[tIndex].problemList[index] = typeList[tIndex].problemList[eIndex];
      typeList[tIndex].problemList[eIndex] = tempArr;
      store.updateTarget.isGetPreview = true;
      this.emitChange();
    } catch (e) {
      console.log(e);
    }
  },
  handlePaperCartResult: function () {
    const cartMap = assign({}, store.paperCartMap);
    delete cartMap.total;
    const cartKeys = Object.keys(cartMap);
    const typeList = [];
    const problemList = [];
    const typeMap = {};
    let totalScore = 0;
    if (cartKeys.length > 0) {
      cartKeys.forEach((key) => {
        const problemMap = cartMap[key].problemMap;
        if (problemMap) {
          const problemKeys = Object.keys(problemMap);
          problemKeys.forEach((pkey) => {
            const problem = problemMap[pkey];
            const problemType = problem.problemType || problem.problemTypeId;
            if (typeMap[problemType]) {
              typeMap[problemType].problemCount += 1;
            } else {
              typeMap[problemType] = {
                typeId: problemType,
                typeName: problem.problemTypeName,
                problemCount: 1,
              };
              typeList.push(typeMap[problemType]);
            }
            const score = problem.score || problem.problemScore || 0;
            totalScore = Util.accAdd(totalScore, score);
            problemList.push(problem);
          });
        }
      });
    }
    this.previewWeightChange(problemList, typeList, totalScore);
  },
  previewWeightChange: function (problemList, typeList, totalScore) {
    if (problemList.length > 0) {
      const typeMap = {};
      let totalWeight = 0;
      let totalCount = 0;
      typeList.forEach((item, index) => {
        const weightItem = store.weightMap[item.typeId] || {};
        if (!item.weight) {
          item.weight = weightItem.weight || 1;
        }
        const problemCount = item.problemCount;
        const weight = item.weight * problemCount;
        totalWeight = Util.accAdd(totalWeight, weight);
        totalCount = Util.accAdd(totalCount, problemCount);
        typeList[index].index = index;
        typeMap[item.typeId] = item;
        typeList[index].problemList = [];
      });
      /**
       * 当题目单总权重大于默认总分时，将总分设置为总权重的数值
       * @type {*}
       */
      if (totalWeight > totalScore) {
        totalScore = totalWeight;
      }
      /**
       * 用总分值/总权重得出整数结果
       * @type {Number}
       */
      const dInteger = parseInt(totalScore / totalWeight, 10);
      const cartMap = {};
      const pLen = problemList.length;
      /**
       * 用总分值%总权重得出余数
       * @type {number}
       */
      const dRemainder = totalScore % totalWeight;
      problemList.forEach((item, index) => {
        const problemType = item.problemType || item.problemTypeId;
        if (!problemType) {
          return;
        }
        const typeItem = typeMap[problemType];
        if (_.isEmpty(typeItem)) {
          return;
        }
        item.score = typeItem.weight * dInteger;
        if (!typeItem.totalScore) {
          typeItem.totalScore = 0;
        }
        if (!cartMap[problemType]) {
          cartMap[problemType] = {
            problemTypeName: item.problemTypeName,
            problemMap: {},
          };
        }
        if (item.id) {
          cartMap[problemType].problemMap[item.id] = item;
        }
        typeList[typeItem.index].problemList.push(item);
        if (pLen - 1 === index) {
          item.score = Util.accAdd(item.score, dRemainder);
        }
        typeItem.totalScore = Util.accAdd(typeItem.totalScore, item.score);
      });
      store.paperCartMap = cartMap;
      store.previewCartMap = {
        ...store.previewCartMap,
        totalCount,
        totalScore,
        totalWeight,
        typeList,
      };
    }
    store.updateTarget.isUpdateCart = true;
    store.updateTarget.isGetPreview = true;
    this.emitChange();
  },
  handlePaperCartData: function (problemList) {
    const cartMap = {};
    if (problemList.length > 0) {
      problemList.forEach((item) => {
        const problemType = item.problemType || item.problemTypeId;
        const problemId = item.problemId || item.id;
        if (!problemType) {
          return;
        }
        if (!cartMap[problemType]) {
          cartMap[problemType] = {
            problemTypeName: item.problemTypeName,
            problemMap: {},
          };
        }
        if (problemId) {
          cartMap[problemType].problemMap[problemId] = item;
        }
      });
    }
    return cartMap;
  },
  paperCartChange: function (problemList, type) {
    store.updateTarget.isUpdateCart = true;
    if (type === 'init') {
      store.updateTarget.isGetInitCart = true;
    }
    const cartMap = this.handlePaperCartData(problemList);
    store.paperCartMap = cartMap;
    this.emitChange();
  },
  GET_PAPER_CART_LIST_RESPONSE: function (response, status, error) {
    if (!error) {
      const { data, returnParam } = response;
      const cartData = data || {};
      const problemList = cartData.problemList || [];
      this.paperCartChange(problemList, returnParam.type);
    }
  },
  GET_PREVIEW_CART_LIST_RESPONSE: function (response, status, error) {
    if (!error) {
      const data = response && response.data ? response.data : {};
      const problemList = data.problemList || [];
      this.previewWeightChange(problemList, data.typeList, data.totalScore);
    }
  },
  GET_PAPER_CART_DETAIL_RESPONSE: function (response, status, error) {
    if (!error) {
      store.updateTarget.isGetPreview = true;
      const cartData = response && response.data ? response.data : {};
      const problemList = cartData.problemList || [];
      const typeList = cartData.typeList || [];
      const typeMap = {};
      let totalScore = 0;
      let totalWeight = 0;
      let totalCount = 0;
      typeList.forEach((item, index) => {
        typeList[index].index = index;
        typeMap[item.typeId] = item;
        const problemCount = item.problemCount || 0;
        const weightItem = store.weightMap[item.typeId] || {};
        if (!item.weight) {
          item.weight = weightItem.weight || 1;
        }
        const weight = item.weight * problemCount;
        totalWeight = Util.accAdd(totalWeight, weight);
        totalCount = Util.accAdd(totalCount, problemCount);
        typeList[index].problemList = [];
      });
      problemList.forEach((item, index) => {
        const problemType = item.problemType || item.problemTypeId;
        if (!problemType) {
          return;
        }
        const typeItem = typeMap[problemType];
        typeList[typeItem.index].problemList.push(item);
        problemList[index].sourceId = item.sourceType || item.sourceId || 0;
        const score = item.score || item.problemScore || 0;
        problemList[index].score = score;
        typeItem.totalScore = Util.accAdd(typeItem.totalScore, score);
        totalScore = Util.accAdd(totalScore, score);
      });
      delete cartData.problemList;
      cartData.typeList = typeList;
      cartData.totalScore = totalScore;
      cartData.totalWeight = totalWeight;
      cartData.totalCount = totalCount;
      store.previewCartMap = cartData;
      this.emitChange();
    }
  },
});

CartStore.registerDispatchToken();

export default CartStore;
