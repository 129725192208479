
// const env = process.env.NODE_ENV || 'development';

const deployHost = {
  'default': {
    showTabBar: true, // 是否显示底部菜单
    encryptType: 'MD5', // 加密方式，默认为'MD5'，解密需传入'AES'
    aesKey: '', // AES加密key
    showRate: true,
  },
  'm.hdkt100.com': {
    showTabBar: true,
    showRate: false,
  },
  'jxm.hdkt100.cn': {
    showTabBar: true,
    encryptType: 'AES',
    aesKey: 'QTJXHDKTQTJXHDKT',
    showRate: true,
  },
  'hdm.zhjy.nsfzsq.cn': {
    showTabBar: true,
    showRate: false,
  },
  'ktm.xuechengcloud.com': {
    cookie_name: 'EDU_USERID',
    showTabBar: false,
    channel: 'EDU_CLOUD',
    showRate: false,
  },
  'ktm.hdkt100.com': {
    cookie_name: 'EDU_USERID',
    showTabBar: false,
    channel: 'EDU_CLOUD',
    showRate: false,
    API_PREFIX_USER: '//ucapi.hdkt100.com/api',
  },
};

const testHost = {
  'test.hdkt100.net': {
    showTabBar: true,
    showRate: true,
  },
  'ktm.hdkt100.net': {
    cookie_name: 'EDU_USERID',
    showTabBar: false,
    channel: 'EDU_CLOUD',
    showRate: false,
  },
};

const extHost = Object.assign(deployHost, testHost);

const { host } = window.location;

const extConfig = extHost[host] || extHost['default'];

export default extConfig;
