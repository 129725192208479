import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Util from '../../../../lib/Util';
import EditToggle from '../../../Common/EditToggle';
import { HomeworkStateView } from './HomeworkStateView';
import DateFormatter from '../../../../components/DateFormatter';
import { DataEmpty } from '../../../../components/DataEmpty';
import { confirmAlert } from '../../../../components/Confirm';

class HomeworkList extends React.Component {
  static propTypes = {
    homeworkList: PropTypes.array.isRequired,
    editHomework: PropTypes.func.isRequired,
    deleteHomework: PropTypes.func.isRequired,
    classId: PropTypes.number.isRequired,
    homeworkType: PropTypes.number.isRequired,
    subjectId: PropTypes.string.isRequired,
    unPublishHomeworkCount: PropTypes.number.isRequired,
  };

  constructor (props) {
    super(props);
    this.deleteHomeworkConfirm = this.deleteHomeworkConfirm.bind(this);
    this.editHomework = this.editHomework.bind(this);
    this.state = {};
  }

  deleteHomeworkConfirm (item) {
    if (item.homeworkDelStatus === 1) {
      return;
    }
    return confirmAlert({
      title: '提示',
      message: '删除任务会同时删除学生的反馈，且不可恢复，是否确认删除？',
      onConfirm: () => this.props.deleteHomework(item),
    });
  }

  editHomework (item, type) {
    if (item.homeworkDelStatus === 1) {
      return;
    }
    return this.props.editHomework(item, type);
  }

  handleSwitchPage = (item, link) => {
    Util.pageHashSwitch(link);
  };

  render () {
    const { homeworkList, classId, subjectId, homeworkType, unPublishHomeworkCount } = this.props;
    let wrapClass = 'homework-wrap';
    if (unPublishHomeworkCount || homeworkList.length > 0) {
      wrapClass = `${wrapClass} upwards`;
    }
    return (
      <div className={wrapClass}>
        {unPublishHomeworkCount ? (
          <div
            className='card-group homework-item save-homework'
            onClick={() => Util.pageHashSwitch(`/homework/draft/${classId}_${subjectId}_${homeworkType}`, true)}
          >
            <h1 className='title-default'>{`保存的任务（${unPublishHomeworkCount}）`}</h1>
          </div>
        ) : null}
        {homeworkList && homeworkList.length > 0 ? homeworkList.map((item, index) => {
          homeworkList[index].index = index;
          const { taskList, backWaitingNum, commitNum, dealNum } = item;
          const taskId = taskList && taskList[0].id;
          let previewState = 0;
          if (backWaitingNum > 0) {
            previewState = 1; // 待点评
          } else if (backWaitingNum === 0 && commitNum && dealNum && commitNum === dealNum) {
            previewState = 2; // 已点评
          } else if (backWaitingNum === 0 && commitNum === 0) {
            previewState = 3; // 未反馈
          }
          const status = item.homeworkDelStatus === 1 ? 100 : previewState;
          return (
            <div className={classnames('card-group homework-item theme-1', { 'bg-disabled': item.homeworkDelStatus === 1 })} key={`homework_item_${item.id}`}>
              {
                item.homeworkDelStatus === 1 ? (
                  <div className='mask-delete' />
                ) : null
              }
              <div className='homework-header'>
                <div className='flex flex-center'>
                  <span className='subject-short'>
                    {item.subjectShortName}
                  </span>
                  <div className='publish-info grow-full'>
                    <h4>{item.teacherName ? `${item.teacherName} 老师` : ''}</h4>
                    <DateFormatter timestamp={item.deadline} />
                  </div>
                  <HomeworkStateView className='reviewed-status' state={status} />
                  <EditToggle disabled={item.homeworkDelStatus === 1}>
                    <ul className='tag-list'>
                      {/* <li className='tag-item'>
                        <span onClick={() => this.editHomework(item, 'publish')}>
                          再次发布
                        </span>
                      </li> */}
                      {item.isEnd ? null : (
                        <li className='tag-item'>
                          <span onClick={() => this.editHomework(item, 'edit')}>
                            编辑任务
                          </span>
                        </li>
                      )}
                      <li className='tag-item'>
                        <span onClick={() => this.deleteHomeworkConfirm(item)}>
                          删除任务
                        </span>
                      </li>
                    </ul>
                  </EditToggle>
                </div>
              </div>
              {/* TODO */}
              <section className='homework-section'>
                <div
                  className='title flex flex-center'
                  onClick={() => this.handleSwitchPage(item, `/homework/preview/detail/${item.id}/${classId}/`)}
                >
                  <h1 className='ellipsis'>{item.homeworkName}</h1>
                  {item.tagName ? (
                    <label htmlFor='tag' className='tag-wrap theme-bg-1'>
                      {item.tagName}
                    </label>
                  ) : null}
                </div>
                <div className='explain'>
                  <p>{item.homeworkExplain}</p>
                </div>
                <div className='progress flex'>
                  <div className='h-s-status'
                    onClick={() => this.handleSwitchPage(item, `/homework/preview/submit/detail/${item.id}/${classId}/${taskId}`)}>
                    <span className='count' style={{ color: '#2CCB4D' }}>
                      {item.commitNum || 0}
                    </span>
                    <p className='status-title'>已反馈</p>
                  </div>
                  <div className='h-s-status'
                    onClick={() => this.handleSwitchPage(item, `/homework/preview/submit/detail/${item.id}/${classId}/${taskId}`)}>
                    <span className='count' style={{ color: '#FA5C29' }}>
                      {item.dealNum || 0}
                    </span>
                    <p className='status-title'>已点评</p>
                  </div>
                  {
                    previewState === 1 ? (
                      <div onClick={() => this.handleSwitchPage(item, `/homework/preview/review/detail/${item.id}/${classId}/${taskId}`)} className='corrections'>
                        去点评
                      </div>
                    ) : null
                  }
                  {
                    previewState === 2 ? (
                      <div className='consult' onClick={() => this.handleSwitchPage(item, `/homework/preview/review/detail/${item.id}/${classId}/${taskId}`)} >
                        去查阅
                      </div>
                    ) : null
                  }
                </div>
              </section>
            </div>
          );
        }) : (<DataEmpty height={100} content='暂无预习任务' />)}
      </div>
    );
  }
}

export default HomeworkList;
