export default {
  name: '查看课后作业',
  path: '/homework/after/check/:homeworkStudentId',
  getComponent (nextState, cb) {
    import('./components/index').then((view) => {
      cb(null, view.default);
    });
  },
};

