import Event from 'events';
import assign from 'object-assign';
import StoreMixins from '../mixins/StoreMixins';

const EventEmitter = Event.EventEmitter;

const store = {
  updateTarget: {},
  verifyInfo: {},
};

const UserStore = assign({}, EventEmitter.prototype, StoreMixins.mixins, {
  getUpdateTarget: function () {
    return store.updateTarget;
  },
  clearUpdateTarget: function (key) {
    store.updateTarget[key] = false;
    return store.updateTarget;
  },
  getVerifyInfo: function () {
    return store.verifyInfo;
  },
  GET_VERIFY_CODE_RESPONSE: function (response, status, error) {
    if (!error) {
      store.updateTarget.isGetVerifyCode = true;
      store.verifyInfo = response && response.data ? response.data : {};
      this.emitChange();
    }
  },
});

UserStore.registerDispatchToken();
export default UserStore;
