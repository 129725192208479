import React, { PureComponent } from 'react';
import { Modal } from 'antd-mobile';
import PropTypes from 'prop-types';

class SetClassModal extends PureComponent {
  static defaultProps = {
    visible: false,
    onPress: () => null,
  };

  static propTypes = {
    visible: PropTypes.bool,
    onPress: PropTypes.func,
  };

  render () {
    const { visible, onPress } = this.props;
    return (
      <Modal
        visible={visible}
        transparent
        maskClosable={false}
        title='提示'
        footer={[{ text: '知道了', onPress }]}
        className='hd-modal-transparent'
      >
        <div className='default-tip'>
          <p>老师您好，系统检测到您暂无任课班级为了更好的使用系统，请您：</p>
          <p>1、前往网页端的个人中心内进行设置</p>
          <p>2、联系管理员帮您统一设置</p>
        </div>
      </Modal>
    );
  }
}

export default SetClassModal;
