import React from 'react';
import Moment from 'moment';

/**
 * @return {number}
 */
const GetRelativeDate = (timestampstr) => {
  const thenT = Moment(timestampstr).startOf('day');
  const nowtime = Moment().startOf('day');
  const delt = Moment(nowtime).diff(Moment(thenT), 'days');
  return delt;
};

const DateFormatter = (timeObj) => {
  const timestamps = timeObj.timestamp;
  Moment.locale('zh-cn');
  const isPast = Moment().isAfter(timestamps);
  const isSame = Moment().isSame(timestamps);
  const days = Moment(timestamps).calendar().substr(0, 2);
  const children = (times) => {
    if (isPast || isSame) {
      return (
        <p>
          <span>{Moment(times).format('MM-DD HH:mm')}</span>
          <span style={{ color: 'red' }}> 已截止</span>
        </p>
      );
    }
    if (days === '今天' || days === '明天') {
      return (
        <p>
          <span>{`${days} ${Moment(times).format('HH:mm')}`}</span>
          <span> 截止</span>
        </p>
      );
    } else if (GetRelativeDate(timestamps) === -2) {
      return (
        <p>
          <span>后天 {`${Moment(times).format('MM-DD HH:mm')}`}</span>
          <span> 截止</span>
        </p>
      );
    }
    return (
      <p>
        <span>{`${Moment(times).format('MM-DD HH:mm')}`}</span>
        <span> 截止</span>
      </p>
    );
  };
  return (
    <div className='formatter'>
      {children(timestamps)}
    </div>
  );
};

export default DateFormatter;
