import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import DataEmpty from '../../../components/DataEmpty';
import { redirectTo } from '../../../lib/authority';

class UserList extends React.Component {
  static propTypes = {
    userList: PropTypes.array.isRequired,
  };

  onSelectUser = (user) => {
    redirectTo({
      userType: user.userType,
      token: user.token,
    });
  };

  render () {
    const { userList } = this.props;
    if (userList.length === 0) {
      return (
        <DataEmpty content='暂无数据' />
      );
    }
    return (
      <div className='Profile_User'>
        {userList.map((item, index) => (
          <div
            key={`user_${index}`}
            className={item.isSelected ? 'user-item active' : 'user-item'}
            onClick={() => this.onSelectUser(item)}
          >
            <p className='user-type'>{item.userType === 1 ? '教师' : '学生'}</p>
            <p className='user-name'>{item.name} {item.userType === 1 ? '老师' : '同学'}</p>
          </div>
        ))}
      </div>
    );
  }
}

export default UserList;
