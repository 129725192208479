export default {
  name: '我的留言',
  path: '/profile/message',
  color: 'f0f1f6',
  getComponent (nextState, cb) {
    import('./components/Index').then((view) => {
      cb(null, view.default);
    });
  },
};
