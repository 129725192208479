import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { renderComponent, unmountComponent } from '../lib/ReactUtil';

const CONFIRM_ID = `confirm-modal-${(new Date().getTime())}`;

class Confirm extends React.Component {
  static defaultProps = {
    title: '',
    message: '',
    confirmText: '确认',
    cancelText: '取消',
    onConfirm: () => null,
    onCancel: () => null,
  };
  static propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  };

  static onClose () {
    return unmountComponent(CONFIRM_ID);
  }

  constructor (props) {
    super(props);
    this.onClickConfirm = this.onClickConfirm.bind(this);
    this.onClickCancel = this.onClickCancel.bind(this);
  }

  componentDidMount () {
  }

  onClickConfirm () {
    this.props.onConfirm();
    Confirm.onClose();
  }

  onClickCancel () {
    this.props.onCancel();
    Confirm.onClose();
  }

  render () {
    const { title, message, cancelText } = this.props;
    const me = this;
    return (
      <div className='confirm-modal-visible ui-center'>
        <div className='confirm-modal'>
          <div className='confirm-outer'>
            <div className='confirm-header'>
              <h2>{title}</h2>
            </div>
            <div className='confirm-section'>
              <div className='confirm-tip ui-center-block'>
                <p>{message}</p>
              </div>
            </div>
            <div className='confirm-footer'>
              <div className={classnames('confirm-btn-group flex', cancelText ? '' : 'flex-ui-center')}>
                {cancelText ? (
                  <span className='btn-cancel' onClick={me.onClickCancel}>
                    取消
                  </span>
                ) : null}
                <span className='btn-confirm' onClick={me.onClickConfirm}>
                    确认
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className='hd-popup-mask' />
      </div>
    );
  }
}

export default Confirm;

export function confirmAlert (properties) {
  return renderComponent(<Confirm {...properties} />, CONFIRM_ID);
}
export function hideAlert () {
  unmountComponent(CONFIRM_ID);
}
