import Event from 'events';
import assign from 'object-assign';
import _ from 'underscore';

import CartStore from './CartStore';
import StoreMixins from '../../mixins/StoreMixins';
import ProblemWeight from '../../constants/ProblemWeight';
import Util from '../../lib/Util';
import ProblemAction from '../../actions/teacher/ProblemAction';

const EventEmitter = Event.EventEmitter;

const store = {
  updateTarget: {
    isGetProblemList: false,
    isGetProblemCondition: false,
    isGetPaperDetails: false,
    isGetMaterial: false,
    isGetGrade: false,
  },
  treeNodeList: [],
  treeNodeMap: {},
  problemInfo: {},
  problemMap: {},
  problemCondition: {},
  paperDetails: {},
  problemTypeDict: {},
  teachingMaterialGradeMap: {},
  teachingMaterialMap: {},
};

ProblemWeight.forEach((item) => {
  store.problemTypeDict[item.id] = item.name;
});

let cartTimer = null;

const ProblemStore = assign({}, EventEmitter.prototype, StoreMixins.mixins, {
  getUpdateTarget: function () {
    return store.updateTarget;
  },
  clearUpdateTarget: function (key) {
    store.updateTarget[key] = false;
    return store.updateTarget;
  },
  getTreeNodeList: function () {
    return store.treeNodeList;
  },
  setTreeNode: function (key, value) {
    store.treeNodeMap[key] = value;
  },
  clearTreeNode: function () {
    store.treeNodeList = [];
    store.treeNodeMap = {};
  },
  getProblemInfo: function () {
    return store.problemInfo;
  },
  getProblemCondition: function (subjectId) {
    return store.problemCondition[subjectId];
  },
  clearProblemMap: function () {
    store.problemInfo = {};
    store.problemMap = {};
  },
  hasMoreProblem: function () {
    const { problemList } = store.problemInfo;
    if (!problemList || problemList.length === 0) {
      return false;
    }
    return true;
  },
  changeHasAddProblem: function (problemId) {
    const me = this;
    if (store.problemMap[problemId]) {
      store.problemMap[problemId].hasAdded = false;
      if (cartTimer) {
        clearTimeout(cartTimer);
      }
      cartTimer = setTimeout(() => {
        store.updateTarget.isGetProblemList = true;
        me.emitChange();
      }, 100);
    }
  },
  getPaperDetails: function () {
    return store.paperDetails;
  },
  getProblemTypeDict: function (id) {
    if (id) {
      return store.problemTypeDict[id];
    }
    return store.problemTypeDict;
  },
  getTeachingMaterialList: function (subjectId, hasRequest) {
    const materialList = store.teachingMaterialMap[subjectId];
    if (_.isArray(materialList) && materialList.length > 0) {
      if (hasRequest) {
        setTimeout(() => {
          store.updateTarget.isGetMaterial = true;
          this.emitChange();
        }, 0);
        return false;
      }
      return Util.deepCopy(materialList);
    }
    if (hasRequest) {
      setTimeout(() => {
        ProblemAction.getTeachingMaterialList(subjectId);
      }, 0);
      return false;
    }
    return [];
  },
  getTeachingGradeList: function (materialId, hasRequest) {
    const gradeList = store.teachingMaterialGradeMap[materialId];
    if (_.isArray(gradeList) && gradeList.length > 0) {
      if (hasRequest) {
        setTimeout(() => {
          store.updateTarget.isGetGrade = true;
          this.emitChange();
        }, 0);
        return false;
      }
      return Util.deepCopy(gradeList);
    }
    if (hasRequest) {
      setTimeout(() => {
        ProblemAction.getTeachingGradeList(materialId);
      }, 0);
      return false;
    }
    return [];
  },
  getAsyncTeachingMaterialList: function (subjectId) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const materialList = store.teachingMaterialMap[subjectId];
        if (_.isEmpty(materialList)) {
          ProblemAction.getTeachingMaterialList(subjectId, false).then((response) => {
            if (response.success) {
              const data = response.data || [];
              store.teachingMaterialMap[subjectId] = data;
              const copyData = Util.deepCopy(data);
              resolve(copyData);
            } else {
              reject(response.msg);
            }
          });
        } else {
          const copyData = Util.deepCopy(materialList);
          resolve(copyData);
        }
      }, 0);
    });
  },
  getAsyncTeachingGradeList: function (materialId) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const gradeList = store.teachingMaterialGradeMap[materialId];
        if (_.isEmpty(gradeList)) {
          ProblemAction.getTeachingGradeList(materialId, false).then((response) => {
            if (response.success) {
              const data = response.data || [];
              store.teachingMaterialGradeMap[materialId] = data;
              const copyData = Util.deepCopy(data);
              resolve(copyData);
            } else {
              reject(response.msg);
            }
          });
        } else {
          const copyData = Util.deepCopy(gradeList);
          resolve(copyData);
        }
      }, 0);
    });
  },
  handleCartProblemList: function (problemList) {
    store.problemMap = {};
    const cartMap = CartStore.getPaperCart();
    problemList.forEach((item) => {
      const problemType = item.problemType || item.problemTypeId;
      const problemId = item.problemId || item.id;
      if (cartMap[problemType] && cartMap[problemType].problemMap[problemId]) {
        item.hasAdded = true;
      } else {
        item.hasAdded = false;
      }
      store.problemMap[problemId] = item;
    });
  },
  GET_CHAPTER_LIST_RESPONSE: function (response, status, error) {
    if (!error) {
      store.updateTarget.isGetChapter = true;
      const { returnParam, data } = response;
      const chapterList = data || [];
      if (returnParam.parentId) {
        const parentNode = store.treeNodeMap[returnParam.parentId];
        parentNode.children = chapterList;
        parentNode.isOpen = true;
      } else {
        store.treeNodeList = chapterList;
      }
      this.emitChange();
    }
  },
  GET_KNOWLEDGE_LIST_RESPONSE: function (response, status, error) {
    if (!error) {
      store.updateTarget.isGetChapter = true;
      const { returnParam, data } = response;
      const knowledgeList = data || [];
      if (returnParam.parentId) {
        const parentNode = store.treeNodeMap[returnParam.parentId];
        parentNode.children = knowledgeList;
        parentNode.isOpen = true;
      } else {
        store.treeNodeList = knowledgeList;
      }
      this.emitChange();
    }
  },
  GET_PROBLEM_LIST_RESPONSE: function (response, status, error) {
    if (!error) {
      store.updateTarget.isGetProblemList = true;
      const { data, returnParam, pageInfo } = response;
      let tempProblemList = data || [];
      this.handleCartProblemList(tempProblemList);
      const { problemList } = store.problemInfo;
      if (returnParam.currentPage !== 1) {
        tempProblemList = problemList.concat(tempProblemList);
      }
      store.problemInfo = { problemList: tempProblemList, pageInfo: pageInfo };
      this.emitChange();
    }
  },
  GET_PROBLEM_FILTER_CONDITION_RESPONSE: function (response, status, error) {
    if (!error) {
      store.updateTarget.isGetProblemCondition = true;
      const { returnParam, data } = response;
      const condition = data || {};
      if (!_.isEmpty(condition)) {
        Object.keys(condition).forEach((key) => {
          if (_.isArray(condition[key])) {
            let name = '';
            switch (key) {
              case 'diffcultyList':
                name = '全部难度';
                break;
              case 'kindList':
                name = '全部题类';
                break;
              case 'typeList':
                name = '全部题型';
                break;
              default:
                break;
            }
            condition[key].unshift({
              id: 0, name: name,
            });
          }
        });
      }
      store.problemCondition[returnParam.subjectId] = condition;
      this.emitChange();
    }
  },
  GET_PAPER_DETAILS_RESPONSE: function (response, status, error) {
    if (!error) {
      store.updateTarget.isGetPaperDetails = true;
      try {
        const paperDetails = response && response.data ? response.data : {};
        const problemMap = {};
        const problemList = paperDetails.problemList || [];
        problemList.forEach((item) => {
          if (!item.problemType) {
            return false;
          }
          if (!problemMap[item.problemType]) {
            problemMap[item.problemType] = [];
          }
          problemMap[item.problemType].push(item);
          return problemMap;
        });
        paperDetails.problemMap = problemMap;
        delete paperDetails.problemList;
        store.paperDetails = paperDetails;
      } catch (e) {
        console.log(e);
      }
      this.emitChange();
    }
  },
  GET_PROBLEM_TEACHER_MATERIAL_LIST_RESPONSE: function (response, status, error) {
    if (!error) {
      store.updateTarget.isGetMaterial = true;
      const { data, returnParam } = response;
      const { classId, subjectId } = returnParam;
      let key = subjectId;
      if (classId) {
        key = `${classId}-${subjectId}`;
      }
      store.teachingMaterialMap[key] = data || [];
      this.emitChange();
    }
  },
  GET_PROBLEM_TEACHER_GRADE_LIST_RESPONSE: function (response, status, error) {
    if (!error) {
      store.updateTarget.isGetGrade = true;
      const { data, returnParam } = response;
      store.teachingMaterialGradeMap[returnParam.teachingMaterialId] = data || [];
      this.emitChange();
    }
  },
});

ProblemStore.registerDispatchToken();
export default ProblemStore;
