import React from 'react';

import Util from '../lib/Util';
import ProfileAction from '../actions/ProfileAction';
import ProfileStore from '../stores/ProfileStore';
import AppStore from '../stores/AppStore';
import { setLaterState } from '../lib/authority';

import './SetSubjectModal.scss';

class SetSubjectModal extends React.Component {
  constructor (props) {
    super(props);
    this.onClickConfirm = this.onClickConfirm.bind(this);
    this.onClickCancel = this.onClickCancel.bind(this);
    const userInfo = AppStore.getUserInfo();
    this.state = {
      userInfo,
      setSubjectList: [],
    };
  }

  componentDidMount () {
    ProfileStore.addChangeListener(this.onChange);
    this.getSetSubjectClass();
  }

  componentWillUnmount () {
    ProfileStore.removeChangeListener(this.onChange);
  }
  onChange = () => {
    const updateTarget = ProfileStore.getUpdateTarget();
    if (updateTarget.isGetSetSubject) {
      ProfileStore.clearUpdateTarget('isGetSetSubject');
      const setSubjectList = ProfileStore.getSetSubjectList();
      this.setState({ setSubjectList });
    }
  };
  getSetSubjectClass = () => {
    ProfileAction.getSetSubjectClass();
  };

  onClickConfirm () {
    setLaterState();
    AppStore.emitUpdateClassInfo();
    Util.pageHashSwitch('/profile/curriculum/material/');
  }

  onClickCancel () {
    setLaterState();
    AppStore.emitUpdateClassInfo();
  }

  render () {
    const { setSubjectList } = this.state;
    const me = this;
    return (
      <div className='confirm-modal-visible ui-center'>
        <div className='confirm-modal'>
          <div className='confirm-outer'>
            <div className='confirm-header'>
              <h2>提示</h2>
            </div>
            <div className='confirm-section'>
              <div className='confirm-tip ui-center-block'>
                <p className='description'>老师您好，系统检测到您教授的部分班级暂未绑定学科。为了更好地进行备课，请前往个人中心进行设置。</p>
                <p className='description'>未绑定学科的班级：</p>
                <ul className='flex class-wrap'>
                  {setSubjectList.map(item => (
                    <li key={`class_${item.classId}`}>{item.className}</li>
                  ))}
                </ul>
              </div>
            </div>
            <div className='confirm-footer'>
              <div className='confirm-btn-group flex'>
                <span className='btn-cancel' onClick={me.onClickCancel}>
                  稍后再说
                </span>
                <span className='btn-confirm' onClick={me.onClickConfirm}>
                  立即设置
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className='hd-popup-mask' />
      </div>
    );
  }
}

export default SetSubjectModal;
