import Cookies from 'js-cookie';
import { parse } from 'qs';

import extConfig from '../constants/ext';
import Prefix from '../constants/Prefix';

const env = process.env.NODE_ENV || 'development';

const ua = navigator.userAgent.toLowerCase();

const isWeiXin = ua.match(/MicroMessenger/i) == 'micromessenger';


export function getCookieDomain () {
  const hostname = window.location.hostname;
  const locationPath = hostname.split(/\W+/g);
  const len = locationPath.length;
  const path0 = locationPath[0];
  if (!!Number(path0) || locationPath.length < 2) {
    return hostname;
  }
  return `.${locationPath[len - 2]}.${locationPath[len - 1]}`;
}

/**
 * 获取地址参数
 */
export function getPageQuery() {
  return parse(window.location.href.split('?')[1]);
}

export const globalData = {
  COOKIE_NAME: extConfig.cookie_name || 'USERID',
  token: '',
  openId: Cookies.get('WXID'),
  isWeiXin,
  isDevOrWeiXin: env === 'development' || isWeiXin,
  COOKIE_DOMAIN: getCookieDomain(),
  placeholder: isWeiXin ? '点击此处可添加文字，点击“+”号可添加照片、录音。' : '点击此处可添加文字，点击“+”号可添加照片。',
  schoolId: '',
  PLATFORM_CODE: 'hdkt',
};

export function setAuthority({ token, userType, expires }) {
  Cookies.set(globalData.COOKIE_NAME, `${token}&${userType}`,
    { expires, path: '/', domain: globalData.COOKIE_DOMAIN });
}

const { token, userType, roleId } = getPageQuery();

if (token && userType) {
  setAuthority({ token, userType, roleId, expires: 7 })
}

const USERID = Cookies.get(globalData.COOKIE_NAME);

if (USERID) {
  const tokenSplit = USERID.split('&');
  globalData.token = tokenSplit[0] || null;
  globalData.level = tokenSplit[1] || null;
}

export function removeAuthority() {
  Cookies.remove(globalData.COOKIE_NAME, { domain: globalData.COOKIE_DOMAIN });
  Cookies.remove('SLATER', { domain: globalData.COOKIE_DOMAIN });
  Cookies.remove('SLATER_MATERIAL', { domain: globalData.COOKIE_DOMAIN });
  window.localStorage.clear();
  if (globalData.openId) {
    window.location.href = `/wx/login/${globalData.openId}`;
  } else {
    window.location.href = Prefix('LOGIN');
  }
}

/**
 * 设置学科教材弹窗状态
 */
export function setLaterState (name = 'SLATER') {
  Cookies.set(name, '1', { path: '/', domain: globalData.COOKIE_DOMAIN });
}

/**
 * 登录跳转
 */
export function redirectTo(data, expires = null) {
  let to = '/';
  setAuthority({ token: data.token, userType: data.userType, expires });
  setTimeout(() => {
    window.location.replace(to);
  }, 0);
}
