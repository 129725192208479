export default {
  name: '作业详情',
  path: '/homework/online/detail/:homeworkId/:classId',
  trackPath: '/homework/online/detail',
  color: 'f0f1f6',
  getComponent (nextState, cb) {
    import('./components/HomeworkDetails').then((view) => {
      cb(null, view.default);
    });
  },
};
