import * as React from 'react';
import classNames from 'classnames';

const Icon = (props) => {
  const { type, className = '', spin } = props;
  const classString = classNames({
    [`icon-${type}`]: true,
    icon: true,
    'icon-spin': !!spin || type === 'loading',
  }, className);
  return <i {...props} className={classString} />;
};

export default Icon;
