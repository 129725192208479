import flux from 'flux';
import assign from 'object-assign';

import Constants from '../constants/AppConstants';

const PayloadSources = Constants.PayloadSources;
const Dispatcher = flux.Dispatcher;

const AppDispatcher = assign(new Dispatcher(), {
  handleServerAction: function (action) {
    const payload = {
      source: PayloadSources.SERVER_ACTION,
      action: action,
    };
    if (console) {
      console.log(action.type);
    }
    this.dispatch(payload);
  },

  handleViewAction: function (action) {
    const payload = {
      source: PayloadSources.VIEW_ACTION,
      action: action,
    };
    if (console) {
      console.log(action.type);
    }
    this.dispatch(payload);
  },
});

export default AppDispatcher;
