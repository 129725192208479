import React from 'react';
import PropTypes from 'prop-types';
import classSet from 'classnames';

class EditToggle extends React.Component {
  static defaultProps = {
    className: '',
    iconTheme: 'vertical-more',
    menuDirection: 'right',
    disabled: false,
  };
  static propTypes = {
    className: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired,
    iconTheme: PropTypes.string.isRequired,
    menuDirection: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
  };

  constructor (props) {
    super(props);
    this.toggleOpen = this.toggleOpen.bind(this);
    this.state = { isOpen: false, isUpdate: false };
  }

  shouldComponentUpdate (nextProps, nextState) {
    return nextState.isUpdate;
  }

  toggleOpen () {
    const { disabled } = this.props;
    if (disabled) {
      return;
    }
    this.setState({ isOpen: !this.state.isOpen, isUpdate: true });
  }

  render () {
    const { isOpen } = this.state;
    const { className, iconTheme, menuDirection } = this.props;
    const animateClass = classSet('active-animate', { active: isOpen });
    const menuClass = classSet('dot-toggle-menu',
      { active: isOpen }, { right: menuDirection === 'right' });
    return (
      <div className={`dot-toggle-wrap unselect ${className}`}>
        <div
          className='dot-toggle'
          onClick={this.toggleOpen}
        >
          <i className={`icon-${iconTheme}`} />
          <div className={animateClass} />
        </div>
        <div className={menuClass} onClick={this.toggleOpen}>
          {this.props.children}
        </div>
        {isOpen ? (
          <div
            className='pop-overlay pop-fixed transparent'
            onClick={this.toggleOpen}
          />
        ) : null}
      </div>
    );
  }
}

export default EditToggle;
