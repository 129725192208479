import React from 'react';
import ReactDOM from 'react-dom';

/**
 * 移除组建
 * @param id
 * @returns {boolean}
 */
export function unmountComponent (id) {
  const target = document.getElementById(id);
  if (!target) {
    return false;
  }
  ReactDOM.unmountComponentAtNode(target);
  target.parentNode.removeChild(target);
}

/**
 * render modal
 * @param render
 * @param id
 */
export function renderComponent (component, id) {
  const doc = window.document;
  const divTarget = document.createElement('div');
  divTarget.id = id;
  doc.body.appendChild(divTarget);
  ReactDOM.render(component, divTarget);
}
