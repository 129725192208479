export default {
  name: '批改',
  path: '/homework/online/reviewed/:homeworkId/:classId',
  trackPath: '/homework/online/reviewed/',
  color: 'f0f1f6',
  getComponent (nextState, cb) {
    import('./components/ReviewedView').then((view) => {
      cb(null, view.default);
    });
  },
};
