export default {
  name: '综合提分报告',
  path: '/analysis/:type/student/improve/score',
  getComponent (nextState, cb) {
    import('./components/ImproveScoreView').then((view) => {
      cb(null, view.default);
    });
  },
};

