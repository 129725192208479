import Event from 'events';
import assign from 'object-assign';
import StoreMixins from '../mixins/StoreMixins';
import _ from 'underscore';
import Util from '../lib/Util';

import ProfileAction from '../actions/ProfileAction';

const EventEmitter = Event.EventEmitter;

const store = {
  updateTarget: {
    isGetTaskScore: false,
    isGetStudent: false,
    isGetSubjectMaterial: false,
    isGetSetSubject: false,
    isGetProfileUserList: false,
    isGetBpMessageList: false,
  },
  taskScoreList: [],
  profileStudentList: [],
  profileStudentInfo: {},
  subjectMaterialList: [],
  setSubjectList: [],
  profileUserList: [],
  bpMessageList: []
};

const ProfileStore = assign({}, EventEmitter.prototype, StoreMixins.mixins, {
  getUpdateTarget: function () {
    return store.updateTarget;
  },
  clearUpdateTarget: function (key) {
    store.updateTarget[key] = false;
    return store.updateTarget;
  },
  getTaskScoreList: function () {
    return store.taskScoreList;
  },
  getProfileStudentList: function () {
    return store.profileStudentList;
  },
  getProfileStudentInfo: function (classId, hasRequest) {
    const studentInfo = store.profileStudentInfo[classId];
    if (studentInfo && !_.isEmpty(studentInfo)) {
      if (hasRequest) {
        store.updateTarget.isGetStudent = true;
        // return store.profileStudentInfo[classId];
        this.emitChange();
        return false;
      }
      return Util.deepCopy(studentInfo);
    }
    if (hasRequest) {
      ProfileAction.getStudentList();
      return false;
    }
    return {};
  },
  getSubjectMaterialList: function () {
    return store.subjectMaterialList;
  },
  getSetSubjectList: function () {
    return store.setSubjectList;
  },
  clearSetSubjectList: function () {
    store.setSubjectList = [];
  },
  getProfileUserList: function () {
    return store.profileUserList;
  },
  getBpMessageList: function () {
    return store.bpMessageList;
  },
  GET_PROFILE_TASK_SCORE_RESPONSE: function (response, status, error) {
    if (!error) {
      store.updateTarget.isGetTaskScore = true;
      const { data } = response;
      store.taskScoreList = data || [];
      this.emitChange();
    }
  },
  GET_PROFILE_STUDENT_LIST_RESPONSE: function (response, status, error) {
    if (!error) {
      store.updateTarget.isGetStudent = true;
      const { data } = response;
      store.profileStudentList = data || [];
      const profileStudentInfo = {};
      data.forEach((item, index) => {
        profileStudentInfo[item.classId] = item;
      });
      store.profileStudentInfo = profileStudentInfo;
      this.emitChange();
    }
  },
  GET_SUBJECT_TEACH_MATERIAL_RESPONSE: function (response, status, error) {
    if (!error) {
      store.updateTarget.isGetSubjectMaterial = true;
      const { data } = response;
      store.subjectMaterialList = data || [];
      this.emitChange();
    }
  },
  GET_SET_SUBJECT_CLASS_RESPONSE: function (response, status, error) {
    if (!error) {
      store.updateTarget.isGetSetSubject = true;
      const { data } = response;
      store.setSubjectList = data || [];
      this.emitChange();
    }
  },
  GET_PROFILE_USER_LIST_RESPONSE: function (response, status, error) {
    if (!error) {
      store.updateTarget.isGetProfileUserList = true;
      const { data } = response;
      store.profileUserList = data || [];
      this.emitChange();
    }
  },
  /**
   * 江西BP机-家长获取留言信息列表
   * @param response
   * @param status
   * @param error
   * @constructor
   */
  GET_BP_MESSAGE_LIST_RESPONSE: function (response, status, error) {
    if (!error) {
      store.updateTarget.isGetBpMessageList = true;
      const { data } = response;
      store.bpMessageList = data || [];
      this.emitChange();
    }
  },
});

ProfileStore.registerDispatchToken();
export default ProfileStore;
