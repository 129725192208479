export default {
  name: '课堂报告详情',
  path: '/analysis/:type/class/report/:classId/:subjectId/:classScheduleId',
  trackPath: '/analysis/class/report/details/',
  getComponent (nextState, cb) {
    import('./components/AnalysisClassReportDetail').then((view) => {
      cb(null, view.default);
    });
  },
};
