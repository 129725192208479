export default {
  name: '备课',
  path: '/lesson',
  color: 'f0f1f6',
  getComponent (nextState, cb) {
    import('./components/LessonView').then((view) => {
      cb(null, view.default);
    });
  },
};
