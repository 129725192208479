import ext from '../../../constants/ext';
import { encrypt, encryptMd5 } from '../../../lib/CryptoUtil';
import Constants from '../../../constants/AppConstants';

/**
 * 密码加密
 * @param word
 */
export function passwordEncrypt (word) {
  if (ext.encryptType === 'AES') {
    return encrypt(word, ext.aesKey);
  }
  return encryptMd5(`${word}_${Constants.PASSWORD_SUFFIX}`);
}
