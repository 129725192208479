// 预习任务
import assign from 'object-assign';
import Constants from '../../constants/AppConstants';
import request from '../../lib/request';
import Prefix from '../../constants/Prefix';
import ActionMixins from '../../mixins/ActionMixins';

const ActionTypes = Constants.ActionTypes;

const PreviewHomeworkAction = assign({}, ActionMixins.mixins, {
  // 获取课后作业列表
  getAfterHomework: function (data) {
    const { classId, subjectId, homeworkType, currentPage, pageSize } = data;
    const url = `${Prefix('HDKT')}/homework/teacher/homeworkList/v1/${classId}/${subjectId}/${homeworkType}?currentPage=${currentPage}&pageSize=${pageSize}`;
    const options = {
      method: 'GET',
    };
    return request({
      type: ActionTypes.GET_AFTER_HOMEWORK_LIST,
      url,
      options,
      returnParam: { currentPage },
    });
  },
  /**
   * 发布或编辑预习任务
   */
  savePreviewHomework: function (data) {
    const url = `${Prefix('HDKT')}/homework/preview/teacher/saveBeforeClassHomework/v1`;
    const options = {
      method: 'POST',
      body: data,
    };
    return request({
      url,
      options,
    });
  },

  deleteHomeworkById: function (homeworkId, index) {
    const url = `${Prefix('HDKT')}/homework/preview/teacher/deleteBeforeHomework/v1`;
    const data = { homeworkId };
    const options = {
      method: 'POST',
      body: data,
    };
    return request({
      url,
      options,
      returnParam: { index },
    });
  },

  /**
   * 获取预习任务详情
   */
  getPreviewHomeworkById: function (homeworkId) {
    const url = `${Prefix('HDKT')}/homework/preview/getBeforeClassHomework/v1/${homeworkId}`;
    const options = {
      method: 'GET',
    };
    return request({
      // type: ActionTypes.GET_AFTER_HOMEWORK_BY_ID,
      url,
      options,
    });
  },

  // 获取提交详情
  getReviewDetailList: function (data) {
    const { classId, homeworkId } = data;
    const url = `${Prefix('HDKT')}/homework/preview/teacher/studentCommitInfoList/v1/${classId}/${homeworkId}`;
    const options = {
      method: 'GET',
    };
    return request({
      url,
      options,
    });
  },

  // 获取学生答案
  getStudentAnswer: function (homeworkId, studentId) {
    const url = `${Prefix('HDKT')}/homework/preview/teacher/correctPage/v1/${homeworkId}/${studentId}`;
    const options = {
      method: 'GET',
    };
    return request({
      url,
      options,
    });
  },

  // 提交学生成绩
  putStudentScore: function (data) {
    const url = `${Prefix('HDKT')}/homework/preview/teacher/task/saveScore/v1`;
    const options = {
      method: 'POST',
      body: data,
    };
    return request({
      url,
      options,
    });
  },

  // 获取课后作业详情
  getReviewDetail: function (homeworkId, classId) {
    const url = `${Prefix('HDKT')}/homework/preview/teacher/get/correct/afterClassHomeworkInfo/v1/${homeworkId}/${classId}`;
    const options = {
      method: 'GET',
    };
    return request({
      url,
      options,
    });
  },
});

export default PreviewHomeworkAction;
