export default {
  name: '作业',
  path: '/homework/after',
  color: 'f0f1f6',
  getComponent (nextState, cb) {
    import('./components/index').then((view) => {
      cb(null, view.default);
    });
  },
};
