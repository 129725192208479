export default {
  name: '班级管理',
  path: '/class/manage/:classId/:subjectId',
  trackPath: '/class/manage/',
  getComponent (nextState, cb) {
    import('./components/ClassManageView').then((view) => {
      cb(null, view.default);
    });
  },
};
