import Event from 'events';
import assign from 'object-assign';

import StoreMixins from '../../mixins/StoreMixins';
import Util from '../../lib/Util';

const EventEmitter = Event.EventEmitter;

const store = {
  updateTarget: {
    isGetHomeworkList: false,
    isGetReviewedList: false,

    isGetHomeworkDetails: false,
    isGetSaveHomework: false,
    isGetAfterHomeworkDetail: false,
  },
  homeworkInfo: {},

  reviewMap: {},
  reviewStudentMap: {}, // 批改学生对象
  homeworkDetail: {},
  saveHomeworkInfo: {},
};

const AfterHomeworkStore = assign({}, EventEmitter.prototype, StoreMixins.mixins, {
  getUpdateTarget: function () {
    return store.updateTarget;
  },
  clearUpdateTarget: function (key) {
    store.updateTarget[key] = false;
    return store.updateTarget;
  },
  getHomeworkInfo: function () {
    return store.homeworkInfo;
  },
  getReviewMap: function () {
    return store.reviewMap;
  },
  getHomeworkDetail: function () {
    return store.homeworkDetail;
  },
  getReviewStudent: function (studentId) {
    return store.reviewStudentMap[studentId];
  },
  GET_AFTER_HOMEWORK_LIST_RESPONSE: function (response, status, error) {
    if (!error) {
      store.updateTarget.isGetHomeworkList = true;
      const { data, pageInfo, returnParam } = response;
      let tempList = data.homeworkList || [];
      const { homeworkList } = store.homeworkInfo;
      if (returnParam.currentPage !== 1 && homeworkList.length > 0) {
        tempList = Util.mergePaginateData(homeworkList, tempList, 'id');
      }
      store.homeworkInfo = {
        homeworkList: tempList,
        pageInfo: pageInfo,
        unPublishHomeworkCount: data.unPublishHomeworkCount,
      };
      this.emitChange();
    }
  },
  GET_AFTER_HOMEWORK_REVIEW_LIST_RESPONSE: function (response, status, error) {
    if (!error) {
      store.updateTarget.isGetReviewedList = true;
      const { data } = response;
      const unSubmitList = [];
      const unCorrectList = [];
      const correctList = [];
      const returnList = [];
      const returnUnCorrectList = [];
      const studentMap = {};
      data.length && data.forEach(item => {
        if (item.correctState === 11) {
          unSubmitList.push(item);
        } else if (item.correctState === 1) {
          unCorrectList.push(item);
        } else if (item.correctState === 13) {
          returnList.push(item);
        } else if (item.correctState === 14) {
          returnUnCorrectList.push(item);
        } else {
          correctList.push(item);
        }
        if (item.homeworkStudentId) {
          studentMap[item.homeworkStudentId] = item;
        }
      });
      store.reviewMap.returnUnCorrectList = returnUnCorrectList;
      store.reviewMap.unCorrectList = unCorrectList;
      store.reviewMap.returnList = returnList;
      store.reviewMap.correctList = correctList;
      store.reviewMap.unSubmitList = unSubmitList;
      store.reviewStudentMap = studentMap;
      this.emitChange();
    }
  },
  GET_AFTER_HOMEWORK_BY_ID_RESPONSE: function (response, status, error) {
    if (!error) {
      store.updateTarget.isGetAfterHomeworkDetail = true;
      store.homeworkDetail = response.data || {};
      this.emitChange();
    }
  },
});

AfterHomeworkStore.registerDispatchToken();
export default AfterHomeworkStore;
