export default {
  name: '个人中心',
  path: '/teacher/profile/detail',
  color: 'f0f1f6',
  getComponent (nextState, cb) {
    import('./components/ProfileDetailView').then((view) => {
      cb(null, view.default);
    });
  },
};
