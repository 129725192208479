export default {
  name: '登录',
  path: '/wx/login/:openId',
  trackPath: '/wx/login/',
  getComponent (nextState, cb) {
    import('../Login/components/LoginViewV2').then((view) => {
      cb(null, view.default);
    });
  },
};
