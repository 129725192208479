import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';

import Util from '../lib/Util';
import Movable from '../components/Movable';

import Constants from '../constants/AppConstants';
import TabBar from '../components/TabBar';
import extConfig from '../constants/ext';

import lesson from '../images/footer/lesson@2x.png';
import lessonChecked from '../images/footer/lesson-checked@2x.png';
import homework from '../images/footer/homework@2x.png';
import homeworkChecked from '../images/footer/homeworkChecked@2x.png';
import notice from '../images/footer/notice@2x.png';
import noticeChecked from '../images/footer/noticeChecked@2x.png';
import profile from '../images/footer/profile@2x.png';
import profileChecked from '../images/footer/profile-checked@2x.png';
import rate from '../images/rate@2x.png';

import '../styles/rate.scss';

const defaultTabList = [{
  id: 1,
  text: '首页',
  url: '/',
  iconPath: lesson,
  selectedIconPath: lessonChecked,
  level:[2, 3],
  extraUrl: [
    '/',
    '/home',
  ]
}, {
  id: 1,
  text: '作业',
  url: '/',
  iconPath: homework,
  selectedIconPath: homeworkChecked,
  level:[1],
  extraUrl: [
    '/',
    '/homework/online',
    '/homework/after',
    '/homework/preview',
    // '/analysis/online/class/report/',
    // '/analysis/online/homework/feedback/',
    // '/analysis/online/student/improve/score/',
    // '/analysis/online/comprehensive/learning/',
    // '/analysis/online/improve/score/',
  ]
}, {
  id: 2,
  text: '教学',
  url: '/lesson',
  iconPath: lesson,
  selectedIconPath: lessonChecked,
  level:[1],
},
//   {
//   id: 2,
//   text: '错题本',
//   url: '/library/problem/',
//   iconPath: lesson,
//   selectedIconPath: lessonChecked,
//   level:[2, 3],
// },
{
  id: 3,
  text: '通知',
  url: '/interact/billboard',
  iconPath: notice,
  selectedIconPath: noticeChecked,
  level:[1, 2, 3],
}, {
  id: 4,
  text: '个人中心',
  url: '/profile',
  iconPath: profile,
  selectedIconPath: profileChecked,
  level:[1, 2, 3],
}];

export default class TabBarLayout extends Component {
  static defaultProps = {
    unreadStatus: {},
    local: {},
  };

  static propTypes = {
    local: PropTypes.object,
    unreadStatus: PropTypes.object,
  };

  constructor (props) {
    super(props);
    this.level = Number(Constants.level);
    const tabList = this.handleTabList(props.unreadStatus);
    this.state = {
      tabList,
    };
  }

  componentWillReceiveProps (nextProps) {
    const { unreadStatus } = this.props;
    if (!_.isEqual(unreadStatus, nextProps.unreadStatus)) {
      const tabList = this.handleTabList(nextProps.unreadStatus);
      this.setState({
        tabList,
      });
    }
  }

  handleTabList (unreadStatus) {
    const tabList = defaultTabList.filter((item) => {
      if (item.id === 3 && unreadStatus.advice) {
        item.hasMsgTip = true;
      }
      return item.level.indexOf(this.level) > -1;
    });
    return tabList;
  }

  render () {
    const { local } = this.props;
    const { tabList } = this.state;
    const isShow = tabList.some((item) => item.url === local.pathname || (item.extraUrl && item.extraUrl.indexOf(local.pathname) > -1));
    if (!isShow) {
      return null;
    }
    return (
      <Fragment>
        <TabBar list={tabList} location={local} />
        {extConfig.showRate ? (
          <Movable className='rate-btn'>
            <img className='rate-img' src={rate} alt='评分按钮' onClick={() => Util.pageHashSwitch('/rate/')} />
          </Movable>
        ) : null}
      </Fragment>
    );
  }
}
