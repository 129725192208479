import Util from './Util';
import UserAction from '../actions/UserAction';
import AppStore from '../stores/AppStore';

let trackActionId = 0; // 缓存行为id

class RecordLoginDuration {
  constructor (options) {
    this.options = options;
  }

  init () {
    this.initTime = +new Date();
    Util.addListener(window, 'pagehide', this.handlePagehide);
    this.trackUser(1);
  }

  /**
   * @param actionId 行为id (1是登陆 2是登出)
   * @param sync
   */
  trackUser (actionId = 1, sync) {
    if (trackActionId === actionId) {
      return;
    }
    trackActionId = actionId;
    const userInfo = AppStore.getUserInfo();
    const { userType, userId } = userInfo;
    UserAction.trackUserAction({
      userType,
      userId,
      actionId,
    }, sync);
  };

  handlePagehide = (event) => {
    const endTime = +new Date();
    const costTime = endTime - this.initTime;

    // console.log('page hide cost: %dms', costTime);
    // postMessage
    if (costTime > 6000) {
      this.trackUser(2, true);
    }
  };
}

export default RecordLoginDuration;
