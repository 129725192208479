import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Animate from 'rc-animate';

export default class Spin extends React.Component {
  static propTypes = {
    prefixCls: PropTypes.string,
    className: PropTypes.string,
    spinning: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'default', 'large']),
    wrapperClassName: PropTypes.string,
    suffixCls: PropTypes.string,
    tip: PropTypes.string,
    children: PropTypes.node,
  };

  static defaultProps = {
    prefixCls: 'spinner',
    spinning: true,
    size: 'default',
    wrapperClassName: '',
    className: '',
    suffixCls: 'circle',
    tip: '',
  };

  isNestedPattern () {
    return !!(this.props && this.props.children);
  }

  renderIndicator () {
    const { prefixCls, suffixCls } = this.props;
    const reactClassName = `${prefixCls}-${suffixCls}`;
    if (suffixCls === 'rect') {
      return (
        <div className={classNames(reactClassName, `${prefixCls}-content`)}>
          <i />
          <i />
          <i />
          <i />
          <i />
        </div>
      );
    }
    return (
      <div className={reactClassName}>
        <div className='circle-container container1'>
          <div className='circle1' />
          <div className='circle2' />
          <div className='circle3' />
          <div className='circle4' />
        </div>
        <div className='circle-container container2'>
          <div className='circle1' />
          <div className='circle2' />
          <div className='circle3' />
          <div className='circle4' />
        </div>
        <div className='circle-container container3'>
          <div className='circle1' />
          <div className='circle2' />
          <div className='circle3' />
          <div className='circle4' />
        </div>
      </div>
    );
  }

  render () {
    const { className, size, prefixCls, tip, wrapperClassName, spinning } = this.props;

    const spinClassName = classNames(prefixCls, {
      [`${prefixCls}-sm`]: size === 'small',
      [`${prefixCls}-lg`]: size === 'large',
      [`${prefixCls}-spinning`]: spinning,
      [`${prefixCls}-show-text`]: !!tip,
    }, className);

    const spinElement = (
      <div className={spinClassName}>
        {this.renderIndicator()}
        {tip ? <div className={`${prefixCls}-text`}>{tip}</div> : null}
      </div>
    );
    if (this.isNestedPattern()) {
      let animateClassName = `${prefixCls}-nested-loading`;
      if (wrapperClassName) {
        animateClassName = `${animateClassName} ${wrapperClassName}`;
      }
      const containerClassName = classNames({
        [`${prefixCls}-container`]: true,
        [`${prefixCls}-blur`]: spinning,
      });
      return (
        <Animate
          component='div'
          className={animateClassName}
          style={null}
          transitionName='fade'
        >
          {spinning && <div key='loading'>{spinElement}</div>}
          <div className={containerClassName} key='container'>
            {this.props.children}
          </div>
        </Animate>
      );
    }
    return spinElement;
  }
}
