export default {
  name: '新建通知',
  path: '/billboard/create',
  trackPath: '/billboard/create',
  color: 'f0f1f6',
  getComponent (nextState, cb) {
    import('./components/BillboardCreateView').then((view) => {
      cb(null, view.default);
    });
  },
};
