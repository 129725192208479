import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import { wxConfig } from '../components/Weixin/constants';
import _ from 'underscore';

import Util from '../lib/Util';
import Constants from '../constants/AppConstants';
import AuthLayout from './AuthLayout';

class BasicLayout extends React.Component {
  static propTypes = {
    params: PropTypes.object,
    routes: PropTypes.array,
    children: PropTypes.node,
    location: PropTypes.object,
  };

  constructor (props) {
    super(props);
    this.isDemo = false;
    const { openId, type } = props.params;
    if (type === 'demo') {
      const level = Constants.level;
      this.token = `${level}` === '1' ? Constants.REPORT_TEACHER_USERID : Constants.REPORT_STUDENT_USERID;
      Constants.Token = this.token;
      this.isDemo = true;
    } else {
      this.token = Constants.Token;
    }
    if (this.token && openId) {
      this.token = '';
    }
    this.state = {};
  }

  componentDidMount () {
    wxConfig.appUrl = window.location.href.split('#')[0];
  }

  getPageTitle () {
    const { routes } = this.props;
    // const { pathname } = location;
    if (_.isEmpty(routes)) {
      return;
    }
    let title = '互动课堂';
    let currRouterData = null;
    const len = routes.length;
    // match params path
    // for (let i = 0; i < len; i += 1) {
    //   const routerItem = routes[i];
    //   if (routerItem.path && pathToRegexp(routerItem.path).test(pathname)) {
    //     currRouterData = routerItem;
    //     break;
    //   }
    // }
    if (len > 1) {
      currRouterData = routes[1];
    }
    if (currRouterData) {
      // if (currRouterData.name) {
      //   title = `${currRouterData.name} - 互动课堂`;
      // }
      const trackPath = currRouterData.trackPath || currRouterData.path;
      // baidu统计
      Util.trackPageview(trackPath);
      // 设置body颜色
      Util.setBodyColor(currRouterData.color);
    }
    return title;
  }

  render () {
    const { children, location } = this.props;
    const layout = this.token ? (
      <AuthLayout local={location} isDemo={this.isDemo} children={children} />
    ) : (
      <div className='wrapper'>
        {children}
      </div>
    );
    return (
      <DocumentTitle title={this.getPageTitle()}>
        <Fragment>
          {layout}
        </Fragment>
      </DocumentTitle>
    );
  }
}

export default BasicLayout;
