import assign from 'object-assign';
import request from '../lib/request';
import Prefix from '../constants/Prefix';
import ActionMixins from '../mixins/ActionMixins';

import Constants from '../constants/AppConstants';
import ext from '../constants/ext';
const ActionTypes = Constants.ActionTypes;

const UserAction = assign({}, ActionMixins.mixins, {
  login: function (data, openId) {
    let version = 'v2';
    let suffixUrl = 'user/app/signin';
    data.loginChannel = 'WX';
    let isAes = ext.encryptType === 'AES';
    if (isAes) {
      version = 'v4';
    }
    if (openId) {
      suffixUrl = 'user/wx/signin';
      version = 'v1';
      if (isAes) {
        version = 'v2';
      }
      data.openId = openId;
    }
    const url = `${Prefix('HDKT')}/${suffixUrl}/${version}`;
    const options = {
      method: 'POST',
      disableToken: true,
      body: data,
    };
    return request({
      url,
      options,
    });
  },
  /**
   * 支持验证码校验
   */
  loginV2: function (data) {
    let version = 'v3';
    let isAes = ext.encryptType === 'AES';
    if (isAes) {
      version = 'v4';
    }
    let suffixUrl = 'user/app/signin';
    if (data.openId) {
      suffixUrl = 'user/wx/signin';
      version = 'v3';
      if (isAes) {
        version = 'v2';
      }
    }
    const url = `${Prefix('HDKT')}/${suffixUrl}/${version}`;
    const options = {
      method: 'POST',
      disableToken: true,
      body: data,
    };
    return request({
      url,
      options,
    });
  },
  /**
   * 获取验证码
   */
  getVerifyCode: function () {
    const url = `${Prefix('HDKT')}/user/verificationCode`;
    const options = {
      method: 'GET',
      disableToken: true,
    };
    return request({
      type: ActionTypes.GET_VERIFY_CODE,
      url,
      options,
    });
  },
  // 保存用户行为
  trackUserAction: function (data, sync) {
    const url = `${Prefix('HDKT')}/user/save/user/action/v1`;
    const defaultData = {
      actionType: 6, // 用于web端+微信端使用时长
      actionValue: 2, // 1是WEB端，2是微信端
    };
    const newData = Object.assign({}, defaultData, data);
    if (sync && window.$) {
      window.$.ajax({
        type: 'POST',
        url: url,
        async: false,
        dataType: 'json',
        data: JSON.stringify(newData),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          token: Constants.Token,
        },
      });
      return;
    }
    const options = {
      method: 'POST',
      body: newData,
    };
    return request({
      url,
      options,
    });
  },
});

export default UserAction;
