export default {
  name: '查看解析',
  path: '/homework/online/detail/:homeworkId/:homeworkStudentId/:paperId/:paperState/:isSelfEvaluation',
  trackPath: '/do-homework/online/self-evaluation/',
  color: 'f0f1f6',
  getComponent (nextState, cb) {
    import('./components/SelfEvaluationView').then((view) => {
      cb(null, view.default);
    });
  },
};
