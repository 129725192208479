import fetch from 'isomorphic-fetch';

import Dispatcher from '../dispatcher/AppDispatcher';
import Constants from '../constants/AppConstants';
const ActionTypes = Constants.ActionTypes;

const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  402: '您的账号已在别处登录，请重新登陆',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

const statusHandler = function (type, data) {
  if (typeof data === 'undefined') {
    return;
  }
  const status = Number(data.code) || data.status;
  switch (status) {
    case 0:
    case 200:
    case 101:
      Dispatcher.handleServerAction({
        type: `${type}_RESPONSE`,
        response: data,
        status,
        error: false,
      });
      break;
    case 401:
      Dispatcher.handleServerAction({
        type: ActionTypes.NEED_LOGIN,
        response: data.statusText || 'Something bad happened',
        status,
        error: true,
      });
      break;
    default:
      Dispatcher.handleServerAction({
        type: ActionTypes.EXCEPTION_ACTION,
        response: data.msg || data.statusText || 'Something bad happened',
        status,
        error: true,
      });
      break;
  }
};

const checkStatus = function (response) {
  if (response.ok || response.status === 304) {
    return response.json();
  }
  const error = new Error(response.statusText);
  response.msg = codeMessage[response.status] || response.statusText;
  error.response = response;
  throw error;
};

const request = async function (action) {
  const { type, url, options } = action;
  if (typeof url !== 'string') {
    throw new Error('Specify a string URL.');
  }
  const defaultOptions = {
    mode: 'cors',
  };
  const newOptions = { ...defaultOptions, ...options };
  if (newOptions.method === 'POST' && !newOptions.body) {
    newOptions.body = {};
  }
  if (!(newOptions.body instanceof FormData)) {
    newOptions.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      ...newOptions.headers,
    };
    newOptions.body = JSON.stringify(newOptions.body);
  } else {
    newOptions.headers = {
      Accept: 'application/json',
      ...newOptions.headers,
    };
  }
  if (!options.disableToken && Constants.Token) {
    newOptions.headers.token = Constants.Token;
  }
  newOptions.headers['system-code'] = Constants.SYSTEM_CODE;
  try {
    const response = await fetch(url, newOptions);
    return checkStatus(response).then((data) => {
      const finalAction = Object.assign({}, action, data);
      if (!type) {
        return finalAction;
      }
      return statusHandler(type, finalAction);
    });
  } catch (error) {
    if (!type) {
      return error.response;
    }
    return statusHandler(type, error.response);
  }
};

export default request;
