import Event from 'events';
import assign from 'object-assign';

import StoreMixins from '../../mixins/StoreMixins';
import CartStore from './CartStore';

const EventEmitter = Event.EventEmitter;

const store = {
  updateTarget: {
    isGetPaper: false,
    isGetPaperProblem: false,
  },
  paperInfo: {},
  paperDetails: {},
  problemMap: {},
};

let cartTimer = null;

const PaperStore = assign({}, EventEmitter.prototype, StoreMixins.mixins, {
  getUpdateTarget: function () {
    return store.updateTarget;
  },
  clearUpdateTarget: function (key) {
    store.updateTarget[key] = false;
    return store.updateTarget;
  },
  getPaperInfo: function () {
    return store.paperInfo;
  },
  getPaperDetails: function () {
    return store.paperDetails;
  },
  clearPaperDetails: function () {
    store.paperDetails = {};
    store.problemMap = {};
  },
  hasMoreProblem: function () {
    const { problemList } = store.paperDetails;
    if (!problemList || problemList.length === 0) {
      return false;
    }
    return true;
  },
  changeHasAddProblem: function (problemId) {
    const me = this;
    if (store.problemMap[problemId]) {
      store.problemMap[problemId].hasAdded = false;
      if (cartTimer) {
        clearTimeout(cartTimer);
      }
      cartTimer = setTimeout(() => {
        store.updateTarget.isGetPaperProblem = true;
        me.emitChange();
      }, 100);
    }
  },
  addAllProblemToCart: function () {
    const { problemList } = store.paperDetails;
    if (!problemList || problemList.length === 0) {
      return;
    }
    problemList.forEach((item) => {
      if (!item.hasAdded) {
        item.hasAdded = true;
        const problemType = item.problemType || item.problemTypeId;
        CartStore.addPaperCart(problemType, item);
      }
      return item;
    });
    store.updateTarget.isGetPaperProblem = true;
    this.emitChange();
  },
  GET_PAPER_LIST_RESPONSE: function (response, status, error) {
    if (!error) {
      store.updateTarget.isGetPaper = true;
      const { data, returnParam, pageInfo } = response;
      const { paperList } = store.paperInfo;
      let tempPaperList = data || [];
      if (returnParam.currentPage !== 1) {
        tempPaperList = paperList.concat(tempPaperList);
      }
      store.paperInfo = { paperList: tempPaperList || [], pageInfo };
      this.emitChange();
    }
  },
  GET_PAPER_PROBLEM_LIST_RESPONSE: function (response, status, error) {
    if (!error) {
      store.updateTarget.isGetPaperProblem = true;
      store.paperDetails = response && response.data ? response.data : {};
      const { problemList } = store.paperDetails;
      const cartMap = CartStore.getPaperCart();
      store.problemMap = {};
      if (problemList && problemList.length > 0) {
        problemList.forEach((item) => {
          const problemType = item.problemType || item.problemTypeId;
          const problemId = item.problemId || item.id;
          if (cartMap[problemType] && cartMap[problemType].problemMap[problemId]) {
            item.hasAdded = true;
          } else {
            item.hasAdded = false;
          }
          store.problemMap[problemId] = item;
        });
      }
      this.emitChange();
    }
  },
});

PaperStore.registerDispatchToken();
export default PaperStore;
