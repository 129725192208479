export default {
  name: '课后作业列表',
  path: '/homework/after/detail/:homeworkStudentId/:homeworkState',
  getComponent (nextState, cb) {
    import('./components/index').then((view) => {
      cb(null, view.default);
    });
  },
};

