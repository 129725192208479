export default {
  name: '点播课',
  path: '/excellent/course/:type',
  getComponent (nextState, cb) {
    import('./components/ExcellentCourseView').then((view) => {
      cb(null, view.default);
    });
  },
};

