import assign from 'object-assign';
import Constants from '../constants/AppConstants';
import request from '../lib/request';
import Prefix from '../constants/Prefix';
import ActionMixins from '../mixins/ActionMixins';

const ActionTypes = Constants.ActionTypes;

const AppAction = assign({}, ActionMixins.mixins, {
  getUserInfo: function (isDemo) {
    let urlKey = 'HDKT';
    if (isDemo) {
      urlKey = 'HDKT_DEMO';
    }
    const url = `${Prefix(urlKey)}/user/getUserInfo/v1`;
    const options = {
      method: 'GET',
    };
    return request({
      type: ActionTypes.GET_USER_INFO,
      url,
      options,
    });
  },
  getUserSubjectInfo: function (data) {
    const url = `${Prefix('ANALYSIS')}/class/get/subject/info/v1`;
    const options = {
      method: 'POST',
      body: data,
    };
    return request({
      type: ActionTypes.GET_SUBJECT_INFO,
      url,
      options,
    });
  },
  /**
   * 获取默认班级
   */
  getDefaultClass: function () {
    const url = `${Prefix('HDKT')}/class/sync/subject/list/v1`;
    const options = {
      method: 'GET',
    };
    return request({
      type: ActionTypes.GET_DEFAULT_CLASS_LIST,
      url,
      options,
    });
  },
  /**
   * 获取未读消息
   */
  getUnreadMessageStatus: function () {
    const url = `${Prefix('HDKT')}/homework/student/getHomeworkAndBillboardStatus/v1`;
    const options = {
      method: 'GET',
    };
    return request({
      type: ActionTypes.GET_UNREAD_MESSAGE_STATUS,
      url,
      options,
    });
  },
});

export default AppAction;
