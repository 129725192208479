export default {
  name: '课程详情',
  path: '/micro/course/:courseId',
  trackPath: '/micro/course/details/',
  getComponent (nextState, cb) {
    import('./components/MicroCourseDetailView').then((view) => {
      cb(null, view.default);
    });
  },
};
