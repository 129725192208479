import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

export const DataEmpty = ({ content, height, icon }) => (
  <div
    className={`Empty_wrap ${height === 'auto' ? 'full' : 'auto'}`}
    style={{ height }}
  >
    <div className='Empty_tip'>
      <i className={`icon-empty-${icon} icon`} />
      <h4 className='text' dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  </div>
);

DataEmpty.propTypes = {
  content: PropTypes.any,
  height: PropTypes.any,
  icon: PropTypes.string,
};

DataEmpty.defaultProps = {
  content: '暂无内容',
  height: 'auto',
  icon: 'default'
};

export default DataEmpty;
