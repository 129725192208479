import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';

import history from '../../../../history';
import Affix from '../../../../components/Affix';
import AppStore from '../../../../stores/AppStore';
import ClassStore from '../../../../stores/teacher/ClassStore';
import Util from '../../../../lib/Util';
import ClassSelect from './ClassSelect';

const navList = [{
  id: 3,
  name: '预习任务',
  link: '/homework/preview',
}, {
  id: 2,
  name: '课后作业',
  link: '/homework/after',
}, {
  id: 1,
  name: '在线做题',
  link: '/homework/online',
}];

export class ClassHeader extends React.Component {
  static defaultProps = {
    hideMenu: false,
    channel: 'web',
    homeworkType: 0,
    createHomework: null,
    onSwitchPage: () => null,
  };
  static propTypes = {
    hideMenu: PropTypes.bool,
    homeworkType: PropTypes.number,
    createHomework: PropTypes.func,
    onSwitchPage: PropTypes.func,
  };

  constructor (props) {
    super(props);
    let currentClass = ClassStore.getCurrentClass();
    const classList = AppStore.getUserClassList();
    if (_.isEmpty(currentClass)) {
      currentClass = classList[0] || {};
    }
    this.state = {
      currentClass,
      classList,
    };
  }

  componentDidMount () {
    const { currentClass } = this.state;
    setTimeout(() => {
      ClassStore.updateCurrentClass(currentClass);
    }, 0);
  }

  handleClass = (item) => {
    const { currentClass, currentSubjectId } = this.state;
    if (currentClass === item.classId && currentSubjectId === item.subjectId) {
      return;
    }
    this.setState({
      currentClass: item,
    }, () => {
      ClassStore.updateCurrentClass(item);
    });
  };

  handleSwitchPage = (item) => {
    const { onSwitchPage, homeworkType } = this.props;
    if (homeworkType === item.id) {
      return;
    }
    onSwitchPage();
    history.replace(item.link);
  };

  openClassSelect = () => {
    this.triggerClassSelect.handleModal(true);
  };

  render () {
    const { homeworkType, createHomework, hideMenu } = this.props;
    const { currentClass, classList } = this.state;
    return (
      <Fragment>
        <div className='classroom-detail-wrap'>
          <div className='classroom-detail-info'>
            <div className='info-l' onClick={() => Util.pageHashSwitch(`/class/manage/${currentClass.classId}/${currentClass.subjectId}`, '/teacher/admin/classroom/')}>
              <p className='classroom-stu-count'>
                <span className='class-header'>{currentClass.className}</span>
                <span className='stu-len'>
                  <i className='icon-stu' />
                  {`学生：${currentClass.studentSum || 0}`}
                </span>
              </p>
              <p className='ellipsis'>
                {`授课学科：${currentClass.subjectName}`}
              </p>
            </div>
            <div className='info-r' onClick={this.openClassSelect}>
              <span className='iconfont'>切换班级 &#xe686;</span>
            </div>
          </div>
        </div>
        <Affix offsetTop={0}>
          {hideMenu ? null : (
            <section className='maskBar-wrap'>
              <nav className='maskBar-nav'>
                <ul className='maskBar-navList clearfix'>
                  {navList.map((item) => {
                    return (
                      <li
                        key={`nav_${item.id}`}
                        onClick={() => this.handleSwitchPage(item)}
                        className={item.id === homeworkType ? 'nav-item active' : 'nav-item'}
                      >
                        <span>{item.name}</span>
                      </li>
                    );
                  })}
                </ul>
              </nav>
            </section>
          )}
          {createHomework ? (
            <div className='homework-arrange-operate'>
              <div
                onClick={() => createHomework('', 'create')}
                className='btn-arrange'>
                <i className='iconfont'>&#xe636;</i>
                {homeworkType === 3 ? '发布预习任务' : '布置作业'}
              </div>
            </div>
          ) : null}
        </Affix>
        <ClassSelect
          ref={(e) => { this.triggerClassSelect = e; }}
          currentClass={currentClass}
          classList={classList}
          onChange={this.handleClass}
        />
      </Fragment>
    );
  }
}

export default ClassHeader;
