import React from 'react';
import PropTypes from 'prop-types';

import history from '../../history';

import './index.scss';

class TabBar extends React.Component {
  static defaultProps = {
    list: [],
    isHide: false,
    onClick: () => null
  };

  static propTypes = {
    list: PropTypes.array,
    isHide: PropTypes.bool,
    onClick: PropTypes.func,
    location: PropTypes.object,
  };

  handleClick = (url) => {
    const { onClick } = this.props;
    history.replace(url);
    onClick();
  };

  /**
   * get SubMenu or Item
   */
  getSubMenuOrItem = (item, isActive) => {
    const cls = isActive ? 'TabBar__item active' : 'TabBar__item';
    return (
      <span
        key={item.url}
        className={cls}
        onClick={() => this.handleClick(item.url)}
      >
        <img src={isActive ? item.selectedIconPath : item.iconPath} alt='icon' className='TabBar__icon' />
        <p className='TabBar__title'>{item.text}</p>
        {item.hasMsgTip ? (
          <i className='msg-tip' />
        ) : null}
      </span>
    );
  };

  render () {
    const { list, isHide, location: { pathname } } = this.props;
    if (isHide) {
      return null;
    }
    return (
      <footer className='TabBar__root'>
        <div className='TabBar__footer'>
          {list.map((item) => {
            let isActive = false;
            if (item.extraUrl && item.extraUrl.indexOf(pathname) > -1) {
              isActive = true;
            } else {
              isActive = item.url === pathname;
            }
            return this.getSubMenuOrItem(item, isActive);
          })}
        </div>
      </footer>
    );
  }
}

export default TabBar;
