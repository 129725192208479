/* eslint-disable */
export const USER_AGENT = window.navigator && window.navigator.userAgent || '';
export const LANGUAGE  = window.navigator && window.navigator.language || '';
export const COLOR_DEPTH = window.screen && window.screen.colorDepth || '';
export const CPU_CLASS = window.navigator && window.navigator.cpuClass || '';
export const PLATFORM = window.navigator && window.navigator.platform || '';
export const DO_NOT_TRACK = window.navigator && window.navigator.doNotTrack || '';
export const APP_NAME = window.navigator && window.navigator.appName || '';

const nativeForEach = Array.prototype.forEach;
const nativeMap = Array.prototype.map;

export function each(obj, iterator, context) {
  if (obj === null) {
    return;
  }
  if (nativeForEach && obj.forEach === nativeForEach) {
    obj.forEach(iterator, context);
  } else if (obj.length === +obj.length) {
    for (let i = 0, l = obj.length; i < l; i++) {
      if (iterator.call(context, obj[i], i, obj) === {}) return;
    }
  } else {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (iterator.call(context, obj[key], key, obj) === {}) return;
      }
    }
  }
}

export function map(obj, iterator, context) {
  const results = [];
  // Not using strict equality so that this acts as a
  // shortcut to checking for `null` and `undefined`.
  if (obj == null) return results;
  if (nativeMap && obj.map === nativeMap) return obj.map(iterator, context);
  each(obj, function(value, index, list) {
    results[results.length] = iterator.call(context, value, index, list);
  });
  return results;
}

/**
 * Check has LocalStorage.
 * @returns {boolean}
 */
export function hasLocalStorage () {
  try{
    return !!window.localStorage;
  } catch(e) {
    return true;
  }
}

/**
 * Check has SessionStorage.
 * @returns {boolean}
 */
export function hasSessionStorage () {
  try{
    return !!window.sessionStorage;
  } catch(e) {
    return true;
  }
}

/**
 * Check has indexedDB.
 * @returns {boolean}
 */
export function hasIndexDb() {
  try{
    return !!window.indexedDB;
  } catch(e) {
    return true;
  }
}

/**
 * Check is ie
 * @returns {boolean}
 */
export function isIE() {
  if(APP_NAME === 'Microsoft Internet Explorer') {
    return true;
  } else if(APP_NAME === 'Netscape' && /Trident/.test(navigator.userAgent)){
    return true;
  }
  return false;
}

export function getRegularPluginsString() {
  return map(navigator.plugins, function (p) {
    let mimeTypes = map(p, function(mt){
      return [mt.type, mt.suffixes].join('~');
    }).join(',');
    return [p.name, p.description, mimeTypes].join('::');
  }, this).join(';');
}

export function getIEPluginsString() {
  if(window.ActiveXObject){
    const names = ['ShockwaveFlash.ShockwaveFlash',
      'AcroPDF.PDF', // Adobe PDF reader 7+
      'PDF.PdfCtrl', // Adobe PDF reader 6 and earlier, brrr
      'QuickTime.QuickTime', // QuickTime
      // 5 versions of real players
      'rmocx.RealPlayer G2 Control',
      'rmocx.RealPlayer G2 Control.1',
      'RealPlayer.RealPlayer(tm) ActiveX Control (32-bit)',
      'RealVideo.RealVideo(tm) ActiveX Control (32-bit)',
      'RealPlayer',
      'SWCtl.SWCtl', // ShockWave player
      'WMPlayer.OCX', // Windows media player
      'AgControl.AgControl', // Silverlight
      'Skype.Detection'];

    return map(names, function(name){
      try{
        new ActiveXObject(name);
        return name;
      } catch(e){
        return null;
      }
    }).join(';');
  } else {
    return ""; // behavior prior version 0.5.0, not breaking backwards compat.
  }
}

export function getPluginsString() {
  if (isIE()){
    return getIEPluginsString();
  } else {
    return getRegularPluginsString();
  }
}

/**
 * JS Implementation of MurmurHash3 (r136) (as of May 20, 2011)
 *
 * @author <a href="mailto:gary.court@gmail.com">Gary Court</a>
 * @see http://github.com/garycourt/murmurhash-js
 * @author <a href="mailto:aappleby@gmail.com">Austin Appleby</a>
 * @see http://sites.google.com/site/murmurhash/
 *
 * @param {string} key ASCII only
 * @param {number} seed Positive integer only
 * @return {number} 32-bit positive integer hash
 */

export function murmurhash3_32_gc(key, seed) {
  let remainder, bytes, h1, h1b, c1, c2, k1, i;

  remainder = key.length & 3; // key.length % 4
  bytes = key.length - remainder;
  h1 = seed;
  c1 = 0xcc9e2d51;
  c2 = 0x1b873593;
  i = 0;

  while (i < bytes) {
    k1 =
      ((key.charCodeAt(i) & 0xff)) |
      ((key.charCodeAt(++i) & 0xff) << 8) |
      ((key.charCodeAt(++i) & 0xff) << 16) |
      ((key.charCodeAt(++i) & 0xff) << 24);
    ++i;

    k1 = ((((k1 & 0xffff) * c1) + ((((k1 >>> 16) * c1) & 0xffff) << 16))) & 0xffffffff;
    k1 = (k1 << 15) | (k1 >>> 17);
    k1 = ((((k1 & 0xffff) * c2) + ((((k1 >>> 16) * c2) & 0xffff) << 16))) & 0xffffffff;

    h1 ^= k1;
    h1 = (h1 << 13) | (h1 >>> 19);
    h1b = ((((h1 & 0xffff) * 5) + ((((h1 >>> 16) * 5) & 0xffff) << 16))) & 0xffffffff;
    h1 = (((h1b & 0xffff) + 0x6b64) + ((((h1b >>> 16) + 0xe654) & 0xffff) << 16));
  }

  k1 = 0;

  switch (remainder) {
    case 3: k1 ^= (key.charCodeAt(i + 2) & 0xff) << 16;
    case 2: k1 ^= (key.charCodeAt(i + 1) & 0xff) << 8;
    case 1: k1 ^= (key.charCodeAt(i) & 0xff);

      k1 = (((k1 & 0xffff) * c1) + ((((k1 >>> 16) * c1) & 0xffff) << 16)) & 0xffffffff;
      k1 = (k1 << 15) | (k1 >>> 17);
      k1 = (((k1 & 0xffff) * c2) + ((((k1 >>> 16) * c2) & 0xffff) << 16)) & 0xffffffff;
      h1 ^= k1;
  }

  h1 ^= key.length;

  h1 ^= h1 >>> 16;
  h1 = (((h1 & 0xffff) * 0x85ebca6b) + ((((h1 >>> 16) * 0x85ebca6b) & 0xffff) << 16)) & 0xffffffff;
  h1 ^= h1 >>> 13;
  h1 = ((((h1 & 0xffff) * 0xc2b2ae35) + ((((h1 >>> 16) * 0xc2b2ae35) & 0xffff) << 16))) & 0xffffffff;
  h1 ^= h1 >>> 16;

  return h1 >>> 0;
}

/**
 * Generating browser fingerprints
 * @returns {number}
 */
export function getFingerprint() {
  const keys = [];
  keys.push(USER_AGENT);
  keys.push(LANGUAGE);
  keys.push(COLOR_DEPTH);
  keys.push(new Date().getTimezoneOffset());
  keys.push(hasSessionStorage());
  keys.push(hasLocalStorage());
  keys.push(hasIndexDb());
  //body might not be defined at this point or removed programmatically
  const doc = window.document;
  if (doc.body){
    keys.push(typeof(doc.body.addBehavior));
  } else {
    keys.push(typeof undefined);
  }
  keys.push(typeof(window.openDatabase));
  keys.push(CPU_CLASS);
  keys.push(PLATFORM);
  keys.push(DO_NOT_TRACK);
  keys.push(getPluginsString());

  return murmurhash3_32_gc(keys.join('###'), 31);
}
