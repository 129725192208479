
const ProblemWeightList = [{
  id: 1,
  name: '单选题',
  weight: 1,
}, {
  id: 2,
  name: '多选题',
  weight: 2,
}, {
  id: 3,
  name: '判断题',
  weight: 1,
}, {
  id: 4,
  name: '填空题',
  weight: 1,
}, {
  id: 5,
  name: '计算题',
  weight: 3,
}, {
  id: 6,
  name: '解答题',
  weight: 4,
}, {
  id: 7,
  name: '完形填空',
  weight: 6,
}, {
  id: 8,
  name: '阅读理解',
  weight: 4,
}, {
  id: 9,
  name: '问答题',
  weight: 2,
}, {
  id: 10,
  name: '语言表达',
  weight: 2,
}, {
  id: 11,
  name: '名著导读',
  weight: 3,
}, {
  id: 12,
  name: '句型转换',
  weight: 1,
}, {
  id: 13,
  name: '翻译',
  weight: 2,
}, {
  id: 14,
  name: '改错题',
  weight: 4,
}, {
  id: 15,
  name: '选词填空（词汇运用）',
  weight: 2,
}, {
  id: 16,
  name: '文言文阅读',
  weight: 4,
}, {
  id: 17,
  name: '现代文阅读',
  weight: 4,
}, {
  id: 18,
  name: '连词成句',
  weight: 2,
}, {
  id: 19,
  name: '默写',
  weight: 2,
}, {
  id: 20,
  name: '诗歌鉴赏',
  weight: 3,
}, {
  id: 21,
  name: '辨析题',
  weight: 4,
}, {
  id: 22,
  name: '补全对话',
  weight: 2,
}, {
  id: 23,
  name: '单选题',
  weight: 1,
}, {
  id: 24,
  name: '单词拼写（词汇运用）',
  weight: 1,
}, {
  id: 25,
  name: '作图题',
  weight: 2,
}, {
  id: 26,
  name: '探究题',
  weight: 4,
}, {
  id: 27,
  name: '材料分析题',
  weight: 4,
}, {
  id: 28,
  name: '综合题',
  weight: 4,
}, {
  id: 29,
  name: '实验题',
  weight: 4,
}, {
  id: 30,
  name: '连线题',
  weight: 2,
}, {
  id: 31,
  name: '列举题',
  weight: 2,
}, {
  id: 32,
  name: '写作题',
  weight: 8,
}, {
  id: 33,
  name: '书面表达',
  weight: 2,
}, {
  id: 34,
  name: '书写',
  weight: 1,
}, {
  id: 36,
  name: '应用题',
  weight: 3,
}, {
  id: 37,
  name: '选择题',
  weight: 1,
}, {
  id: 38,
  name: '双选题',
  weight: 2,
}, {
  id: 39,
  name: '听力题',
  weight: 1,
}, {
  id: 41,
  name: '推断题',
  weight: 4,
}];

export default ProblemWeightList;
