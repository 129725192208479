import assign from 'object-assign';
import extConfig from './ext';

const env = process.env.API_ENV || 'development';

const commonMap = {
  HDKT_DEMO: 'http://47.95.9.1:8080/demo/api',
  ANALYSIS_DEMO: 'http://47.95.9.1:8080/demo/api',
  ANALYSIS_DEMO_FILE: 'http://47.94.156.245:8080/img',
};

const deployMap = assign({}, commonMap, {
  HDKT: '//api.hdkt100.com/hd/api',
  UPLOAD: '/file/api',
  FILE: '//file.hdkt100.com/img',
  ANALYSIS: '//api.hdkt100.com/zb/api',
  QUESTION: '//api.hdkt100.com/problem2/api',
  USER: extConfig.API_PREFIX_USER || '//ucapi.xuechengcloud.com/api',
  VIDEOCALL: 'https://videocall.hdkt100.com',
  LOGIN: '/login',
});

const testMap = assign({}, commonMap, {
  HDKT: 'http://api.hdkt100.net:8088/hd/api',
  UPLOAD: 'http://api.hdkt100.net:8088/file/api',
  FILE: 'http://api.hdkt100.net:8088/file/img',
  ANALYSIS: 'http://api.hdkt100.net:8088/zb/api',
  QUESTION: 'http://api.hdkt100.net:8088/public_problem/api', // 公有题库
  USER: 'http://api.hdkt100.net:8088/uc/api',
  VIDEOCALL: 'https://videocall.hdkt100.net',
  LOGIN: '/login',
});

export default function prefix (key) {
  return env === 'development' ? testMap[key] : deployMap[key] || '';
}
