export default {
  name: '个人中心',
  path: '/profile',
  trackPath: '/teacher/profile/',
  color: 'f0f1f6',
  getComponent (nextState, cb) {
    import('./components/ProfileView').then((view) => {
      cb(null, view.default);
    });
  },
};
