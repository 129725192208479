export default {
  name: '评分',
  path: '/profile/task/score',
  color: 'f0f1f6',
  getComponent (nextState, cb) {
    import('./components/TaskScoreView').then((view) => {
      cb(null, view.default);
    });
  },
};
