export default {
  name: '在线做题',
  path: '/zxzy',
  color: 'f0f1f6',
  hideInMenu: true,
  getComponent (nextState, cb) {
    import('../../Homework/components/HomeworkView').then((view) => {
      cb(null, view.default);
    });
  },
};
