export default {
  name: '预习任务点评详情',
  path: '/homework/preview/review/detail/:homeworkId/:classId/:taskId',
  color: 'f0f1f6',
  getComponent (nextState, cb) {
    import('./components/index').then((view) => {
      cb(null, view.default);
    });
  },
};
