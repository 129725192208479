export default {
  name: '',
  path: '/analysis/student/recommend/problem',
  color: 'f0f1f6',
  getComponent (nextState, cb) {
    import('./components/ImproveRecommendProblemView').then((view) => {
      cb(null, view.default);
    });
  },
};
