export default {
  name: '作业',
  path: '/homework/online',
  trackPath: '/homework/online',
  color: 'f0f1f6',
  getComponent (nextState, cb) {
    import('./components/HomeworkView').then((view) => {
      cb(null, view.default);
    });
  },
};


