/* global wx HDKT_APP_JSSDK */
import _ from 'underscore';
import { Toast } from 'antd-mobile';
import history, { goBack } from '../history';
import ProblemStore from '../stores/teacher/ProblemStore';

const env = process.env.NODE_ENV || 'development';
let countdownTimer = null;

const Util = {
  getLocationQuery: function (key) {
    let list = location.search.substr(1).split('&');
    let result = {};
    for (let i = 0, l = list.length; i < l; ++i) {
      let pair = list[i].split('=');
      result[pair[0]] = pair[1];
    }
    if (key) {
      return result[key] || '';
    }
    return result;
  },
  /**
   * 校验手机号
   * @param source
   * @returns {boolean}
   */
  isMobile: function (source) {
    return /^((\(\d{2,3}\))|(\d{3}\-))?(1[3456789]\d{9})$/.test(source);
  },
  /**
   * 校验email
   * @param source
   * @returns {boolean}
   */
  isEmail: function (source) {
    return /^[A-Z_a-z0-9-\.]+@([A-Z_a-z0-9-]+\.)+[a-z0-9A-Z]{2,4}$/.test(source);
  },
  /**
   * 校验文件类型
   * @param format
   * @param str
   * @returns {boolean}
   */
  checkFileFormat: function (format, str) {
    let formatStr = str;
    if (!format || !formatStr) {
      return false;
    }
    const patt1 = new RegExp(`(\.|\/)(${format})$`, 'i');
    formatStr = formatStr.toLowerCase().split('?expires');;
    return patt1.test(formatStr[0]);
  },
  isImage: function (str) {
    return this.checkFileFormat('gif|bmp|png|jpeg|jpg', str);
  },
  storage: {
    set: function (name, item) {
      localStorage.setItem(name, JSON.stringify(item));
    },
    get: function (name) {
      return JSON.parse(
        localStorage.getItem(name)
      );
    },
    remove: function (name) {
      localStorage.removeItem(name);
    },
    clear: function () {
      localStorage.clear();
    },
    session: {
      set: function (name, item) {
        sessionStorage.setItem(name, JSON.stringify(item));
      },
      get: function (name) {
        return JSON.parse(
          sessionStorage.getItem(name)
        );
      },
      remove: function (name) {
        sessionStorage.removeItem(name);
      },
      clear: function () {
        sessionStorage.clear();
      },
    }
  },
  /**
   * 将一串dataURL字符串转变为Image类型文件
   * dataurl参数传入一个dataURL字符串
   * fn为回调方法，包含一个Image类型文件的参数
   * @param dataurl
   * @returns {*}
   */
  dataURLtoBlob: function (dataurl) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  },
  /**
   * 通过一个图片的url加载所需要的图片对象
   * url参数传入图片的url
   * fn为回调方法包含，一个Image对象的参数
   * @param url
   * @param fn
   */
  urltoImage: function (url, fn) {
    var img = new Image();
    img.src = url;
    img.onload = function () {
      fn(img);
    };
  },
  /**
   * 将一个Image对象转变为一个Canvas类型对象
   * image参数传入一个Image对象
   * @param image
   * @returns {Element}
   */
  imagetoCanvas: function (image) {
    var cvs = document.createElement('canvas');
    var ctx = cvs.getContext('2d');
    cvs.width = image.width;
    cvs.height = image.height;
    ctx.drawImage(image, 0, 0, cvs.width, cvs.height);
    return cvs;
  },
  /**
   * 将一个Canvas对象压缩转变为一个Blob类型对象
   * canvas参数传入一个Canvas对象
   * quality参数传入一个0-1的number类型，表示图片压缩质量
   * fn为回调方法，包含一个Blob对象的参数
   * @param canvas
   * @param quality
   * @param fn
   */
  canvasResizetoFile: function (canvas, quality, fn) {
    canvas.toBlob(function (blob) {
      fn(blob);
    }, 'image/jpeg', quality);
  },
  /**
   * 将一个Canvas对象压缩转变为一个dataURL字符串
   * canvas参数传入一个Canvas对象
   * quality参数传入一个0-1的number类型，表示图片压缩质量
   * @param canvas
   * @param quality
   * @returns {string}
   */
  canvasResizetoDataURL: function (canvas, quality) {
    return canvas.toDataURL('image/jpeg', quality);
  },
  /**
   * 将File（Blob）类型文件转变为dataURL字符串
   * file参数传入一个File（Blob）类型文件
   * fn为回调方法，包含一个dataURL字符串的参数
   * @param file
   * @param fn
   */
  filetoDataURL: function (file, fn) {
    let reader = new FileReader();
    reader.onload = function (e) {
      fn(e.target.result);
    };
    reader.onerror = function (e) {
      Toast.info('error')
    };
    reader.progress = function (percent) {
      Toast.info(percent);
    };
    // Toast.info(file.webkitSlice);
    // Toast.info(file.mozSlice);
    reader.readAsDataURL(file);
  },
  /**
   * 将一串dataURL字符串转变为Image类型文件
   * dataurl参数传入一个dataURL字符串
   * fn为回调方法，包含一个Image类型文件的参数
   * @param dataurl
   * @param fn
   */
  dataURLtoImage: function (dataurl, fn) {
    let img = new Image();
    // if (img.complete) {
    //   fn(img);
    // } else {
    //   img.onload = function () {
    //     fn(img);
    //   };
    // }
    img.onload = function () {
      fn(img);
    };
    img.onerror = function () {

    };
    img.src = dataurl;
  },
  /**
   * 将File（Blob）类型文件压缩后再返回Blob类型对象
   * file参数传入一个File（Blob）类型文件
   * quality参数传入一个0-1的number类型，表示图片压缩质量
   * fn为回调方法，包含一个Blob类型文件的参数
   * @param file
   * @param quality
   * @param fn
   */
  fileResizetoFile: function (file, quality, fn) {
    const me = this;
    me.filetoDataURL(file, function (dataurl) {
      me.dataURLtoImage(dataurl, function (image) {
        me.canvasResizetoFile(me.imagetoCanvas(image), quality, fn);
      });
    });
  },
  /**
   * 隐藏滚动条
   * @param overflow
   */
  setFixedModal: function (overflow) {
    const doc = window.document;
    if (doc.body.style.overflow === overflow) {
      return;
    }
    doc.body.style.overflow = overflow;
  },
  /**
   * 获取class
   * @param obj
   * @param sClass
   * @returns {Array}
   */
  getClassName: function (obj, sClass) {
    let aResult = [];
    if ('getElementsByClassName' in document) {
      aResult = obj.getElementsByClassName(sClass);
    } else {
      var aEle = obj.getElementsByTagName('*');
      for (let i = 0; i < aEle.length; i += 1) {
        const str = aEle[i].className;
        if (str === sClass) {
          aResult.push(aEle[i]);
        }
      }
    }
    return aResult;
  },
  hasClass: function (obj, cls) {
    const obj_class = obj.className;
    const obj_class_list = obj_class.split(/\s+/);
    for (let i = 0; i < obj_class_list.length; i += 1) {
      if (obj_class_list[i] === cls) {
        return true;
      }
    }
    return false;
  },
  addClass: function (ele, cls) {
    if (this.hasClass(ele, cls)) {
      return;
    }
    const obj_class = ele.className;
    if (obj_class) {
      ele.className = `${obj_class} ${cls}`;
    } else {
      ele.className = cls;
    }
  },
  removeClass: function (ele, cls) {
    if (this.hasClass(ele, cls)) {
      const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
      ele.className = ele.className.replace(reg, '');
    }
  },
  setBodyColor: function (color) {
    const doc = window.document;
    if (color) {
      doc.body.style.background = `#${color}`;
    } else {
      doc.body.style.background = '';
    }
  },
  fileNameSubstringSuffix: function (fileName) {
    if (!fileName || fileName.indexOf('.') === -1) {
      return '';
    }
    var index1 = fileName.lastIndexOf('.') + 1;
    var index2 = fileName.length;
    return fileName.substring(index1, index2);
  },
  /**
   * 对象深拷贝
   * @param source
   * @returns {*}
   */
  deepCopy: function (source) {
    const sourceCopy = source instanceof Array ? [] : {};
    Object.keys(source).forEach((key) => {
      sourceCopy[key] = source[key] && typeof source[key] === 'object' ? this.deepCopy(source[key]) : source[key];
      return false;
    });
    return sourceCopy;
  },
  linkRegExpCheck: function (url) {
    if (!url) {
      return false;
    }
    var reg = /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/;
    return reg.test(url);
  },
  /**
   * 页面hash跳转
   */
  pageHashSwitch: function (link, trackLink, disChannel) {
    if (disChannel) {
      return history.push(link);
    }
    const search = this.getLocationQuery();
    let channel = 'm_web';
    if (search.channel) {
      channel = search.channel;
    }
    let splitUrl = `&channel=${channel}`;
    if (link.indexOf('?') < 0) {
      splitUrl = `?channel=${channel}`;
    }
    return history.push(`${link}${splitUrl}`);
  },
  getBrowseChannel: function () {
    const search = this.getLocationQuery();
    if (!search.channel) {
      return 'web';
    }
    return search.channel;
  },
  /**
   * [checkPhoneType 检查手机类型]
   * @param {void} 无
   * @return{void}  无
   */
  checkPhoneType: function () {
    const u = navigator.userAgent;
    const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
    const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    return {
      isAndroid: isAndroid,
      isIOS: isIOS,
    };
  },
  /**
   * [pageSwitchMethod 页面切换]
   * @param {void} data 无
   * @return{void}  无
   */
  pageSwitchMethod: function (link) {
    const search = this.getLocationQuery();
    if (!search.channel || search.channel !== 'HDKT_APP') {
      if (link) {
        this.pageHashSwitch(link);
      } else {
        this.backPrevPage();
      }
      return;
    }
    const phoneType = this.checkPhoneType();
    if (phoneType.isIOS) {
      window.location.href = 'hdkt://back';
    } else if (phoneType.isAndroid) {
      try {
        HDKT_APP_JSSDK.pageSwitch();
      } catch (e) {
        alert(e);
      }
    }
  },
  /**
   * app 预览文件
   * @param openUrl
   * @returns {boolean}
   */
  appPreviewFile: function (openUrl) {
    const search = this.getLocationQuery();
    if (search.channel === 'HDKT_APP') {
      const phoneType = this.checkPhoneType();
      if (phoneType.isIOS) {
        window.location.href = `hdkt://toexternallink?fileLink=${openUrl}`;
        return true;
      }
    }
    return false;
  },
  openAppFileLink: function (openUrl) {
    const hasPreview = Util.appPreviewFile(openUrl);
    if (hasPreview) {
      return false;
    }
    return window.open(openUrl);
  },
  /**
   * 返回上一页
   */
  backPrevPage: function () {
    goBack();
  },
  iScrollClick: function () {
    if (/iPhone|iPad|iPod|Macintosh/i.test(navigator.userAgent)) {
      return false;
    }
    if (/Chrome/i.test(navigator.userAgent)) {
      return (/Android/i.test(navigator.userAgent));
    }
    if (/Silk/i.test(navigator.userAgent)) {
      return false;
    }
    if (/Android/i.test(navigator.userAgent)) {
      var s = navigator.userAgent.substr(navigator.userAgent.indexOf('Android') + 8, 3);
      return (parseFloat(s[0] + s[3]) >= 44);
    }
  },
  /**
   * 异步加载图片
   * @param url
   * @returns {Promise}
   */
  loadImageAsync: function (url) {
    return new Promise(function (resolve, reject) {
      const image = new Image();
      image.onload = function () {
        resolve(image);
      };
      image.onerror = function () {
        reject(new Error('Could not load image at ' + url));
      };
      image.src = url;
    });
  },
  getScrollOptions: function () {
    return {
      mouseWheel: true,
      disablePointer: true,
      disableTouch: false,
      disableMouse: false,
      click: true,
    };
  },
  /**
   * 验证码倒计时
   * @param wait
   * @param e
   */
  countdown: function (wait, e) {
    const me = this;
    if (wait === 0) {
      if (countdownTimer) {
        clearTimeout(countdownTimer);
      }
      return e.innerText = '发送验证码', wait = 60, e.disabled = !1;
    } else {
      return e.disabled = !0, e.innerText = '验证码已发送(' + wait + 's)', wait--, countdownTimer = setTimeout(function () {
        me.countdown(wait, e);
      }, 1000);
    }
  },
  substringFileType: function (str) {
    let tempStr = str;
    if (!tempStr) {
      return '';
    }
    const index = tempStr.lastIndexOf('\.');
    return tempStr.substring(index + 1, tempStr.length);
  },
  getWinWidth: function () {
    let winWidth = 375;
    if (window.innerWidth) {
      winWidth = window.innerWidth;
    } else if ((document.body) && (document.body.clientWidth)) {
      winWidth = document.body.clientWidth;
    }
    return winWidth;
  },
  setRootDisplay: function (display) {
    if (!window.rootEle) {
      window.rootEle = document.getElementById('root');
    }
    try {
      window.rootEle.style.display = display || '';
    } catch (e) {
      console.log(e);
    }
  },
  /**
   * 率
   * @param m
   * @param d
   * @returns {number}
   */
  toProportion: function (m, d) {
    const r1 = Number(m);
    const r2 = Number(d);
    return Math.round(r1 / r2 * 10000) / 100.00;
  },
  /**
   * 加
   * @param num1
   * @param num2
   * @returns {number}
   */
  accAdd: function (num1, num2) {
    var r1, r2, m;
    try {
      r1 = num1.toString().split('.')[1].length;
    } catch (e) {
      r1 = 0;
    }
    try {
      r2 = num2.toString().split('.')[1].length;
    } catch (e) {
      r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    return Math.round(num1 * m + num2 * m) / m;
  },
  /**
   * 减
   * @param num1
   * @param num2
   * @returns {string}
   */
  accSub: function (num1, num2) {
    var r1, r2, m, n;
    try {
      r1 = num1.toString().split('.')[1].length;
    }
    catch (e) {
      r1 = 0;
    }
    try {
      r2 = num2.toString().split('.')[1].length;
    }
    catch (e) {
      r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    n = (r1 >= r2) ? r1 : r2;
    return ((num1 * m - num2 * m) / m).toFixed(n);
  },
  /**
   * 乘
   * @param num1
   * @param num2
   * @returns {number}
   */
  accMul: function (num1, num2) {
    var m = 0, s1 = num1.toString(), s2 = num2.toString();
    try {
      m += s1.split('.')[1].length;
    } catch (e) {
    }
    try {
      m += s2.split('.')[1].length;
    } catch (e) {
    }
    return Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / Math.pow(10, m);
  },
  /**
   * 除
   * @param arg1
   * @param arg2
   * @returns {number}
   */
  accDiv: function (arg1, arg2) {
    var t1 = 0, t2 = 0, r1, r2;
    try {
      t1 = arg1.toString().split('.')[1].length;
    } catch (e) {
    }
    try {
      t2 = arg2.toString().split('.')[1].length;
    } catch (e) {
    }
    r1 = Number(arg1.toString().replace('.', ''));
    r2 = Number(arg2.toString().replace('.', ''));
    return (r1 / r2) * Math.pow(10, t2 - t1);
  },
  strTrim: function (str) {
    return str.replace(/\s/g, '');
  },
  /**
   * 下载文件
   * @param url
   * @param fileName
   */
  downloadFile: function (url, fileName) {
    if (!url) {
      return;
    }
    const eleLink = document.createElement('a');
    const isSupportDownload = 'download' in eleLink;
    if (!isSupportDownload) {
      eleLink.target = '_blank';
    }
    eleLink.style.display = 'none';
    eleLink.download = fileName || 'file';
    eleLink.href = url;
    document.body.appendChild(eleLink);
    eleLink.click();
    document.body.removeChild(eleLink);
  },
  /**
   * 自定义跳转
   * @param url
   */
  locationHref: function (url) {
    window.location.href = url;
  },
  /**
   * pv 跟踪
   * @param pageURL
   */
  trackPageview: function (pageURL) {
    if (env !== 'production') {
      return;
    }
    try {
      _hmt.push(['_trackPageview', pageURL]);
    } catch (e) {
      console.log(e);
    }
  },
  /**
   * event 跟踪
   * @param category
   * @param action
   * @param opt_label
   * @param opt_value
   */
  trackEvent: function (category, action, opt_label, opt_value) {
    if (env !== 'production') {
      return;
    }
    const label = opt_label || '-';
    const value = opt_value || '-';
    try {
      _hmt.push(['_trackEvent', category, `m.${action}`, label, value]);
    } catch (e) {
      console.log(e);
    }
  },
  /**
   * 数字转中文
   * @param n
   */
  translateNumber : function(n) {
      const digit = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
      const unit = [['', '万', '亿'], ['', '十', '百', '千']];
      let num = Math.abs(n);
      let s = '';
      num = Math.floor(num);
      if (num < 10) {
        return digit[num];
      }
      for (let i = 0; i < unit[0].length && num > 0; i += 1) {
        let p = '';
        for (let j = 0; j < unit[1].length && num > 0; j += 1) {
          p = digit[num % 10] + unit[1][j] + p;
          num = Math.floor(num / 10);
        }
        s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
      }
      return s.replace(/(零.)+/g, '零').replace(/^(一十)/, '十');
  },
  formatProblemData: function (problemList){
    const problemTypeMap = {};
    const optionArr = ['A', 'B', 'C', 'D', 'E', 'F'];
    for (let i = 0; i < problemList.length; i += 1) {
      const answerItem = problemList[i];
      const { optionList, problemType, rightAnswer } = answerItem;
      const seriesList = [];
      let answerTitle = '';
      let answerStudent = '';
      let isChioceQuestion = false;
      if (!problemTypeMap[problemType]) {
        const typeName = ProblemStore.getProblemTypeDict(problemType);
        problemTypeMap[problemType] = {
          typeName: typeName,
          problemList: [],
        };
      }
      if (optionList && optionList.length > 0) {
        for (let j = 0; j < optionList.length; j += 1) {
          const optionItem = optionList[j];
          optionItem.index = i;
          optionItem.problemType = problemType;
          if (problemType === 1) {
            if (optionItem.optionLetter === 'noanswer') {
              optionItem.optionLetter = '未作答';
            }
            if (optionItem.optionLetter === rightAnswer) {
              answerTitle = `选“ ${rightAnswer} ”的同学为：`;
              answerStudent = optionItem.studentName;
            }
          } else if (optionArr.indexOf(optionItem.optionLetter) < 0) {
            if (optionItem.optionLetter === 'right') {
              answerTitle = '回答正确的同学如下：';
              answerStudent = optionItem.studentName;
            }
          }
          seriesList.push(optionItem);
        }
        delete answerItem.optionList;
      }
      if (answerItem.problemType === 1 || answerItem.problemType === 2) {
        isChioceQuestion = true;
      }
      answerItem.seriesList = seriesList;
      answerItem.answerTitle = answerTitle;
      answerItem.answerStudent = answerStudent;
      answerItem.isChioceQuestion = isChioceQuestion;
      answerItem.answerStudent = answerStudent;
      answerItem.itemIndex = i + 1;
      problemTypeMap[problemType].problemList.push(answerItem);
    }
    return problemTypeMap
  },
  isUrl: function (path) {
    const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/g;
    return reg.test(path);
  },
  // 获取windows滚动高度
  getScrollTop: function () {
    const doc = document.documentElement || document.body.parentNode || document.body;
    const scrollTop = window.pageYOffset !== undefined ? window.pageYOffset : doc.scrollTop;
    return scrollTop;
  },
  setScrollTop: function (scrollTop) {
    const doc = document.documentElement || document.body.parentNode || document.body;
    doc.scrollTop = scrollTop;
  },
  /**
   *
   * @param paginate
   * @returns {boolean}
   */
  deletePaginateData: function (paginate, func) {
    if (_.isEmpty(paginate)) {
      return false;
    }
    paginate.total -= 1;
    const pageCount = paginate.pageSize * paginate.currentPage;
    let flag = false;
    if (paginate.total >= pageCount) {
      flag = true;
    } else if (paginate.currentPage > 1 && (pageCount - paginate.total) === paginate.pageSize) {
      paginate.currentPage -= 1;
      flag = true;
    }
    if (flag && typeof func === 'function') {
      return func();
    }
    return false;
  },
  /**
   * 添加自定义事件
   * @param element
   * @param eventName
   * @param func
   */
  addListener: function (element, eventName, func) {
    if (element.addEventListener) {
      element.addEventListener(eventName, func, false);
    } else if (element.attachEvent) {
      element.attachEvent('on' + eventName, function () {
        func.call(element, window.event);
      });
    }
  },
  /**
   * 移除事件
   * @param element
   * @param eventName
   * @param func
   */
  removeListener: function (element, eventName, func) {
    if (element.removeEventListener) {
      element.removeEventListener(eventName, func, false);
    } else if (element.detachEvent) {
      element.detachEvent('on' + eventName, func);
    }
  },
  /**
   * 是否微信访问
   * envCheck 开启dev环境检验
   * @returns {boolean}
   */
  isWeiXin: function (envCheck) {
    if (envCheck && env === 'development') {
      return true;
    }
    const ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
      return true;
    }
    return false;
  },
  /**
   * 检测数组中是否有对应值
   * @param arr
   * @param key
   * @param value
   * @returns {boolean}
   */
  hasMatchValue: function (data, key, value) {
    if (data && key && value) {
      if (_.isArray(data)) {
        for (let i = 0; i < data.length; i += 1) {
          const item = data[i];
          if (item[key] === value) {
            return true;
          }
        }
      } else if (_.isObject(data) && data[key] === value) {
        return true;
      }
    }
    return false;
  },
  /**
   * 合并分页数据
   */
  mergePaginateData: function (oldArr = [], newArr = [], key = 'id') {
    const oldMap = {};
    oldArr.forEach((item, index) => {
      oldArr[index].index = index;
      oldMap[item[key]] = item;
    });
    newArr.forEach((item) => {
      const oldItem = oldMap[item[key]];
      if (oldItem) {
        oldArr[oldItem.index] = item;
      } else {
        oldArr.push((item));
      }
    });
    return oldArr;
  },
  /**
   * 生成树形结构
   * @param data
   * @param root 根父id的值
   * @param openId 展开id
   */
  generateTreeData: function (data, root, openId) {
    if (_.isEmpty(data)) {
      return [];
    }
    let lastData = [];
    let tempData = {};
    let expandedKeys = [];
    data.forEach((item) => {
      item.children = tempData[item.id] && tempData[item.id].children;
      tempData[item.id] = item;
      if (item.parentId === root) {
        lastData.push(item);
      } else {
        tempData[item.parentId] = tempData[item.parentId] || {};
        tempData[item.parentId].children = tempData[item.parentId].children || [];
        tempData[item.parentId].children.push(item);
      }
    });
    function child(treeId, isInit) {
      let treeItem = tempData[treeId];
      if (treeItem) {
        if (!isInit) {
          treeItem.isOpen = true;
          expandedKeys.push(`${treeItem.id}`);
        }
        if (treeItem.parentId) {
          child(treeItem.parentId, false)
        }
      }
    }
    if (openId) {
      child(openId, true);
    }
    return { treeData: lastData, expandedKeys };
  },

  listAddSortField: function (keys = [], obj) {
    keys.forEach((key) => {
      const sortList = obj[key] || [];
      if (sortList.length > 0) {
        sortList.forEach((item, index) => {
          sortList[index].sort = index + 1
        });
      }
    })
  },

  listSort: function (keys = [], obj) {
    keys.forEach((key) => {
      const sortList = obj[key] || [];
      if (sortList.length > 0) {
        obj[key] = _.sortBy(sortList, 'sort')
      }
    })
  }

};

export default Util;
