export default {
  name: '催交作业',
  path: '/dashboards/push/:homeworkId/:classId',
  trackPath: '/dashboards/push/',
  getComponent (nextState, cb) {
    import('./components/PushStudentView').then((view) => {
      cb(null, view.default);
    });
  },
};
