export default {
  name: '备课',
  path: '/lesson/:resourceType/:docType',
  trackPath: '/lesson/details',
  color: 'f0f1f6',
  getComponent (nextState, cb) {
    import('./components/LessonDetailsView').then((view) => {
      cb(null, view.default);
    });
  },
};
