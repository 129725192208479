export default {
  name: '作业详情',
  path: '/homework/online/detail/:homeworkId',
  trackPath: '/homework/online/detail/',
  getComponent (nextState, cb) {
    import('./components/HomeworkDetailsView').then((view) => {
      cb(null, view.default);
    });
  },
};
