import BasicLayout from '../layouts/BasicLayout';
import Login from './Login/index';
import WxLogin from './WxLogin/index';
import LoginView from './Login/components/LoginViewV2';
import IframeRoute from './IframeRoute/index';
import AppConstants from '../constants/AppConstants';

import Test from './Teacher/Test/index';

// 教育云应用地址
import EduTeacherHomeworkOnline from './Teacher/_EduCloud/HomeworkOnline';
import EduTeacherHomeworkAfter from './Teacher/_EduCloud/HomeworkAfter';
import EduTeacherHomeworkPreview from './Teacher/_EduCloud/HomeworkPreview';

import EduStudentHomeworkOnline from './Student/_EduCloud/HomeworkOnline';
import EduStudentHomeworkAfter from './Student/_EduCloud/HomeworkAfter';
import EduStudentHomeworkPreview from './Student/_EduCloud/HomeworkPreview';
import EduStudentAnalysis from './Student/_EduCloud/Analysis';

// 备课
import TeacherLesson from './Teacher/Lesson/index';
import TeacherLessonDetails from './Teacher/LessonDetails/index';

// 在线作业
import TeacherHomework from './Teacher/Homework/index';
import TeacherHomeworkDetails from './Teacher/HomeworkDetails/index';
import TeacherHomeworkReviewed from './Teacher/HomeworkReviewed/index';
import TeacherHomeworkReviewedStudent from './Teacher/HomeworkReviewedStudent/index';
import TeacherHomeworkReviewedResult from './Teacher/HomeworkReviewedResult/index';

// 作业首页跳转
import TeachHomeworkIndex from './Teacher/PreviewHomeworkList/components/index';

import TeacherSaveHomework from './Teacher/SaveHomework/index';
import TeacherProfile from './Teacher/Profile/index';
import TeacherProfileDetail from './Teacher/ProfileDetail/index';
import TeacherProfileStudent from './Teacher/ProfileStudent/index';
import TeacherProfileStudentDetail from './Teacher/ProfileStudentDetail/index';
import TeacherProfileCurriculumMaterials from './Teacher/ProfileCurriculumMaterials/index';
import TeacherProfileTask from './Teacher/TaskScore/index';
import TeacherAccountSet from './Teacher/ProfileAccountSet/index';
import TeacherClassManage from './Teacher/ClassManage/index';
import TeacherAnalysisHomework from './Teacher/AnalysisHomework/index';
import TeacherEditHomework from './Teacher/EditHomework/index';
import TeacherMicroCourse from './Teacher/MicroCourse/index';
import TeacherMicroCourseDetails from './Teacher/MicroCourseDetails/index';
import TeacherHomeworkSubmitDetails from './Teacher/HomeworkSubmitDetails/index';
import TeacherAddBillBoard from './Teacher/BillboardCreate/index';
import TeacherAnalysisClassReport from './Teacher/AnalysisClassReport/index';
import TeacherAnalysisClassReportDetail from './Teacher/AnalysisClassReportDetail/index';
import TeacherAnalysisClassReportProblem from './Teacher/AnalysisClassReportProblem';
import TeacherAnalysisHomeworkFeedback from './Teacher/AnalysisHomeworkFeedback';
import TeacherAnalysisHomeworkFeedbackDetail from './Teacher/AnalysisHomeworkfeedbackDetail';
import TeacherAnalysisImproveScoreView from './Teacher/AnalysisClassReportImproveScore';
import TeacherImproveRecommendProblem from './Teacher/ImproveRecommendProblem';
import TeacherAnalysisComprehensiveLearning from './Teacher/AnalysisComprehensiveLearning';

// 课后作业
import TeacherAfterHomeworkCheck from './Teacher/AfterHomeworkCheck/index';
import TeacherAfterHomeworkDetail from './Teacher/AfterHomeworkDetail/index';
import TeacherAfterHomeworkList from './Teacher/AfterHomeworkList';
import TeacherAfterHomeworkReview from './Teacher/AfterHomeworkReview';
import TeacherAfterHomeworkReviewList from './Teacher/AfterHomeworkReviewList';
import TeacherAfterHomeworkSubmitDetails from './Teacher/AfterHomeworkSubmitDetails';
import TeacherAfterEditHomework from './Teacher/AfterHomeworkEdit';

// 预习作业
import TeacherPreviewHomeworkList from './Teacher/PreviewHomeworkList';
import TeacherPreviewEditHomework from './Teacher/PreviewHomeworkEdit';
import TeacherPreviewHomeworkSubmitDetail from './Teacher/PreviewHomeworkSubmitDetail';
import TeacherPreviewHomeworkReview from './Teacher/PreviewHomeworkReview';
import TeacherPreviewHomeworkReviewList from './Teacher/PreviewHomeworkReviewList';
import TeacherPreviewHomeworkDetail from './Teacher/PreviewHomeworkDetail';

import TeacherUploadProblem from './Teacher/UploadSingleProblem';

import StudentHomework from './Student/Homework/index';
import StudentHomeworkDetails from './Student/HomeworkDetails/index';
import StudentWrongProblem from './Student/WrongProblem/index';

import StudentHomeView from './Student/Home/components/HomeView';
import StudentHome from './Student/Home';
import StudentProfile from './Student/Profile/index';
import StudentAccountSet from './Student/ProfileAccountSet/index';
import StudentDoHomework from './Student/DoHomework/index';
import StudentDoSelfEvaluationHomework from './Student/DoSelfEvaluationHomework/index';
import StudentAnalysisClassReport from './Student/AnalysisClassReport/index';
import StudentAnalysisClassReportDetial from './Student/AnalysisClassReportDetail/index';
import StudnetAnalysisHomeworkFeedback from './Student/AnalysisHomeworkFeedback';
import StudnetAnalysisHomeworkFeedbackDetail from './Student/AnalysisHomeworkfeedbackDetail';
import StudentAnalysisImproveScore from './Student/AnalysisClassReportImproveScore';
import StudentAnalysisImproveScoreRecommend from './Student/ImproveRecommendProblem';

import StudentAfterHomework from './Student/AfterHomeworkList';
import StudentAfterHomeworkDetail from './Student/AfterHomeworkDetail';
import StudentAfterHomeworkCheck from './Student/AfterHomeworkCheck';
import StudentMessageBoard from './Teacher/MessageBoard';

import StudentPreviewHomework from './Student/PreviewHomeworkList';
import StudentPreviewHomeworkDetail from './Student/PreviewHomeworkDetail';

// 在线考试
import StudentOnlineExamList from './Student/OnlineExamList';
import StudentOnlineExamEntry from './Student/OnlineExamEntry';
import StudentOnlineExamPaper from './Student/OnlineExamPaper';

import CommonBillboardList from './BillboardList/index';
import UpdateMobile from './Profile/UpdateMobile/index';
import UpdatePassword from './Profile/UpdatePassword/index';
import PaperPreview from './Teacher/PaperPreview/index';
import LoginIdentity from './LoginIdentity/index';
import Rate from './Rate/index';

import ExcellentCourses from './ExcellentCourses/index';
import ExcellentCourseDetail from './ExcellentCourseDetail/index';
// app原分析跳转
import AppAnalysisRouter from './AppAnalysis/CorrectRate/index';
// 分享地址
import ShareAfterHomework from './Teacher/AfterHomeworkShare';
import TeacherEditPaper from './Teacher/EditPaper';

const childRoutes = [];
let indexRoute = '';
let level = AppConstants.level;

const routers = [{
  level: ['1'],
  sys: ['teacher'],
  item: EduTeacherHomeworkOnline
}, {
  level: ['1'],
  sys: ['teacher'],
  item: EduTeacherHomeworkAfter
}, {
  level: ['1'],
  sys: ['teacher'],
  item: EduTeacherHomeworkPreview
}, {
  level: ['2', '3'],
  sys: ['student'],
  item: EduStudentHomeworkOnline
}, {
  level: ['2', '3'],
  sys: ['student'],
  item: EduStudentHomeworkAfter
}, {
  level: ['2', '3'],
  sys: ['student'],
  item: EduStudentHomeworkPreview
}, {
  level: ['2', '3'],
  sys: ['student'],
  item: EduStudentAnalysis
}, {
  level: ['1'],
  sys: ['teacher'],
  item: TeacherHomework,
}, {
  level: ['1'],
  sys: ['teacher'],
  item: TeacherLesson,
}, {
  level: ['1'],
  sys: ['teacher'],
  item: TeacherLessonDetails,
}, {
  level: ['1'],
  sys: ['teacher'],
  item: TeacherHomeworkDetails,
}, {
  level: ['1'],
  sys: ['teacher'],
  item: TeacherHomeworkReviewed,
}, {
  level: ['1'],
  sys: ['teacher'],
  item: TeacherHomeworkReviewedStudent,
}, {
  level: ['1'],
  sys: ['teacher'],
  item: TeacherHomeworkReviewedResult,
}, {
  level: ['1'],
  sys: ['teacher'],
  item: TeacherSaveHomework,
}, {
  level: ['1'],
  sys: ['teacher'],
  item: TeacherProfile,
}, {
  level: ['1'],
  sys: ['teacher'],
  item: TeacherProfileDetail,
}, {
  level: ['1'],
  sys: ['teacher'],
  item: TeacherProfileStudent,
}, {
  level: ['1'],
  sys: ['teacher'],
  item: TeacherProfileStudentDetail,
}, {
  level: ['1'],
  sys: ['teacher'],
  item: TeacherProfileCurriculumMaterials,
}, {
  level: ['1'],
  sys: ['teacher'],
  item: TeacherAccountSet,
}, {
  level: ['1'],
  sys: ['teacher'],
  item: TeacherProfileTask,
}, {
  level: ['1'],
  sys: ['teacher'],
  item: TeacherClassManage,
}, {
  level: ['1'],
  sys: ['teacher'],
  item: TeacherAnalysisHomework,
}, {
  level: ['1'],
  sys: ['teacher'],
  item: TeacherMicroCourse,
}, {
  level: ['1'],
  sys: ['teacher'],
  item: TeacherMicroCourseDetails,
}, {
  level: ['1'],
  sys: ['teacher'],
  item: TeacherEditHomework,
}, {
  level: ['1'],
  sys: ['teacher'],
  item: TeacherAddBillBoard,
}, {
  level: ['1'],
  sys: ['teacher'],
  item: TeacherHomeworkSubmitDetails,
}, {
  level: ['1'],
  sys: ['teacher'],
  item: TeacherAnalysisClassReport,
}, {
  level: ['1'],
  sys: ['teacher'],
  item: TeacherAnalysisClassReportDetail,
}, {
  level: ['1'],
  sys: ['teacher'],
  item: TeacherAnalysisClassReportProblem,
}, {
  level: ['1'],
  sys: ['teacher'],
  item: TeacherAnalysisHomeworkFeedback,
}, {
  level: ['1'],
  sys: ['teacher'],
  item: TeacherAnalysisHomeworkFeedbackDetail,
}, {
  level: ['1'],
  sys: ['teacher'],
  item: TeacherAnalysisImproveScoreView
}, {
  level: ['1'],
  sys: ['teacher'],
  item: TeacherImproveRecommendProblem
}, {
  level: ['1'],
  sys: ['teacher'],
  item: TeacherAnalysisComprehensiveLearning
}, {
  level: ['1'],
  sys: ['teacher'],
  item: TeacherAfterHomeworkCheck
}, {
  level: ['1'],
  sys: ['teacher'],
  item: TeacherAfterHomeworkDetail
}, {
  level: ['1'],
  sys: ['teacher'],
  item: TeacherAfterHomeworkList
}, {
  level: ['1'],
  sys: ['teacher'],
  item: TeacherAfterHomeworkReview
}, {
  level: ['1'],
  sys: ['teacher'],
  item: TeacherAfterHomeworkReviewList
}, {
  level: ['1'],
  sys: ['teacher'],
  item: TeacherAfterHomeworkSubmitDetails
}, {
  level: ['1'],
  sys: ['teacher'],
  item: TeacherAfterEditHomework,
}, {
  level: ['1'],
  sys: ['teacher'],
  item: TeacherPreviewHomeworkList,
}, {
  level: ['1'],
  sys: ['teacher'],
  item: TeacherPreviewEditHomework,
}, {
  level: ['1'],
  sys: ['teacher'],
  item: TeacherPreviewHomeworkSubmitDetail,
}, {
  level: ['1'],
  sys: ['teacher'],
  item: TeacherPreviewHomeworkReview,
}, {
  level: ['1'],
  sys: ['teacher'],
  item: TeacherPreviewHomeworkReviewList,
}, {
  level: ['1'],
  sys: ['teacher'],
  item: TeacherPreviewHomeworkDetail,
}, {
  level: ['1'],
  sys: ['common'],
  item: TeacherEditPaper,
}, {
  level: ['1'],
  sys: ['teacher'],
  item: TeacherUploadProblem,
}, {
  level: ['2', '3'],
  sys: ['student'],
  item: StudentHome,
}, {
  level: ['2', '3'],
  sys: ['student'],
  item: StudentHomework,
}, {
  level: ['2', '3'],
  sys: ['student'],
  item: StudentHomeworkDetails,
}, {
  level: ['2', '3'],
  sys: ['student'],
  item: StudentWrongProblem,
}, {
  level: ['2', '3'],
  sys: ['student'],
  item: StudentProfile,
}, {
  level: ['2', '3'],
  sys: ['student'],
  item: StudentAccountSet,
}, {
  level: ['2', '3'],
  sys: ['student'],
  item: StudentDoHomework,
}, {
  level: ['2', '3'],
  sys: ['student'],
  item: StudentDoSelfEvaluationHomework,
}, {
  level: ['2', '3'],
  sys: ['student'],
  item: StudentAnalysisClassReport
}, {
  level: ['2', '3'],
  sys: ['student'],
  item: StudentAnalysisClassReportDetial
}, {
  level: ['2', '3'],
  sys: ['student'],
  item: StudnetAnalysisHomeworkFeedback
}, {
  level: ['2', '3'],
  sys: ['student'],
  item: StudnetAnalysisHomeworkFeedbackDetail
}, {
  level: ['2', '3'],
  sys: ['student'],
  item: StudentAnalysisImproveScore
}, {
  level: ['2', '3'],
  sys: ['student'],
  item: StudentAnalysisImproveScoreRecommend
}, {
  level: ['2', '3'],
  sys: ['student'],
  item: StudentAfterHomework
}, {
  level: ['2', '3'],
  sys: ['student'],
  item: StudentAfterHomeworkDetail
}, {
  level: ['2', '3'],
  sys: ['student'],
  item: StudentAfterHomeworkCheck
}, {
  level: ['2', '3'],
  sys: ['student'],
  item: StudentMessageBoard,
}, {
  level: ['2', '3'],
  sys: ['student'],
  item: StudentPreviewHomework,
}, {
  level: ['2', '3'],
  sys: ['student'],
  item: StudentPreviewHomeworkDetail,
}, {
  level: ['2', '3'],
  sys: ['student'],
  item: StudentOnlineExamList,
}, {
  level: ['2', '3'],
  sys: ['student'],
  item: StudentOnlineExamEntry,
}, {
  level: ['2', '3'],
  sys: ['student'],
  item: StudentOnlineExamPaper,
}, {
  level: ['1', '2', '3'],
  sys: ['common'],
  item: UpdateMobile,
}, {
  level: ['1', '2', '3'],
  sys: ['common'],
  item: UpdatePassword,
}, {
  level: ['1', '2', '3'],
  sys: ['common'],
  item: CommonBillboardList,
}, {
  level: ['1', '2', '3'],
  sys: ['common'],
  item: PaperPreview,
}, {
  level: ['1', '2', '3'],
  sys: ['common'],
  item: AppAnalysisRouter,
}, {
  level: ['1', '2', '3'],
  sys: ['common'],
  item: IframeRoute,
}, {
  level: ['0', '1', '2'],
  sys: ['test'],
  item: Test,
}, {
  level:['1', '2', '3'],
  sys: ['common'],
  item: LoginIdentity
}, {
  level:['1', '2', '3'],
  sys: ['common'],
  item: Rate
}, {
  level:['1', '2', '3'],
  sys: ['common'],
  item: ExcellentCourses
}, {
  level:['1', '2', '3'],
  sys: ['common'],
  item: ExcellentCourseDetail
}];

// wx login router
childRoutes.push(WxLogin, ShareAfterHomework);

if (!level || level === 'null') {
  childRoutes.push(Login);
  indexRoute = {
    name: '登录',
    component: LoginView,
  };
} else {
  routers.map((router) => {
    if (router.level.indexOf(level) >= 0) {
      childRoutes.push(router.item);
    }
    return childRoutes;
  });
  if (`${level}` === '1') {
    indexRoute = {
      color: 'f0f1f6',
      name: '作业',
      component: TeachHomeworkIndex,
    };
  } else {
    indexRoute = {
      // color: 'f0f1f6',
      name: '首页',
      component: StudentHomeView,
    };
  }
}

const notFound = {
  path: '*',
  ...indexRoute,
};

childRoutes.push(notFound);

export const createRoutes = {
  path: '/',
  component: BasicLayout,
  indexRoute: indexRoute,
  childRoutes,
};

export default createRoutes;
