import Event from 'events';
import assign from 'object-assign';

import StoreMixins from '../../mixins/StoreMixins';
import Util from '../../lib/Util';

const EventEmitter = Event.EventEmitter;

const store = {
  updateTarget: {
    isGetClass: false,
    isGetHomework: false,
    isGetTag: false,
    isGetClassManageInfo: false,
    isUpdateCurrentClass: false,
  },
  classList: [],
  homeworkInfo: {},
  tagList: [],
  classManageInfo: {},
  currentClass: {}, // 当前的班级
};

const ClassStore = assign({}, EventEmitter.prototype, StoreMixins.mixins, {
  getUpdateTarget: function () {
    return store.updateTarget;
  },
  clearUpdateTarget: function (key) {
    store.updateTarget[key] = false;
    return store.updateTarget;
  },
  getClassList: function () {
    return store.classList;
  },
  getHomeworkInfo: function () {
    return store.homeworkInfo;
  },
  getTagList: function () {
    return Util.deepCopy(store.tagList);
  },
  getHomeworkDetails: function () {
    return store.homeworkDetails;
  },
  getClassManageInfo: function () {
    return store.classManageInfo;
  },
  getCurrentClass: function () {
    return store.currentClass;
  },
  updateCurrentClass: function (value) {
    store.updateTarget.isUpdateCurrentClass = true;
    store.currentClass = value;
    this.emitChange();
  },
  GET_CLASS_LIST_RESPONSE: function (response, status, error) {
    if (!error) {
      store.updateTarget.isGetClass = true;
      store.classList = response && response.data ? response.data : [];
      this.emitChange();
    }
  },
  GET_HOMEWORK_LIST_RESPONSE: function (response, status, error) {
    if (!error) {
      store.updateTarget.isGetHomework = true;
      const { data, pageInfo, returnParam } = response;
      const dataInfo = data || {};
      let tempList = dataInfo.homeworkList || [];
      const { homeworkList } = store.homeworkInfo;
      if (returnParam.currentPage !== 1 && homeworkList.length > 0) {
        tempList = Util.mergePaginateData(homeworkList, tempList, 'id');
      }
      store.homeworkInfo = {
        homeworkList: tempList,
        pageInfo: pageInfo,
        unPublishHomeworkCount: dataInfo.unPublishHomeworkCount,
      };
      this.emitChange();
    }
  },
  GET_HOMEWORK_TAG_LIST_RESPONSE: function (response, status, error) {
    if (!error) {
      store.updateTarget.isGetTag = true;
      store.tagList = response && response.data ? response.data : [];
      this.emitChange();
    }
  },
  GET_CLASS_MANAGE_INFO_RESPONSE: function (response, status, error) {
    if (!error) {
      store.updateTarget.isGetClassManageInfo = true;
      store.classManageInfo = response && response.data ? response.data : {};
      this.emitChange();
    }
  },
});

ClassStore.registerDispatchToken();
export default ClassStore;
