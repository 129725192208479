import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { Toast, Modal } from 'antd-mobile';
import _ from 'underscore';

import SetSubjectModal from './SetSubjectModal';
import SetClassModal from './SetClassModal';
import AppAction from '../actions/AppAction';
import AppStore from '../stores/AppStore';
import AppConstants from '../constants/AppConstants';
import RecordLoginDuration from '../lib/RecordLoginDuration';
import TabBarLayout from './TabBarLayout';
import extConfig from '../constants/ext';
import { setLaterState } from '../lib/authority';
import Util from '../lib/Util';

const { alert } = Modal;

const loginDuration = new RecordLoginDuration();

class AuthLayout extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    isDemo: PropTypes.bool,
    local: PropTypes.object,
  };
  static defaultProps = {
    isDemo: false,
  };
  static isSetLater () {
    const setLater = Cookies.get('SLATER');
    return !!setLater;
  }
  static isSetMaterialLater () {
    const setLater = Cookies.get('SLATER_MATERIAL');
    return !!setLater;
  }
  constructor (props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    const { query } = props.local;
    if (extConfig.showTabBar) {
      if (_.has(query, 'show_tabbar')) {
        extConfig.showTabBar = !!Number(query.show_tabbar);
        Util.storage.session.set('showTabBar', extConfig.showTabBar);
      } else {
        const showTabBar = Util.storage.session.get('showTabBar');
        if (_.isBoolean(showTabBar)) {
          extConfig.showTabBar = showTabBar;
        }
      }
    }
    this.state = {
      isInit: true,
      isSetClass: false,
      isSetSubject: false,
      isSetLater: false,
      isSetTeachingMaterial: 0,
      unreadMessageStatus: {},
    };
  }

  componentDidMount () {
    const { isDemo } = this.props;
    AppStore.addChangeListener(this.onChange);
    Toast.loading('加载中...', 0);
    AppAction.getUserInfo(isDemo);
    if (`${AppConstants.level}` !== `1`) {
      AppAction.getUnreadMessageStatus();
    }
    // 七鱼客服
    // loadjs(['https://qiyukf.com/script/5bca2c814bdcb942dbd3ac04cd24b112.js']);
  }

  componentWillUnmount () {
    AppStore.removeChangeListener(this.onChange);
  }

  updateClassState () {
    const userInfo = AppStore.getUserInfo();
    let isSetLater = AuthLayout.isSetLater();
    const { userType, isSetClass, isSetSubject } = userInfo;
    if (`${userType}` !== '1') {
      Toast.hide();
      this.setState({ isInit: false });
      return;
    }
    let isInit = false;
    // 班级列表为空 同步班级列表
    if (isSetClass && isSetLater) {
      isInit = true;
      AppAction.getDefaultClass();
    }
    if (!isInit) {
      Toast.hide();
    }
    this.setState({ isInit, isSetClass, isSetSubject, isSetLater });
  }

  onChange () {
    const updateTarget = AppStore.getUpdateTarget();
    if (updateTarget.isGetUserInfo) {
      AppStore.clearUpdateTarget('isGetUserInfo');
      this.updateClassState();
      // 记录登录时长
      loginDuration.init();
    }
    // 修改班级信息
    if (updateTarget.isUpdateClassInfo) {
      AppStore.clearUpdateTarget('isUpdateClassInfo');
      this.updateClassState();
    }
    // 同步班级信息
    if (updateTarget.isGetDefaultClass) {
      AppStore.clearUpdateTarget('isGetDefaultClass');
      Toast.hide();
      this.setState({ isInit: false, isSetSubject: false });
    }

    // 未读消息同步
    if (updateTarget.isGetUnreadMessage) {
      const unreadMessageStatus = AppStore.getUnreadMessageStatus();
      this.setState({
        isUpdate: true,
        unreadMessageStatus,
      });
    }
  }

  setMaterialAlert = (isSetTeachingmaterial) => {
    if (!isSetTeachingmaterial || AuthLayout.isSetMaterialLater()) {
      return;
    }
    alert('提示',
      <span className='title-default'>您绑定的学科存在未设置的教材，请联系管理员前往管理后台进行设置</span>,
      [{
        text: '已经了解',
        onPress: () => this.handleMessageConfirm('setMaterial'),
        style: { fontSize: '0.4rem', color: '#108ee9' },
      }, {
        text: '取消',
        onPress: () => console.log('ok'),
        style: { fontSize: '0.4rem', color: '#888' },
      },
      ]
    );
  };

  handleMessageConfirm = (type) => {
    if (type === 'setMaterial') {
      setLaterState('SLATER_MATERIAL');
    }
  };

  handleSetClass = () => {
    Toast.loading('加载中...', 0);
    setLaterState();
    AppStore.emitUpdateClassInfo();
  };

  render () {
    const { isInit, isSetClass, isSetSubject, isSetLater, unreadMessageStatus } = this.state;
    if (isInit) {
      return null;
    }
    if (isSetClass && !isSetLater) {
      return (
        <SetClassModal visible onPress={this.handleSetClass} />
      );
    }
    if (!isSetClass && isSetSubject && !isSetLater) {
      return (
        <SetSubjectModal />
      );
    }
    const { children, local } = this.props;
    return (
      <Fragment>
        <section className='app-wrapper'>
          {children}
        </section>
        {extConfig.showTabBar ? (
          <TabBarLayout local={local} unreadStatus={unreadMessageStatus} />
        ) : null}
      </Fragment>
    );
  }
}

export default AuthLayout;
