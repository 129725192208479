import assign from 'object-assign';
import Constants from '../constants/AppConstants';
import request from '../lib/request';
import Prefix from '../constants/Prefix';
import ActionMixins from '../mixins/ActionMixins';

const ActionTypes = Constants.ActionTypes;

const ClassAction = assign({}, ActionMixins.mixins, {
  getTaskScoreSys: function () {
    const url = `${Prefix('HDKT')}/dict/taskScoreSys/list/v1`;
    const options = {
      method: 'GET',
    };
    return request({
      type: ActionTypes.GET_PROFILE_TASK_SCORE,
      url,
      options,
    });
  },
  updateTaskScore: function (data) {
    const url = `${Prefix('HDKT')}/user/teacher/updateTaskScore/v1`;
    const options = {
      method: 'POST',
      body: data,
    };
    return request({
      url,
      options,
    });
  },
  sendVerifyCode: function (data) {
    const url = `${Prefix('HDKT')}/message/sendVerifyCode`;
    const options = {
      method: 'POST',
      body: data,
    };
    return request({
      url,
      options,
    });
  },
  verifyMobile: function (data) {
    const url = `${Prefix('HDKT')}/message/verify`;
    const options = {
      method: 'POST',
      body: data,
    };
    return request({
      url,
      options,
    });
  },
  updateMobile: function (data) {
    const url = `${Prefix('HDKT')}/user/updateTel/v1`;
    const options = {
      method: 'POST',
      body: data,
    };
    return request({
      url,
      options,
    });
  },
  updatePassword: function (data) {
    const url = `${Prefix('HDKT')}/user/updatePwd/v1`;
    const options = {
      method: 'POST',
      body: data,
    };
    return request({
      url,
      options,
    });
  },
  /**
   * 个人中心-我的学生列表
   */
  getStudentList: function () {
    const url = `${Prefix('HDKT')}/user/getStudentList/v1`;
    const options = {
      method: 'GET',
    };
    return request({
      type: ActionTypes.GET_PROFILE_STUDENT_LIST,
      url,
      options,
    });
  },
  /**
   * 个人中心-我的课程和教材页
   */
  getSubjectTeachMaterial: function () {
    const url = `${Prefix('HDKT')}/user/getSubjectTeachMaterial/v1`;
    const options = {
      method: 'GET',
    };
    return request({
      type: ActionTypes.GET_SUBJECT_TEACH_MATERIAL,
      url,
      options,
    });
  },
  /**
   * 个人中心-保存新增/修改学科教材
   * @param data
   */
  saveSubjectMaterial: function (data) {
    const url = `${Prefix('HDKT')}/class/teacher/subject/v4`;
    const options = {
      method: 'POST',
      body: data,
    };
    return request({
      url,
      options,
    });
  },
  /**
   * 个人中心-删除学科教材
   * @param data
   */
  deleteSubjectTeachMaterial: function (data) {
    const url = `${Prefix('HDKT')}/user/deleteSubjectTeachMaterial/v1`;
    const options = {
      method: 'POST',
      body: data,
    };
    return request({
      url,
      options,
    });
  },
  getSetSubjectClass: function () {
    const url = `${Prefix('HDKT')}/class/set/list/v1`;
    const options = {
      method: 'GET',
    };
    return request({
      type: ActionTypes.GET_SET_SUBJECT_CLASS,
      url,
      options,
    });
  },
  /**
   * 用户个人中心-切换用户身份
   * @param data
   * @returns {Promise<*|undefined>}
   */
  getProfileUserList: function () {
    const url = `${Prefix('HDKT')}/user/change/v1`;
    const options = {
      method: 'GET',
    };
    return request({
      url,
      type: ActionTypes.GET_PROFILE_USER_LIST,
      options,
    });
  },
  /**
   * 江西BP机-家长获取留言信息列表
   * @param data
   * @returns {Promise<*|undefined>}
   */
  getBpMessageList: function (data) {
    const url = `${Prefix('HDKT')}/bpmsg/get/list/v1`;
    const options = {
      method: 'POST',
      body: data,
    };
    return request({
      type: ActionTypes.GET_BP_MESSAGE_LIST,
      url,
      options,
    });
  },
  /**
   * 江西BP机-删除消息
   * @param data
   * @returns {Promise<*|undefined>}
   */
  deleteBpMessage: function (data) {
    const url = `${Prefix('HDKT')}/bpmsg/delete/msg/v1`;
    const options = {
      method: 'POST',
      body: data,
    };
    return request({
      url,
      options,
    });
  },
  /**
   * 江西BP机-保存留言
   * @param data
   * @returns {Promise<*|undefined>}
   */
  saveBpMessage: function (data) {
    const url = `${Prefix('HDKT')}/bpmsg/save/msg/v1`;
    const options = {
      method: 'POST',
      body: data,
    };
    return request({
      url,
      options,
    });
  },

});

export default ClassAction;
