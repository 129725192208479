export default {
  name: '作业反馈报告',
  path: '/analysis/:type/homework/feedback',
  color: 'f0f1f6',
  getComponent (nextState, cb) {
    import('./components/AnalysisHomeworkFeedback').then((view) => {
      cb(null, view.default);
    });
  },
};

