import React from 'react';
import PropTypes from 'prop-types';

export const HomeworkStateView = ({ state, className }) => {
  let stateClass = '';
  let stateStr = '';
  switch (state) {
    case 1:
      stateClass = 'icon-warning'; // 待点评
      stateStr = '待点评';
      break;
    case 2:
      stateClass = 'icon-success'; // 已点评;
      stateStr = '已点评';
      break;
    case 3:
      stateClass = ''; // 未反馈
      stateStr = '';
      break;
    case 100:
      stateClass = 'icon-danger'; // 已删除
      stateStr = '已删除';
      break;
    default:
      break;
  }
  return (
    <div className={`flex flex-center ${className}`}>
      <i className={`${stateClass}`} />
      <strong>
        {stateStr}
      </strong>
    </div>
  );
};

HomeworkStateView.propTypes = {
  state: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
};

HomeworkStateView.defaultProps = {
  className: '',
};

export default HomeworkStateView;
