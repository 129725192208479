export default {
  name: '作业反馈报告详情',
  path: '/analysis/:type/homework/feedback/:classId/:subjectId/:homeworkId',
  trackPath: '/analysis/homework/feedback/details/',
  getComponent (nextState, cb) {
    import('./components/AnalysisHomeworkFeedbackDetail').then((view) => {
      cb(null, view.default);
    });
  },
};
