/**
 * @description: 预习任务列表
 * @author: tengyang <tengyangcc@163.com>
 */
import React, { Component } from 'react';
import _ from 'underscore';
import InfiniteScroll from 'react-infinite-scroller';
import NavBar from '../../../../components/NavBar';
import Util from '../../../../lib/Util';
import HomeworkList from './HomeworkList';
import { ClassHeader } from '../../Homework/components/ClassHeader';
import Spin from '../../../../components/Spin';

import AfterHomeworkAction from '../../../../actions/teacher/AfterHomeworkAction';
import AfterHomeworkStore from '../../../../stores/teacher/AfterHomeworkStore';
import PreviewHomeworkAction from '../../../../actions/teacher/PreviewHomeworkAction';
import AppStore from '../../../../stores/AppStore';
import ClassStore from '../../../../stores/teacher/ClassStore';

const session = {
  paginate: {
    currentPage: 1,
    pageSize: 10,
    total: 0,
  },
  homeworkList: [],
  unPublishHomeworkCount: 0,
  isUpdate: false,
  loading: true,
  hasMoreHomework: false,
  classId: 0,
  subjectId: '',
};

export default class PreviewHomwrorkList extends Component {
  constructor (props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.homeworkType = 3;
    this.state = session;
  }
  componentDidMount () {
    AfterHomeworkStore.addChangeListener(this.onChange);
    ClassStore.addChangeListener(this.onClassChange);
  }
  componentWillUnmount () {
    AfterHomeworkStore.removeChangeListener(this.onChange);
    ClassStore.removeChangeListener(this.onClassChange);
  }
  onChange () {
    const updateTarget = AfterHomeworkStore.getUpdateTarget();
    const { paginate } = session;
    if (updateTarget.isGetHomeworkList) {
      AfterHomeworkStore.clearUpdateTarget('isGetHomeworkList');
      const homeworkInfo = AfterHomeworkStore.getHomeworkInfo();
      const { homeworkList, pageInfo, unPublishHomeworkCount } = homeworkInfo;
      paginate.total = pageInfo.total || 0;
      const { currentPage, pageSize, total } = paginate;
      let hasMoreHomework = false;
      if (total && (total / currentPage) > pageSize) {
        hasMoreHomework = true;
      }
      session.homeworkList = homeworkList;
      session.unPublishHomeworkCount = unPublishHomeworkCount;
      session.hasMoreHomework = hasMoreHomework;
      session.isUpdate = true;
      session.loading = false;
      this.setState(session);
    }
  }

  onClassChange = () => {
    const updateTarget = ClassStore.getUpdateTarget();
    if (updateTarget.isUpdateCurrentClass) {
      ClassStore.clearUpdateTarget('isUpdateCurrentClass');
      const currentClass = ClassStore.getCurrentClass();
      if (session.classId === currentClass.classId && session.subjectId === currentClass.subjectId) {
        this.getHomeworkList('didmount');
        return;
      }
      session.classId = currentClass.classId;
      session.subjectId = currentClass.subjectId;
      this.getHomeworkList('init');
    }
  };

  getHomeworkList = (type) => {
    const { paginate } = session;
    let currentPage = paginate.currentPage;
    let pageSize = paginate.pageSize;
    if (type === 'didmount') {
      currentPage = 1;
      pageSize = paginate.currentPage * pageSize;
    } else if (type === 'init') {
      currentPage = 1;
      paginate.currentPage = 1;
    }
    const requestData = {
      classId: session.classId,
      subjectId: session.subjectId || 0,
      homeworkType: this.homeworkType,
      currentPage,
      pageSize,
    };
    if (type === 'init') {
      session.loading = true;
      this.setState(session, () => {
        AfterHomeworkAction.getAfterHomework(requestData);
      });
    } else {
      AfterHomeworkAction.getAfterHomework(requestData);
    }
  }

  openEditHomework = (data, editType) => {
    let homeworkId = 0;
    if (data && _.isObject(data)) {
      homeworkId = data.id;
    }
    Util.trackEvent('homework', 'click', '新建预习任务弹出页');
    this.handleSwitchPage();
    Util.pageHashSwitch(`/homework/preview/${editType}/${session.classId}/${session.subjectId}/${homeworkId}`, '', true);
  }

  deleteHomework = (item) => {
    if (!item.id) {
      return;
    }
    PreviewHomeworkAction.deleteHomeworkById(item.id, item.index).then((response) => {
      if (response.success) {
        this.getHomeworkList('init');
      } else {
        AppStore.emitFlashMessage({ content: response.msg, status: 'fail' });
      }
    });
  };

  loadMoreHomework = () => {
    const { paginate } = session;
    paginate.currentPage += 1;
    this.getHomeworkList('paginate');
  };

  handleSwitchPage = () => {
    window.scrollTo(0, 0);
    session.paginate.currentPage = 1;
  };

  headerView () {
    const { route } = this.props;
    return (
      <div className='mix-header'>
        <NavBar showGoBack={false} fixed={false}>{route.name || '作业'}</NavBar>
        <ClassHeader
          onSwitchPage={this.handleSwitchPage}
          homeworkType={this.homeworkType}
          classId={session.classId}
          subjectId={session.subjectId}
          createHomework={this.openEditHomework}
          hideMenu={!!route.hideInMenu}
        />
      </div>
    );
  }
  render () {
    const { homeworkList, unPublishHomeworkCount, hasMoreHomework, paginate, loading } = session;
    return (
      <div className='classDetails teacher-after-homework'>
        <InfiniteScroll
          pageStart={paginate.currentPage}
          loadMore={this.loadMoreHomework}
          hasMore={!loading && hasMoreHomework}
          loader={<div className='loading' key={0}>正在加载中...</div>}
          threshold={50}
        >
          <div className='teacher-homework-details'>
            {this.headerView()}
            {loading ? (
              <Spin spinning />
            ) : (
              <HomeworkList
                homeworkList={homeworkList}
                classId={session.classId}
                subjectId={session.subjectId}
                homeworkType={this.homeworkType}
                unPublishHomeworkCount={unPublishHomeworkCount}
                editHomework={this.openEditHomework}
                deleteHomework={this.deleteHomework}
              />
            )}
          </div>
        </InfiniteScroll>
      </div>
    );
  }
}
