export default {
  path: '/profile/set/mobile',
  name: '修改手机号',
  getComponent (nextState, cb) {
    import('./components/UpdateMobileView').then((view) => {
      cb(null, view.default);
    });
  },
};

