import React, { Fragment } from 'react';
import assign from 'object-assign';

import Constants from '../../../constants/AppConstants';
import UserAction from '../../../actions/UserAction';
import UserStore from '../../../stores/UserStore';
import UserList from './../../Common/UserList';
import Util from '../../../lib/Util';
import { passwordEncrypt } from './_util';
import { redirectTo } from '../../../lib/authority';

import NavBar from "../../../components/NavBar";
import loginBg from '../../../images/login/login-bg@2x.png';

class LoginView extends React.Component {
  static refreshVerifyCode () {
    UserAction.getVerifyCode();
  }
  constructor (props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.level = 1;
    this.expires = null;
    this.state = {
      loginInfo: {
        remember: true,
        browsersId: Constants.FINGERPRINT,
        openId: props.params.openId
      },
      submitting: false,
      errorTips: '',
      verifyInfo: {},
      loginList: [],
      verifying: false,
    };
  }

  componentDidMount () {
    window.scrollTo(0, 0);
    UserStore.addChangeListener(this.onChange);
  }

  componentWillUnmount () {
    UserStore.removeChangeListener(this.onChange);
  }

  onChange () {
    const updateTarget = UserStore.getUpdateTarget();
    const { verifyInfo } = this.state;
    if (updateTarget.isGetVerifyCode) {
      UserStore.clearUpdateTarget('isGetVerifyCode');
      const verify = UserStore.getVerifyInfo();
      verifyInfo.verifyCode = verify.verifyCode;
      verifyInfo.key = verify.key;
      this.setState({ verifyInfo });
    }
  }

  handleInputChange = (event, name) => {
    const { loginInfo } = this.state;
    if (name === 'remember') {
      loginInfo.remember = !loginInfo.remember;
    } else {
      loginInfo[name] = event.target.value;
    }
    this.setState({ loginInfo, errorTips: '' });
  };

  handleKeyDown = (e) => {
    const me = this;
    if (e.keyCode === 13) {
      me.loginSubmit();
    }
  };

  loginSubmit = () => {
    const { loginInfo, submitting, verifying, verifyInfo } = this.state;
    if (submitting) {
      return;
    }
    // loginInfo.userType = this.level;
    let errorTips = '';
    if (!loginInfo.username) {
      errorTips = '请输入手机号';
    } else if (!Util.isMobile(loginInfo.username)) {
      errorTips = '手机号不正确';
    } else if (!loginInfo.password) {
      errorTips = '请输入密码';
    } else if (verifying && !loginInfo.verificationCode) {
      errorTips = '请输入验证码';
    }
    if (errorTips) {
      this.setState({ errorTips });
      return;
    }
    loginInfo.username = Util.strTrim(loginInfo.username);
    if (loginInfo.remember) {
      this.expires = 7;
    } else {
      this.expires = null;
    }
    const copyLoginInfo = assign({}, loginInfo, { loginChannel: 'WX' });
    copyLoginInfo.password = passwordEncrypt(loginInfo.password);
    if (verifying) {
      copyLoginInfo.key = verifyInfo.key;
    }
    this.setState({ submitting: true }, () => {
      UserAction.loginV2(copyLoginInfo).then((response) => {
        const { success, code } = response;
        if (success) {
          const loginList = response.data || [];
          if (loginList.length > 1) {
            this.setState({ loginList, submitting: false }, () => {
              window.scrollTo(0, 0);
            });
            return;
          }
          try {
            let loginItem = loginList[0];
            redirectTo({
              userType: loginItem.userType,
              token: loginItem.token,
            });
          } catch (e) {
            console.log(e);
          }
        } else {
          let showVerify = verifying;
          if (code === 2001) {
            response.msg = '';
            showVerify = true;
          }
          this.setState({ errorTips: response.msg, verifying: showVerify, submitting: false }, () => {
            if (showVerify) {
              LoginView.refreshVerifyCode();
            }
          });
        }
      });
    });
  };

  render () {
    const me = this;
    const { loginInfo, errorTips, submitting, verifying, verifyInfo, loginList } = this.state;
    if (loginList.length > 0) {
      return (
        <Fragment>
          <NavBar showGoBack={false}>选择身份</NavBar>
          <UserList userList={loginList} />
        </Fragment>
      );
    }
    return (
      <div className='login-index-wrap'>
        <header className='login-header'>
          <img alt='logo' className='logo' src={loginBg} />
        </header>
        <section className='login-section'>
          <div className='passport'>
            <div className='login-form-wrap'>
              <div className='form-group'>
                <span className='mobile' />
                <input
                  type='number'
                  className='form-control'
                  placeholder='请输入手机号'
                  value={loginInfo.username || ''}
                  onChange={e => me.handleInputChange(e, 'username')}
                />
              </div>
              <div className='form-group'>
                <span className='password' />
                <input
                  type='password'
                  className='form-control'
                  placeholder='请输入密码'
                  value={loginInfo.password || ''}
                  onChange={e => me.handleInputChange(e, 'password')}
                />
              </div>
              {verifying ? (
                <div className='form-group login-group'>
                  <span className='code' />
                  <input
                    type='text'
                    className='form-control'
                    placeholder='验证码'
                    onChange={e => this.handleInputChange(e, 'verificationCode')}
                    onKeyDown={this.handleKeyDown}
                  />
                  <div className='unselect login-verify-code' onClick={LoginView.refreshVerifyCode}>
                    {verifyInfo.verifyCode ? (
                      <img alt='verifyCode' src={verifyInfo.verifyCode} />
                    ) : null}
                  </div>
                </div>
              ) : null}
              <div className='form-btn-group'>
                {errorTips ? (
                  <p className='login-error-tip'>
                    <span>* </span>{errorTips}
                  </p>
                ) : null}
                <span
                  className='btn'
                  onClick={me.loginSubmit}
                  disabled={submitting}
                >
                  {submitting ? '登录中...' : '登录'}
                </span>
              </div>
              {loginInfo.openId ? (
                <div className='login-tip'>
                  成功登录后，下次将默认此账号自动登录
                </div>
              ) : null}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default LoginView;
