export default {
  name: '问题预览',
  path: '/paper/:paperId/:source',
  trackPath: '/paper/details',
  getComponent (nextState, cb) {
    import('./components/PaperPreviewView').then((view) => {
      cb(null, view.default);
    });
  },
};
