export default {
  name: '课堂报告',
  path: '/analysis/:type/class/report',
  color: 'f0f1f6',
  getComponent (nextState, cb) {
    import('./components/AnalysisClassReportView').then((view) => {
      cb(null, view.default);
    });
  },
};
