export default {
  name: '课后作业',
  path: '/khzy',
  color: 'f0f1f6',
  hideInMenu: true,
  hideGoBack: true,
  getComponent (nextState, cb) {
    import('../../AfterHomeworkList/components/index').then((view) => {
      cb(null, view.default);
    });
  },
};
