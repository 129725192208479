import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import Icon from '../Icon';
import { goBack } from '../../history';

class NavBar extends React.Component {
  static defaultProps = {
    fixed: true,
    extra: null,
    onBack: () => goBack(),
    showGoBack: true,
  };

  static propTypes = {
    fixed: PropTypes.bool,
    extra: PropTypes.node,
    onBack: PropTypes.func,
    showGoBack: PropTypes.bool
  };

  render () {
    const { isHide, children, fixed, extra, onBack, showGoBack } = this.props;
    if (isHide) {
      return null;
    }
    const cls = ClassNames('header-wrapper header-primary flex', { 'fixed': fixed });
    const layout = (
      <div className={cls}>
        {showGoBack ? (
          <div className='header-back flex flex-center' onClick={() => onBack()}>
            <Icon type='back' />
          </div>
        ) : <div className='header-back'>&nbsp;</div>}
        <h1 className='header-title current'>
          {children}
        </h1>
        {extra}
      </div>
    );
    if (fixed) {
      return (
        <div className='header-root'>
          {layout}
        </div>
      );
    }
    return layout;
  }
}

export default NavBar;
