export default {
  name: '报告问题列表',
  path: '/analysis/class/report/problemlist/:sourse',
  trackPath: '/analysis/class/report/problemlist/',
  getComponent (nextState, cb) {
    import('./components/AnalysisClassReportProblem').then((view) => {
      cb(null, view.default);
    });
  },
};

