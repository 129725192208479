export default {
  name: '发作作业',
  path: '/homework/online/:editType/:classId/:subjectId/:pageSource/:homeworkId',
  color: 'f0f1f6',
  getComponent (nextState, cb) {
    import('./components/EditHomeworkView').then((view) => {
      cb(null, view.default);
    });
  },
};
