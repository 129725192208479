import { browserHistory } from 'react-router';
import extConfig from './constants/ext';

export default browserHistory;

let indexPathName = window.location.pathname;

/**
 * 跳转当前页面路由
 * @param location
 * @param suffix
 */
export function navigateToCurrentLocation (location, suffix) {
  let to = `${location.pathname}`;
  if (location.search) {
    to = `${to}${location.search}&${suffix}`;
  } else {
    to = `${to}?${suffix}`;
  }
  browserHistory.push(to);
}

/*
 * 返回上一页
 * 如果没有上一页返回首页
 */
export function goBack (to = '/') {
  if (indexPathName === window.location.pathname) {
    if (extConfig.showTabBar) {
      indexPathName = to;
      browserHistory.push(to);
    }
  } else {
    browserHistory.goBack();
  }
}
