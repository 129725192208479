/**
 * @description: 选择班级
 * @author: tengyang <tengyangcc@163.com>
 */
import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'rmc-dialog';

class ClassSelect extends React.Component {
  static propTypes = {
    currentClass: PropTypes.object.isRequired,
    classList: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
  };
  constructor (props) {
    super(props);
    this.handleModal = this.handleModal.bind(this);
    this.selectClass = this.selectClass.bind(this);
    this.state = {
      isOpen: false,
      isUpdate: false,
    };
  }

  shouldComponentUpdate (nextProps, nextState) {
    return nextState.isUpdate;
  }

  handleModal (isOpen, func) {
    this.setState({ isOpen, isUpdate: true }, () => {
      if (func) {
        func();
      }
    });
  }

  selectClass (item) {
    this.handleModal(false, () => {
      this.props.onChange(item);
    });
  }

  render () {
    const { currentClass, classList } = this.props;
    const { isOpen } = this.state;
    if (!isOpen) {
      return null;
    }
    return (
      <Modal
        prefixCls='hd-popup'
        transitionName='hd-popup-slide-fade'
        maskTransitionName='hd-popup-fade'
        visible
        closable={false}
        onClose={() => this.handleModal(false)}
      >
        <section className='tag-list__main'>
          {classList.map((item, index) => {
            let checkCls = 'list-adorn-group flex flex-center';
            if (currentClass.subjectId === item.subjectId && currentClass.classId === item.classId) {
              checkCls = `${checkCls} hd-checked`;
            }
            return (
              <div
                className={checkCls}
                key={`student_subject_item_${index}`}
                onClick={() => this.selectClass(item)}
              >
                <label htmlFor='subject' className='title'>
                  {`${item.className}: ${item.subjectName}`}
                </label>
                <span className='hd-form-radio' />
              </div>
            );
          })}
        </section>
      </Modal>
    );
  }
}

export default ClassSelect;
