export default {
  name: '作业',
  path: '/homework/online/detail/:homeworkId/:paperId/:isSelfEvaluation',
  trackPath: '/do-homework/online/',
  getComponent (nextState, cb) {
    import('./components/DoHomeworkView').then((view) => {
      cb(null, view.default);
    });
  },
};
