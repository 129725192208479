import _ from 'underscore';
import { v1 as uuidv1 } from 'uuid';

/**
 * Check if the given value is a number.
 * @param {*} value - The value to check.
 * @returns {boolean} Returns `true` if the given value is a number, else `false`.
 */
export function isNumber (value) {
  return typeof value === 'number' && !isNaN(value);
}

/**
 * @param target
 * @param top
 * @returns {*}
 */
export function getScroll(target, top) {
  if (typeof window === 'undefined') {
    return 0;
  }
  const prop = top ? 'pageYOffset' : 'pageXOffset';
  const method = top ? 'scrollTop' : 'scrollLeft';
  const isWindow = target === window;

  let ret = isWindow ? target[prop] : target[method];
  // ie6,7,8 standard mode
  if (isWindow && typeof ret !== 'number') {
    ret = window.document.documentElement[method];
  }

  return ret;
}

export function getTargetRect (target) {
  return target !== window ? target.getBoundingClientRect() : { top: 0, left: 0, bottom: 0 };
}

/**
 * Get a pointer from an event object.
 * @param {Object} event - The target event object.
 * @param {boolean} endOnly - Indicates if only returns the end point coordinate or not.
 * @returns {Object} The result pointer contains start and/or end point coordinates.
 */
export function getPointer ({ pageX, pageY }, endOnly) {
  const end = {
    endX: pageX,
    endY: pageY,
  };
  return endOnly ? end : Object.assign({
    startX: pageX,
    startY: pageY,
  }, end);
}

const REGEXP_SUFFIX = /^(?:width|height|left|top|marginLeft|marginTop)$/;

/**
 * Apply styles to the given element.
 * @param {Element} element - The target element.
 * @param {Object} styles - The styles for applying.
 */
export function setStyle (element, styles) {
  const { style } = element;
  Object.keys(styles).forEach((key) => {
    let value = styles[key];
    if (REGEXP_SUFFIX.test(key) && _.isNumber(value)) {
      value += 'px';
    }
    style[key] = value;
  });
}

// 可视窗口高度
export function getClientHeight() {
  return document.documentElement.clientHeight || document.body.clientHeight;
}

// 可视窗口高度
export function getClientWidth() {
  return document.documentElement.clientWidth || document.body.clientWidth;
}

// 整个页面文档高度
export function getWinOffsetHeight () {
  return document.documentElement.offsetHeight || document.body.offsetHeight;
}

/**
 * 获取唯一id
 */
export function getId () {
  return uuidv1().replace(/-/g, '');
}

/**
 * 加载图像元素并将其传递给回调函数
 * @param url
 * @param callback
 * @param context
 * @param crossOrigin
 */
export function loadImage (url, callback, context, crossOrigin) {
  if (!url) {
    callback && callback.call(context, url);
    return;
  }

  let img = document.createElement('img');

  const onLoadCallback = function () {
    callback && callback.call(context, img);
    img = img.onload = img.onerror = null;
  };

  img.onload = onLoadCallback;

  img.onerror = function () {
    window.alert(`Error loading ${img.src}`);
    callback && callback.call(context, null);
    img = img.onload = img.onerror = null;
  };

  if (crossOrigin) {
    img.crossOrigin = crossOrigin;
  }

  img.src = url;
}

/**
 * Get transforms base on the given object.
 * @returns {string} A string contains transform values.
 */
export function getTransforms ({ rotate, scaleX, scaleY, translateX, translateY }) {
	const values = [];

	if (isNumber(translateX) && translateX !== 0) {
		values.push(`translateX(${translateX}px)`);
	}

	if (isNumber(translateY) && translateY !== 0) {
		values.push(`translateY(${translateY}px)`);
	}

	// Rotate should come first before scale to match orientation transform
	if (isNumber(rotate) && rotate !== 0) {
		values.push(`rotate(${rotate}deg)`);
	}

	if (isNumber(scaleX) && scaleX !== 1) {
		values.push(`scaleX(${scaleX})`);
	}
	if (isNumber(scaleY) && scaleY !== 1) {
		values.push(`scaleY(${scaleY})`);
	}
	const transform = values.length ? values.join(' ') : 'none';

	return {
		WebkitTransform: transform,
		msTransform: transform,
		transform,
	};
}