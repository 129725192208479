import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router';
import history from './history';

import './normalize';
import './lib/polyfill';
import './lib/flexible.debug';
import './styles/main.scss';

const MOUNT_NODE = document.getElementById('root');

let render = () => {
  const routes = require('./routes/index').default;
  ReactDOM.render(<Router children={routes} history={history} />, MOUNT_NODE);
};

render();
