export default {
  name: '上传单题',
  path: '/upload/problem',
  color: 'f0f1f6',
  getComponent (nextState, cb) {
    import('./components/UploadSingleProblemView').then((view) => {
      cb(null, view.default);
    });
  },
};
