import ENCUTF8 from 'crypto-js/enc-utf8';
import AES from 'crypto-js/aes';
import MD5 from 'crypto-js/md5';
import ECB from 'crypto-js/mode-ecb';
import Pkcs7 from 'crypto-js/pad-pkcs7';

const AesKey = 'QTHDKTQTHDKT'; //加密时用的key

const ECBOptions = {
  mode: ECB,
  padding: Pkcs7,
};

/**
 * 加密(AES)
 */
export function encrypt (word, aesKey = AesKey) {
  const key = ENCUTF8.parse(aesKey);
  const srcs = ENCUTF8.parse(word);
  const encrypted = AES.encrypt(srcs, key, ECBOptions);
  return encrypted.toString();
}

/**
 * 解密(AES)
 */
export function decrypt (word, aesKey = AesKey) {
  const key = ENCUTF8.parse(aesKey);
  const decrypt = AES.decrypt(word, key, ECBOptions);
  return ENCUTF8.stringify(decrypt).toString();
}

/**
 * 加密(MD5)
 */
export function encryptMd5 (word) {
  return MD5(word).toString();
}
