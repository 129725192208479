export default {
  name: '学情分析',
  path: '/xqfx',
  color: 'f0f1f6',
  getComponent (nextState, cb) {
    import('../../AnalysisClassReport/components/AnalysisClassReportView').then((view) => {
      cb(null, view.default);
    });
  },
};

