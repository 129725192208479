export default {
  name: '综合学情报告',
  path: '/analysis/:type/comprehensive/learning',
  color: 'f0f1f6',
  getComponent (nextState, cb) {
    import('./components/ComprehensiveLearningView').then((view) => {
      cb(null, view.default);
    });
  },
};
