export default {
  name: '班级提分报告',
  path: '/analysis/:type/improve/score',
  color: 'f0f1f6',
  getComponent (nextState, cb) {
    import('./components/ImproveScoreView').then((view) => {
      cb(null, view.default);
    });
  },
};
