import * as React from 'react';
import addEventListener from 'rc-util/lib/Dom/addEventListener';
import shallowequal from 'shallowequal';

import { getTargetRect, getPointer, setStyle } from '../../lib/UtilExtend';

export default class Movable extends React.Component {
  constructor (props) {
    super(props);
    this.events = [
      'touchstart',
      'touchmove',
      'touchend',
    ];
    this.moveStyle = {
      position: 'fixed',
    };
    this.eventHandlers = {};
    this.pointers = {};
  }

  componentDidMount () {
    this.timeout = setTimeout(() => {
      this.setTargetEventListeners(this.moveNode);
    });
    const elemOffset = getTargetRect(this.moveNode);
    this.setMoveStyle({
      ...this.moveStyle,
      top: elemOffset.top,
      width: elemOffset.width,
      height: elemOffset.height,
      left: elemOffset.left,
      right: elemOffset.right,
    });
  }

  componentWillUnmount () {
    this.clearEventListeners();
    clearTimeout(this.timeout);
  }

  setTargetEventListeners (target) {
    if (!target) {
      return;
    }
    this.clearEventListeners();
    this.events.forEach(eventName => {
      this.eventHandlers[eventName] = addEventListener(target, eventName, this.handleEvent);
    });
  }

  clearEventListeners () {
    this.events.forEach(eventName => {
      const handler = this.eventHandlers[eventName];
      if (handler && handler.remove) {
        handler.remove();
      }
    });
  }

  setMoveStyle (moveStyle) {
    if (!this.moveNode || shallowequal(moveStyle, this.moveStyle)) {
      return;
    }
    this.moveStyle = moveStyle;
    setStyle(this.moveNode, moveStyle);
  };

  handleEvent = (e) => {
    const event = e || window.event;
    switch (event.type) {
      case 'touchstart':
        this.pointerdown(event);
        break;
      case 'touchmove':
        this.pointermove(event);
        break;
      case 'touchend':
        this.pointerup(event);
        break;
      default:
        break;
    }
  };

  pointerdown = (e) => {
    const { pointers } = this;
    const event = e || window.event;
    // event.preventDefault();
    const touch = event.touches[0];
    pointers[0] = getPointer(touch);
    this.action = true;
  };

  pointermove = (e) => {
    const { pointers } = this;
    if (!this.action) {
      return;
    }
    e.preventDefault();
    const touch = e.touches[0];
    Object.assign(pointers[0], getPointer(touch, true));
    this.change(e);
  };

  pointerup = () => {
    const { pointers } = this;
    delete pointers[0];
    if (!this.action) {
      return;
    }
    this.action = false;
  };

  move = (offsetX, offsetY) => {
    this.moveTo(this.moveStyle.left + Number(offsetX), this.moveStyle.top + Number(offsetY));
  };

  moveTo = (x, y) => {
    this.setMoveStyle({
      ...this.moveStyle,
      left: x,
      top: y,
    });
  };

  change = () => {
    const { pointers } = this;
    const pointer = pointers[Object.keys(pointers)[0]];
    const offsetX = pointer.endX - pointer.startX;
    const offsetY = pointer.endY - pointer.startY;

    this.move(offsetX, offsetY);
    // Override
    pointers[0].startX = pointers[0].endX;
    pointers[0].startY = pointers[0].endY;
  };

  saveMoveNode = (node) => {
    this.moveNode = node;
  };

  render () {
    const { children, className } = this.props;
    return (
      <div className={className} ref={this.saveMoveNode}>
        {children}
      </div>
    );
  }
}
