export default {
  name: '作业分享',
  path: '/share/homework/:openId',
  color: 'fff',
  getComponent (nextState, cb) {
    import('./components/ShareView').then((view) => {
      cb(null, view.default);
    });
  },
};
