import { getFingerprint } from '../lib/Fingerprint';
import { globalData } from '../lib/authority';

import keyMirror from 'keymirror';

const { token, level } = globalData;

export default {
  ActionTypes: keyMirror({
    NEED_LOGIN: null, // 401 登录
    EXCEPTION_ACTION: null, // 统一异常处理
    GET_USER_INFO: null, // 获取用户信息
    GET_DEFAULT_CLASS_LIST: null, // 同步班级信息
    GET_SUBJECT_INFO: null, // 获取用户科目信息
    GET_CLASS_LIST: null, // 获取所有班级列表
    GET_HOMEWORK_LIST: null, // 获取作业列表
    GET_HOMEWORK_TAG_LIST: null, //  获取作业标签列表
    GET_CHAPTER_LIST: null, //  获取章节列表
    GET_TEACHER_MATERIAL_LIST: null, // 获取教材列表
    GET_TEACHER_GRADE_LIST: null, // 获取教材年级列表
    GET_KNOWLEDGE_LIST: null, // 获取知识点列表
    GET_PROBLEM_LIST: null, // 获取问题列表
    GET_PAPER_CART_LIST: null, // 获取试卷问题购物车列表
    GET_PROBLEM_FILTER_CONDITION: null, // 获取过滤问题列表
    GET_PREVIEW_CART_LIST: null, // 获取购物车问题预览
    GET_LESSON_LIST: null, // 获取备课列表
    GET_LESSON_CHAPTER_LIST: null, // 获取备课章节
    GET_MINE_LESSON_LIST: null, // 获取我的课堂列表
    GET_MINE_LESSON_LIST_BY_TYPE: null, // 我的课堂列表页(查询每个类型)
    GET_PAPER_LIST: null, // 获取试卷列表
    GET_PAPER_PROBLEM_LIST: null, // 获取试卷问题详情
    GET_HOMEWORK_BY_ID: null, // 获取作业信息
    GET_REVIEWED_HOMEWORK_INFO: null, // 获取作业信息
    GET_REVIEWED_HOMEWORK_ANALYSIS: null, // 获取教师批改作业
    GET_REVIEWED_STUDENT_LIST: null, // 获取批改学生列表
    GET_REVIEWED_STUDENT_PROBLEM: null, // 获取批改学生问题信息
    GET_REVIEWED_HOMEWORK_RESULT: null, // 获取批改学生完成信息
    GET_STUDENT_HOMEWORK_LIST: null, // 获取学生作业列表
    GET_SUBJECT_LIST_BY_CLASS_ID: null, // 获取学科列表通过班级id
    GET_STUDENT_HOMEWORK_DETAILS: null, // 获取学生作业详情
    GET_STUDENT_PROBLEM_LIST: null, // 获取学生问题列表
    GET_STUDENT_SELF_PROBLEM_LIST: null, // 获取自评成绩列表
    GET_STUDENT_WRONG_PROBLEM_LIST: null, // 获取学生错题本
    GET_STUDENT_CLASS_BILLBOARD_LIST: null, // 获取学生端班级公告列表
    GET_CLASS_BILLBOARD_LIST: null, // 获取班级公告列表
    GET_SAVE_HOMEWORK_LIST: null, // 获取保存作业列表
    GET_CLASS_MANAGE_INFO: null, //  获取班级管理信息
    GET_PROFILE_TASK_SCORE: null, // 获取评分方式列表
    GET_PROBLEM_ANALYSES: null, // 获取问题解析
    GET_BILLBOARD_DETAILS: null, // 获取公告详情
    GET_TEACHER_HOMEWORK_RATE_CHART: null, // 作业分析图表-教师
    GET_TEACHER_HOMEWORK_RATE_LIST: null, // 作业分析列表查询-教师
    GET_ERROR_PROBLEM_RATE: null, // 错误题号分析
    GET_DICT_SUBJECT: null, // 获取学科字典
    GET_PAPER_DETAILS: null, // 获取试卷详情
    GET_ANALYSIS_DROP_DOWN_MENU: null, // 获取分析静态数据
    GET_REVIEWED_STUDENT_TASK: null, // 获取学生批改任务
    GET_ANALYSIS_STUDENT_HOMEWORK: null, // 获取分析学生作业列表
    GET_TEACHER_ANALYSIS_HOMEWORK_LIST: null, // 获取分析作业列表
    GET_TEACHER_ANALYSIS_WHOLE_LIST: null, // 作业整体分布情况
    GET_TEACHER_ANALYSIS_DIFFICULTY_LIST: null, // 作业难度分布情况
    GET_TEACHER_ANALYSIS_KNOWLEDGE_LIST: null, // 作业知识点分布情况
    GET_TEACHER_ANALYSIS_CORRECT_RATE: null, // 当前作业正确率
    GET_TEACHER_ANALYSIS_PROBLEM_TYPE: null, // 按题型情况
    GET_TEACHER_ANALYSIS_KNOWLEDGE_CORRECT_RATE_TOTAL: null, // 知识点汇总
    GET_TEACHER_ANALYSIS_KNOWLEDGE_CORRECT_RATE_LIST: null, // 知识点覆盖情况分析
    GET_TEACHER_ANALYSIS_ERROR_PROBLEM_LIST: null, // 分析错题列表
    GET_COURSE_LIST: null, // 获取微课列表
    GET_COURSE_DETAIL_CONTENT: null, // 获取微课课程详情页
    GET_COURSE_VIDEO_DETAIL_CONTENT: null, // 获取微课视频播放详情
    GET_HOMEWORK_PUSH_STUDENT_LIST: null, // 获取催交作业推送列表
    GET_STUDENT_UN_SUBMIT_HOMEWORK: null, // 获取未完成作业
    GET_ANALYSIS_CLASS_SCHEDULE: null, // 获取课程表数据
    GET_ANALYSIS_CLASS_SCHEDULE_INFO: null, // 班级课堂标题
    GET_ANALYSIS_VOTE_INFO: null, //  获取课堂投票
    GET_ANALYSIS_STUDENT_PRAISE: null, // 获取表扬情况
    GET_ANALYSIS_OFFLINE_TEST_SITUATION: null, // 线下测评
    GET_ANALYSIS_OFFLINE_TEST_DETAIL: null, // 线下测评明细接口
    GET_ANALYSIS_OFFLINE_TEST_DETAIL_ANSWER: null, // 线下测评明细接口 学生答题结果
    GET_ANALYSIS_CLASS_KNOWLEDGE_INFO: null, // 课堂知识点情况
    GET_ANALYSIS_CLASS_PICTURE: null, // 获取课堂截图
    GET_ANALYSIS_SCHEDULE_ANSWER: null, // 答题结果分析接口
    GET_ANALYSIS_STUDENT_SCHEDULE_ANSWER: null, // 学生答题结果分析接口
    GET_STUDENT_ANSWER_RESULT: null, // 学生端答题结果
    GET_LESSON_ZIP_FILE_LIST: null, // 在线预览压缩包列表
    GET_ANALYSIS_HOMEWORK_FEEDBACK_LIST: null, // 获取作业列表
    GET_ANALYSIS_PROBLEM_CONTRAST: null, // 作业难易度比例
    GET_ANALYSIS_KNOWLEDGE_SITUATION: null, // 知识点掌握情况
    GET_ANALYSIS_HOMEWORK_CORRECTION: null, // 作业批改情况
    GET_TEACHER_ANALYSIS_HOMEWORK_CORRECTION: null, // 教师端作业批改情况
    GET_ANALYSIS_SIMILARS_EXERCISES: null, // 获取错题同类题训练
    GET_STUDENT_SIMILARS_EXERCISES: null, // 获取学生错题同类题训练
    GET_DATE_SECTION: null, // 获取日期区间
    GET_KNOWLEDGE_ANALYSIS_INFO: null, // 提分报告知识点分析
    GET_SCORE_DISTRIBUTE: null, // 获取各分数段学生分布
    GET_KNOWLEDGE_LEVEL: null, // 提分报告获取知识点掌握水平
    GET_SCORE_TRAINING: null, // 提分训练
    GET_LEARNING_SCHEDULE_INFO: null, // 综合学情课程情况
    GET_LEARNING_SITUATION: null, // 学情概况
    GET_LEARNING_HOMEWORK: null, // 作业情况

    GET_AFTER_HOMEWORK_LIST: null, // 课后作业列表
    DELETE_AFTER_HOMEWORK: null, // 删除课后作业
    // CREATE_AFTER_HOMEWORK: null, // 创建课后作业
    // EDIT_AFTER_HOMEWORK: null, // 编辑课后作业
    GET_AFTER_HOMEWORK_REVIEW_DETAIL: null, // 批改详情
    GET_AFTER_HOMEWORK_REVIEW_LIST: null, // 批改列表
    GET_AFTER_HOMEWORK_CHECK_LIST: null, // 查看题目单
    GET_AFTER_HOMEWORK_STUDENT_ANSWER: null, // 获取学生答案
    // SUBMIT_STUDENT_SCORE: null, // 老师提交学生成绩

    GET_STUDENT_AFTER_HOMELIST_LIST: null, // 学生课后作业
    GET_AFTER_HOMEWORK_BY_ID: null, // 获取课后作业详情

    GET_PROFILE_STUDENT_LIST: null,  // 个人中心学生列表
    GET_SUBJECT_TEACH_MATERIAL: null, // 个人中心课程教材
    GET_PERIOD_SUBJECT_LIST: null, // 个人中心科目
    GET_MATERIAL_BY_SUBJECTID: null, // 个人中心教材列表
    GET_GRADE_BY_MATERIALID: null, // 个人中心年级上下册
    GET_SET_SUBJECT_CLASS: null, // 获取需要设置科目的班级列表

    GET_CLASS_INTERACTION_LIST: null, // 获取互动通知列表

    GET_HOMEWORK_STATUS: null, // 获取作业的查看状态 小红点

    GET_FOLDER_LIST: null, // 个人题库获取文件夹列表
    GET_SUBJECT_LIST: null, // 获取题库中学科下拉列表

    GET_PROFILE_USER_LIST: null, // 用户个人中心-切换用户身份
    GET_VERIFY_CODE: null, // 获取验证码
    GET_PROBLEM_TEACHER_MATERIAL_LIST: null, // 获取题库下教材
    GET_PROBLEM_TEACHER_GRADE_LIST: null, // 获取题库下年级
    GET_UNREAD_MESSAGE_STATUS: null, // 获取未读消息状态

    GET_BP_MESSAGE_LIST: null, // 江西BP机-家长获取留言信息列表
    GET_PAPER_CART_DETAIL: null, // 获取试卷详情

    GET_EXCELLENT_COURSE_LIST: null, // 精品课列表
    GET_EXCELLENT_COURSE_DETAIL: null,  // 精品课详情

    GET_STUDENT_ONLINE_EXAM_PROBLEM_LIST: null, // 学生端在线考试题目列表
  }),
  PayloadSources: keyMirror({
    VIEW_ACTION: null,
    SERVER_ACTION: null,
  }),
  Token: token,
  level,
  PASSWORD_SUFFIX: 'hdkt1.0',
  OBJECTIVE_ID:['1', '2', '3'],
  SYSTEM_CODE: 'school',
  FINGERPRINT: getFingerprint(),
  REPORT_TEACHER_USERID: '0DD87CC9B625DB6E06FCA7CC801E3C5C76688C069C10402ABDE73E235A73323470FD6A6833E3F035',
  REPORT_STUDENT_USERID: '16F9626649D89064612E7ACD59486C16C02992FB92A0FB7B496F7012EA9530F23F1C887337E493B4',
};
