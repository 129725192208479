export default {
  name: '点播课详情',
  path: '/excellent/course/:type/detail/:courseId',
  color: 'f0f1f6',
  getComponent (nextState, cb) {
    import('./components/ExcellentCourseDetailView').then((view) => {
      cb(null, view.default);
    });
  },
};

