import assign from 'object-assign';
import Constants from '../../constants/AppConstants';
import request from '../../lib/request';
import Prefix from '../../constants/Prefix';
import ActionMixins from '../../mixins/ActionMixins';

const ActionTypes = Constants.ActionTypes;

const ProblemAction = assign({}, ActionMixins.mixins, {
  getChapterList: function (data) {
    const { teachingMaterialId, gradeId, parentId, subjectId } = data;
    const url = `${Prefix('QUESTION')}/chapter/list/v2/${subjectId}/${teachingMaterialId}/${gradeId}/${parentId}`;
    const options = {
      method: 'GET',
    };
    return request({
      type: ActionTypes.GET_CHAPTER_LIST,
      url,
      options,
      returnParam: { parentId },
    });
  },
  getKnowledgeList: function (subjectId, parentId) {
    const url = `${Prefix('QUESTION')}/dict/knowledge/v1/${subjectId}/${parentId}`;
    const options = {
      method: 'GET',
    };
    return request({
      type: ActionTypes.GET_KNOWLEDGE_LIST,
      url,
      options,
      returnParam: { parentId },
    });
  },
  getProblemList: function (data, type) {
    let url = 'problem/teacher/problemList/v2';
    if (type === 'school') {
      url = `personalProblem/problemList/v1`;
    }
    url = `${Prefix('QUESTION')}/${url}`;
    const options = {
      method: 'POST',
      body: data,
    };
    return request({
      type: ActionTypes.GET_PROBLEM_LIST,
      url,
      options,
      returnParam: { currentPage: data.currentPage },
    });
  },
  doCollection: function (data) {
    const url = `${Prefix('HDKT')}/problem/teacher/doCollection/v1`;
    const options = {
      method: 'POST',
      body: data,
    };
    return request({
      url,
      options,
    });
  },
  doProblemCart: function (data) {
    const url = `${Prefix('HDKT')}/problem/teacher/doProblemCart/v1`;
    const options = {
      method: 'POST',
      body: data,
    };
    return request({
      url,
      options,
    });
  },
  getProblemFilterCondition: function (subjectId) {
    const url = `${Prefix('HDKT')}/problem/teacher/condition/type/diffculty/kind/v1/${subjectId}`;
    const options = {
      method: 'GET',
    };
    return request({
      type: ActionTypes.GET_PROBLEM_FILTER_CONDITION,
      url,
      options,
      returnParam: { subjectId },
    });
  },
  /**
   * 获取试卷详情
   */
  getPaperDetailsById: function (paperId, source, hasType = true) {
    let url = '';
    if (source === 'personal') {
      url = `${Prefix('QUESTION')}/personal/paper/getPaper/v1/${paperId}`;
    } else {
      url = `${Prefix('HDKT')}/paper/teacher/getPaper/v1/${paperId}`;
    }
    const options = {
      method: 'GET',
    };
    return request({
      type: hasType ? ActionTypes.GET_PAPER_DETAILS : '',
      url,
      options,
    });
  },
  /**
   * 获取问题类型字典
   */
  getProblemTypeDict: function (isDemo) {
    let urlKey = 'HDKT';
    if (isDemo) {
      urlKey = 'HDKT_DEMO';
    }
    const url = `${Prefix(urlKey)}/dict/problemtype/list/v1`;
    const options = {
      method: 'GET',
    };
    return request({
      url,
      options,
    });
  },
  /**
   * 通过学科Id获取教材列表
   */
  getTeachingMaterialList: function (subjectId, dispatch = true) {
    const url = `${Prefix('QUESTION')}/dict/teachingMaterial/v1/${subjectId}`;
    const options = {
      method: 'GET',
    };
    return request({
      type: dispatch ? ActionTypes.GET_PROBLEM_TEACHER_MATERIAL_LIST : '',
      url,
      options,
      returnParam: { subjectId },
    });
  },
  /**
   * 通过教材Id获取年级列表
   */
  getTeachingGradeList: function (teachingMaterialId, dispatch = true) {
    const url = `${Prefix('QUESTION')}/dict/grade/v1/${teachingMaterialId}`;
    const options = {
      method: 'GET',
    };
    return request({
      type: dispatch ? ActionTypes.GET_PROBLEM_TEACHER_GRADE_LIST : '',
      url,
      options,
      returnParam: { teachingMaterialId },
    });
  },
});

export default ProblemAction;
