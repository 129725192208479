export default {
  path: '/profile/set/password',
  name: '修改密码',
  getComponent (nextState, cb) {
    import('./components/UpdatePasswordView').then((view) => {
      cb(null, view.default);
    });
  },
};

