import Dispatcher from '../dispatcher/AppDispatcher';

export default {
  mixins: {
    // Emit Change event
    emitChange: function () {
      this.emit('changes');
    },

    // Add change listener
    addChangeListener: function (callback) {
      this.on('changes', callback);
    },

    // Remove change listener
    removeChangeListener: function (callback) {
      this.removeListener('changes', callback);
    },

    registerDispatchToken: function () {
      const store = this;
      store.dispatchToken = Dispatcher.register((payload) => {
        const action = payload.action;
        if (action.type in store) {
          store[action.type].apply(store, [
            action.response,
            action.status,
            action.error,
          ]);
        }
        return true;
      });
    },
  },
};
